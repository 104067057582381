// import utils from '../utils'
import apiClient from './AxiosHttp'

const api = '/api/v1'

const login = (details) => {
    return apiClient.post(`${api}/auth/token/`, details)
}

const checkEmail = (email) => {
    return apiClient.post(`${api}/auth/users/check/email/`, email)
}

const requestOtp = (email) => {
    return apiClient.post(`${api}/auth/users/forgot/password/`, email)
}

const checkOtp = (otp) => {
    return apiClient.post(`${api}/auth/users/check/otp/`, otp)
}

const resetPassword = (details) => {
    return apiClient.post(`${api}/auth/users/forgot/password/change/`, details)
}

const getUserDetails = () => {
    return apiClient.get(`${api}/auth/users/user/`)
}

const getRefreshToken =  (details) => {

}

const authServices = {
    login,
    checkEmail,
    requestOtp,
    resetPassword,
    checkOtp,
    getUserDetails,
    getRefreshToken,
}

export default authServices