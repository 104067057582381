import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import Loading from '../../components/feedback/Loading'
import { useSearchParams } from 'react-router-dom'
import expertsServices from '../../services/ExpertsServices'
import FetchError from '../../components/feedback/FetchError'
import ExpertsSumary from '../../components/experts/ExpertsSumary'
import ExpertsTable from '../../components/experts/ExpertsTable'

function ExpertsPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [statistics, setStatistics] = useState()
    const [experts, setExperts] = useState()

    const getStatistics = () => {
        expertsServices.getExpertStatistics().then(
            res => {
                setStatistics(res.data)
            }, 
            err => {
                console.log(err);
            }
        )
    }
    useEffect(() => {
        getStatistics()
        getExperts()
    }, [])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getExperts(params)
        }
    }, [searchParams])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [statistics])
    useEffect(() => {}, [experts])

    const getExperts = (params) => {
        setError()
        setLoading(true)
        expertsServices.getExperts(params ? `?${params}` : '').then(
            res => {
                console.log(res.data);
                setExperts(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
            getExperts()
        }
    }
    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Experts - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <div className='mb-3'>
                                    <ExpertsSumary statistics={statistics}/>
                                </div>
                                <div className='mb-3'>
                                    <ExpertsTable 
                                        experts={experts}
                                        getExperts={getExperts}
                                        changePageParam={changePageParam}
                                        changeLimitParam={changeLimitParam}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default ExpertsPage