import React, { useEffect } from 'react'
import moment from 'moment'

function BuyerSubscription(props) {
    const buyer = props.buyer
    useEffect(() => {}, [buyer])
    return (
        <div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Subscription Details</p>
                </div>
                <div className='p-2 space-y-3'>
                    {buyer?.owner_subscription ? (
                        <>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Subscription Plan</p>
                                <p>{buyer?.email}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Billing</p>
                                <p>{buyer?.owner_subscription?.billing_period?.period_name?.name}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Pricing</p>
                                <p>{buyer?.owner_subscription?.billing_period?.amount}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Subscription Status</p>
                                <p>{buyer?.owner_subscription?.billing_period?.subscription_plan?.status}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className='text-gray-500'> Expiry Date</p>
                                <p>{moment(buyer?.owner_subscription?.due_date).format("Do MMMM YYYY")}</p>
                            </div>
                        </>
                    ):(
                        <p>User has not made any Subscription Yet</p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BuyerSubscription