import { createContext, useState } from "react";

const SessionContext = createContext({
    limit: 10,
    setLimit: (data) => {},
    hasCheckedAuth: true,
    setHasCheckedAuth: () => {}
})

export function SessionContextProvider(props){
    const [limit, setLimit] = useState(10)

    const [hasCheckedAuth, setHasCheckedAuth] = useState(() => {
        const checked = sessionStorage.getItem("hasCheckedAuth")
        if (checked === null) {
            return false
        }
        return true
    })

    function setLimitHandler(data){
        setLimit(data)
    }

    function setHasCheckedAuthHandler(){
        return (
            setHasCheckedAuth(true),
            sessionStorage.setItem("hasCheckedAuth", 1)
        )
    }

    const context = {
        limit: limit,
        setLimit: setLimitHandler,
        hasCheckedAuth: hasCheckedAuth,
        setHasCheckedAuth: setHasCheckedAuthHandler
    }

    return (
        <SessionContext.Provider value={context}>
            {props.children}
        </SessionContext.Provider>
    )
}

export default SessionContext