import React, { useEffect } from 'react'
import utils from '../../utils'
import moment from 'moment'

function SubscriptionPayRow(props) {
    const payment = props.payment

    useEffect(() => {}, [payment])
    return (
        <tr className='mb-3'>
            <td>
                {payment?.user_payment_method ? (
                    <div className="flex items-center gap-3">
                        <div className='avatar'>
                            {utils.checkImage(payment?.service_request?.owner) ? (
                                <div className=" w-11 rounded-full">
                                    <img 
                                        src={payment?.user_payment_method?.user?.image} 
                                        alt={payment?.user_payment_method?.user?.email} />
                                </div>
                            ):(
                                <div>
                                    <div className="avatar placeholder">
                                        <div className="bg-neutral text-neutral-content rounded-full w-11">
                                            <span className="text-xl">{utils.makeProfileLetters(payment?.user_payment_method?.user)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className=''>
                            <div>
                                {payment?.user_payment_method?.user?.full_name ? (
                                    <p>{payment?.user_payment_method?.user?.full_name}</p>
                                ):(
                                    <p>{payment?.user_payment_method?.user?.email}</p>
                                )}
                            </div>
                        </div>
                    </div>
                ):(
                    <span>-</span>
                )}
            </td>
            <td>
                {payment?.benefit_code ? (
                    <div>
                        {payment?.benefit_code?.billing_period?.subscription_plan?.name}
                    </div>
                ):(
                    <span>-</span>
                )}
            </td>
            <td>
                {payment?.benefit_code ? (
                    <div>
                        {payment?.benefit_code?.billing_period?.period_name?.name}
                    </div>
                ):(
                    <span>-</span>
                )}
            </td>
            <td>
                {utils.toCommas(payment?.total)}
            </td>
            <td>
                {payment?.creation_time ? (
                    <span>{moment(payment?.creation_time).format("Do MMM YYYY")}</span>
                ):(
                    <span>-</span>
                )}
            </td>
        </tr>
    )
}

export default SubscriptionPayRow