import React, { useState } from 'react'
import Loading from '../feedback/Loading'
import toast from 'react-hot-toast'
import subscriptionServices from '../../services/SubscriptionService'
import utils from '../../utils'

function AddSubscriptionModal(props) {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [tagLine, setTagLine] = useState('')
    const [discount, setDiscount] = useState(0)
    const [usageCount, setUsageCount] = useState(0)
    const [colorOne, setColorOne] = useState('#010101')
    const [colorTwo, setColorTwo] = useState('#0c3e4f')

    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        const data = {
            "name": name,
            "discount": discount,
            "tag_line": tagLine,
            "usage_count": usageCount,
            "hex_code_one": colorOne,
            "hex_code_two": colorTwo
        }
        const createToast = toast.loading("Creating Subscription Plan")
        subscriptionServices.addSubscriptionPlan(data).then(
            res => {
                setLoading(false)
                toast.success("Created Subscription Plan successfully", {
                    id:createToast
                })
                closeModal()
                props.updateUI()
            },
            err => {
                console.log(err);
                setLoading(false)
                toast.error(utils.toasterError, {
                    id:createToast
                })
            }
        )
    }
    function closeModal(){
        const closeBtn = document.getElementById("subscription-add-modal-close-btn")
        closeBtn.click()
    }
    return (
        <div>
            <label htmlFor="subscription-add-modal" id='subscription-add-open-btn' className="hidden">Add Subscription</label>
            <input type="checkbox" id="subscription-add-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-7/12 max-w-5xl">
                    <label htmlFor="subscription-add-modal" id='subscription-add-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-6'>Add Subscription Plan</h2>
                    <form onSubmit={submitHandler}>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Name *</span>
                            </div>
                            <input 
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text" 
                                required 
                                className='input input-bordered' 
                                placeholder='Enter Plan Name' />
                        </div>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Disount *</span>
                            </div>
                            <input 
                                value={discount}
                                onChange={(e) => setDiscount(e.target.value)}
                                type="number" 
                                required 
                                min={1}
                                className='input input-bordered' 
                                placeholder='Enter discount' />
                        </div>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Tagline *</span>
                            </div>
                            <textarea 
                                value={tagLine}
                                required
                                onChange={(e) => setTagLine(e.target.value)}
                                className="textarea textarea-bordered" 
                                placeholder="Some text here"></textarea>
                        </div>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Usage Count *</span>
                            </div>
                            <input 
                                value={usageCount}
                                onChange={(e) => setUsageCount(e.target.value)}
                                type="number" 
                                required 
                                min={1}
                                className='input input-bordered' 
                                placeholder='Enter Usage Count' />
                        </div>
                        <div className="mb-3 grid grid-cols-2 gap-3">
                            <div className="form-control mb-3">
                                <div className="label">
                                    <span className='label-text'>Color One Hex Code *</span>
                                </div>
                                <input 
                                    value={colorOne}
                                    onChange={(e) => setColorOne(e.target.value)}
                                    type="text" 
                                    required 
                                    className='input input-bordered' 
                                    placeholder='Enter Hexadecimal color code' />
                            </div>
                            <div className="form-control mb-3">
                                <div className="label">
                                    <span className='label-text'>Color Two Hex Code *</span>
                                </div>
                                <input 
                                    value={colorTwo}
                                    onChange={(e) => setColorTwo(e.target.value)}
                                    type="text" 
                                    required 
                                    className='input input-bordered' 
                                    placeholder='Enter Hexadecimal color code' />
                            </div>
                        </div>
                        <div className='modal-action flex justify-end gap-3'>
                            {loading ? (<Loading/>):(
                                <>
                                    <div 
                                        onClick={() => closeModal()}
                                        className='btn btn-ghost text-accent'>Cancel</div>
                                    <button type='submit' className='btn text-white btn-accent'>Save</button>
                                </>
                            )}                                
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddSubscriptionModal