import React, { useEffect, useState } from 'react'
import servicesService from '../../services/ServicesService'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import Empty from '../feedback/Empty'
import utils from '../../utils'
import moment from 'moment'
import { AiOutlineUser } from "react-icons/ai"
import { GrUserWorker } from "react-icons/gr"

function ServiceReviews(props) {
    const [reviews, setReviews] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const isCompleted = props.isCompleted
    const service = props.service

    useEffect(() => {}, [reviews])
    useEffect(() => {}, [isCompleted])
    useEffect(() => {}, [error])
    useEffect(() => {
        if (service && isCompleted) {
            getReviews(service?.id)
        }
    }, [service, isCompleted]) 

    const getReviews = (id) => {
        setLoading(true)
        setError(null)
        servicesService.getReviews(id).then(
            res => {
                setReviews(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    return (
            <div className="rounded-md border pb-7">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Reviews</p>    
                </div>
                <div>
                    {isCompleted ? (
                        <div>
                            {loading ? (
                                <div className='flex items-center justify-center h-44'>
                                    <Loading/>
                                </div>
                            ):(
                                <div className='p-2'>
                                    {error && (<FetchError error={error}/>)}
                                    {reviews?.map((review, idx) => (
                                        <div
                                            key={idx}
                                            className='bg-base-200 p-2 mb-3'>
                                            <div className="flex">
                                                <div className='flex-grow'>
                                                    <div className="flex gap-3">
                                                        {review?.rated_by && (
                                                            <div className='avatar mb-3'>
                                                                {utils.checkImage(service?.owner) ? (
                                                                    <div className="w-12 rounded-full">
                                                                        <img 
                                                                            src={review?.rated_by?.image} 
                                                                            alt={review?.rated_by?.email} />
                                                                    </div>
                                                                ):(
                                                                    <div>
                                                                        <div className="avatar placeholder">
                                                                            <div className="bg-neutral text-neutral-content rounded-full w-12">
                                                                                <span className="text-xl">{utils.makeProfileLetters(review?.rated_by)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                        <div>
                                                            <div className="flex gap-3 items-center">
                                                                <p className='text-gray-500'>
                                                                    {review?.rated_by.full_name ? (
                                                                        <span>{review?.rated_by?.full_name}</span>
                                                                    ):(
                                                                        <span>{review?.rated_by?.email}</span>
                                                                    )}
                                                                </p>
                                                                {review?.rated_by?.is_buyer ? (
                                                                    <AiOutlineUser className='h-7 w-5 text-accent font-bold'/>
                                                                ): null}
                                                                {review?.rated_by?.is_expert ? (
                                                                    <GrUserWorker className='h-7 w-5 text-accent font-bold'/>
                                                                ): null}
                                                            </div>                                                    
                                                            <p>Ratings: {review?.ratings}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-grow-0'>
                                                    <p className='flex gap-1 text-gray-500'>
                                                        <span>{moment(review?.creation_time).format("HH:MM:SS")}</span>
                                                        <span className='font-bold'>.</span>
                                                        <span>{moment(review?.creation_time).format("Do MMMM YYYY")}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                {review?.text}
                                            </div>
                                        </div>
                                    ))}
                                    {reviews?.length < 1 && (
                                        <Empty objectName={"Reviews"}/>
                                    )}
                                </div>
                            )}
                        </div>
                    ):(
                        <div className='p-2'>
                            <div>This gig is not Completed!</div>
                        </div>
                    )}
                </div>
            </div>
    )
}

export default ServiceReviews