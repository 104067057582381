import React, { useContext, useEffect } from 'react'
import { IoMdNotificationsOutline } from "react-icons/io"
import { IoIosSearch } from "react-icons/io"
import UserContext from '../../context/UserContext'
import utils from '../../utils'

function NavBar() {
    const userCtx = useContext(UserContext)
    const isAuth = userCtx.isAuth
    const user = userCtx.user
    useEffect(() => {}, [isAuth, user])
    return (
        <div className="navbar bg-base-100 mt-4 md:mt-6 lg:mt-9 mx-auto">
            <div className='navbar-start gap-3'>
                <div className="flex-none lg:hidden">
                    <label htmlFor="nav-drawer" className="btn btn-square btn-ghost">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-6 h-6 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                    </label>
                </div>
                <div className="flex-none hidden md:block">
                    {(isAuth && user) && (
                        <div className='flex flex-col'>
                            <p className='uppercase'>Welcome Back</p>
                            {user?.full_name ? (
                                <p className='text-lg'>{user?.full_name}</p>
                            ):(
                                <p className='text-lg'>{user?.email}</p>
                            )}                            
                        </div>
                    )}
                </div>
            </div>
            <div className="navbar-end">
                <div className='flex space-x-6 items-center'>
                    <div className='btn btn-circle btn-ghost'>
                        <IoIosSearch className='w-7 h-7 md:h-10 md:w-10'/>
                    </div>
                    <div className='btn btn-circle btn-ghost'>
                        <IoMdNotificationsOutline className='w-7 h-7 md:h-10 md:w-10'/>
                    </div>
                    <div className="avatar placeholder">
                        <div className="bg-neutral text-neutral-content rounded-full w-9 md:w-12 uppercase">
                            {user && (
                                <span>{utils.makeProfileLetters(user)}</span>
                            )}                            
                        </div>
                    </div> 
                </div>                
            </div>
        </div>
    )
}

export default NavBar