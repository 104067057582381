import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import servicesService from '../../services/ServicesService'
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { AiOutlineUser } from "react-icons/ai"
import { IoLocationOutline } from "react-icons/io5";
import { IoStarOutline } from "react-icons/io5";
import { IoMdAlarm } from "react-icons/io";
import BuyersSideBar from '../../components/buyers/BuyersSideBar'
import BuyerGigs from '../../components/buyers/BuyerGigs'
import ExpertProfile from '../../components/experts/ExpertProfile'
import BuyerVehicles from '../../components/buyers/BuyerVehicles'
import ExpertReferral from '../../components/experts/ExpertReferral'
import BuyerPromotions from '../../components/buyers/BuyerPromotions'
import BuyerSubscription from '../../components/buyers/BuyerSubscription'

function BuyerViewPage() {
    const [buyer, setBuyer] = useState(null)
    const [loading, setLoading] = useState(true)
    const [currentTab, setCurrentTab] = useState(0)
    const [error, setError] = useState(null)
    const idParam = useParams()

    useEffect(() => {
        if (idParam?.id) {
            getBuyer(idParam?.id)
        }
    }, [idParam?.id])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [buyer])
    useEffect(() => {}, [currentTab])

    const getBuyer = (id) => {
        setError()
        setLoading(true)
        servicesService.getUser(id).then(
            res => {
                console.log(res.data);
                setBuyer(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>Buyer - {utils.siteName}</title>
            </Helmet>
            <div className="p-2">
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>): null}
                        {buyer ? (
                            <div>
                                <div className='flex justify-end mb-3'>
                                    <div>
                                        <div className="dropdown">
                                            <label tabIndex="0" className="flex items-center gap-3 btn btn-ghost text-accent">
                                                <TfiLayoutGrid3 className='h-6 w-6'/>
                                                <p className='font-bold'>Actions</p>
                                            </label>
                                            <ul 
                                                tabIndex="0"
                                                className="menu menu-vertical dropdown-content mt-3 shadow bg-base-200 rounded-box text-start">
                                                <li className='p-2 hover:cursor-pointer'>Action 1</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {buyer && (
                                    <div className='flex justify-center mb-6'>
                                        <div className='avatar'>
                                            {utils.checkImage(buyer) ? (
                                                <div className="w-20 rounded-full">
                                                    <img 
                                                        src={utils.baseUrl + buyer?.image} 
                                                        alt={buyer?.email} />
                                                </div>
                                            ):(
                                                <div>
                                                    <div className="avatar placeholder">
                                                        <div className="bg-neutral text-neutral-content rounded-full w-20">
                                                            <span className="text-xl">{utils.makeProfileLetters(buyer)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="flex justify-center gap-3 items-center mb-6">
                                    <div className='text-lg font-bold'>
                                        {buyer?.full_name ? (
                                            <p>{buyer?.full_name}</p>
                                        ):(
                                            <p>{buyer?.email}</p>
                                        )}
                                    </div>
                                    <div>
                                        <AiOutlineUser className='h-7 w-5 text-accent font-bold'/>
                                    </div>
                                </div>
                                <div className="flex justify-center gap-3 items-center mb-6">
                                    <div className="flex gap-2 items-center">
                                        <IoMdAlarm className='h-5 w-5 text-accent'/>
                                        <p className='text-gray-500'>{buyer?.subscription}</p>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <IoLocationOutline className='h-5 w-5 text-accent'/>
                                        <p className='text-gray-500'>{buyer?.location}</p>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <IoStarOutline className='h-5 w-5 text-accent'/>
                                        <p className='text-gray-500'><span className='mr-1'>{buyer?.ratings}</span> <span>Ratings</span></p>
                                    </div>
                                </div>
                                <div className='md:flex gap-3'>
                                    <div className="md:w-4/12 lg:w-3/12 rounded-md">
                                        <BuyersSideBar
                                            currentTab={currentTab}
                                            setCurrentTab={setCurrentTab}
                                        />
                                    </div>
                                    <div className="md:w-8/12 lg:w-9/12">
                                        {currentTab === 0 && (
                                            <BuyerGigs
                                                buyer={buyer}/>
                                        )}
                                        {currentTab === 1 && (
                                            <ExpertProfile
                                                user={buyer}/>
                                        )}
                                        {currentTab === 2 && (
                                            <BuyerVehicles
                                                user={buyer}/>
                                        )}
                                        {currentTab === 3 && (
                                            <ExpertReferral
                                                user={buyer}/>
                                        )}
                                        {currentTab === 4 && (
                                            <BuyerPromotions
                                                user={buyer}/>
                                        )}
                                        {currentTab === 5 && (
                                            <BuyerSubscription
                                                buyer={buyer}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ):null}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default BuyerViewPage