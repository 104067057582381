/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import PromotionSteps from '../promotions/PromotionSteps'
import { FaAngleDown } from "react-icons/fa6";
import CreationVehicleRangeRow from '../serviceCharges/CreationVehicleRangeRow';
import gift from '../../assets/amico.png'
import StartingCostCreationModal from '../serviceCharges/StartingCostCreationModal';
import CommissionModal from '../serviceCharges/CommissionModal';
import toast from 'react-hot-toast';
import servicesService from '../../services/ServicesService';
import utils from '../../utils';

function ServiceChargesCreateModal(props) {
    const vehicleCategories = props.vehicleCategories
    const commission = props.commission
    const [name, setName] = useState('')
    const [baseRate, setBaseRate] = useState('')
    const [pricePerKm, setPricePerKm] = useState('')
    const [stopCost, setStopCost] = useState('')
    const [tractactionFee, setTransactionFee] = useState('')
    const [stage, setStage] = useState(0)
    const [activeTab, setActiveTab] = useState(0)
    const [activeCategory, setActiceCategory] = useState()
    const [action, setAction] = useState(0)
    const [startingCosts, setStartingCosts] = useState([])
    const [vehilceRangePerc, setVehilceRangePerc] = useState([])
    const [charges, setCharges] = useState()
    const [serviceType, setServiceType] = useState()
    let tabs = [
        "Coming Soon",
        "Launch Now"
    ]
    
    useEffect(() => {}, [name])
    useEffect(() => {}, [baseRate])
    useEffect(() => {}, [pricePerKm])
    useEffect(() => {}, [stopCost])
    useEffect(() => {}, [tractactionFee])
    useEffect(() => {}, [activeCategory])
    useEffect(() => {}, [stage])
    useEffect(() => {}, [activeTab])
    useEffect(() => {}, [action])
    useEffect(() => {}, [vehicleCategories])
    useEffect(() => {}, [startingCosts])
    useEffect(() => {}, [vehilceRangePerc])
    useEffect(() => {}, [charges])
    useEffect(() => {}, [serviceType])
    useEffect(() => {}, [commission])

    function openModal(val){
        setAction(val)
        const active = vehicleCategories.filter((item) => item?.id === val)
        setActiceCategory(active[0])
        const openBtn = document.getElementById("vehicle-starting-cost-create-open-btn")
        openBtn.click()
    }
    function closeModal(){
        const closeBtn = document.getElementById("service-charge-create-modal-close-btn")
        closeBtn.click()
    }
    function changeActiveTab(val){
        setActiveTab(val)
    }
    function checkStartingCosts(){
        if (startingCosts?.length < parseInt(6)) {
            return false
        }
        return true
    }
    function checkRangesPercentage(){
        if (vehilceRangePerc?.length < 4) {
            return false
        }
        return true
    }
    function checkChargesForm(){
        if (!name || !baseRate || !pricePerKm || !stopCost || !tractactionFee) {
            return false
        }
        return true
    }
    function changeStageOne(){
        const startingCostsValid = checkStartingCosts()
        const rangesValid = checkRangesPercentage()
        const chargesValid = checkChargesForm()
        if (!chargesValid) {
            toast.error("Please fill all Costs, Fees and Rates")
            return
        }
        if (!startingCostsValid) {
            toast.error("Please fill all Starting Costs")
            return
        } 
        if (!rangesValid){
            toast.error("Please fill all Vehicle Bracket Percentage")
            return
        }
        const chargeData = {
            "base_rate": baseRate,
            "price_per_km": pricePerKm,
            "stop_cost": stopCost,
            "transaction_fee": tractactionFee
        }
        setCharges(chargeData)
        setStage(1)
    }
    function saveServiceType(){
        const loadToast = toast.loading("Creating Service Type")
        const data = {
            "name": name
        }
        servicesService.createServiceType(data).then(
            res => {
                setServiceType(res.data)
                toast.success("Created Service Type Successfully", {
                    id: loadToast
                })
                saveCharges(res.data?.id)
            },
            err => {
                console.log(err);
                toast.error(utils.toasterError, {
                    id: loadToast
                })
            }
        )
    }
    function saveCharges(id){
        const loadToast = toast.loading("Creating Service Charges")
        charges["service_type"] = id
        servicesService.createServiceCharge(charges).then(
            res => {
                toast.success("Created Service Charges Successfully", {
                    id:loadToast
                })
                saveVehicleStartingCosts(id)
            },
            err => {
                console.log(err);
                toast.error(utils.toasterError, {
                    id: loadToast
                })
            }
        )
    }
    function saveVehicleStartingCosts(id){
        const loadToast = toast.loading("Creating Vehicle Starting Costs")
        const data = startingCosts.map(obj => ({
            ...obj,
            service_type: id
        }));
        servicesService.bulkCreateStartingCosts(data).then(
            res => {
                toast.success("Created Vehicle Starting Costs Successfully", {
                    id:loadToast
                })
                saveVehicleRangePercentages(id)
            }, 
            err => {
                console.log(err);
                toast.error(utils.toasterError, {
                    id: loadToast
                })
            }
        )

    }
    function saveVehicleRangePercentages(id){
        const loadToast = toast.loading("Creating Vehicle Range Percentages")
        const data = vehilceRangePerc.map(obj => ({
            ...obj,
            service_type: id
        }));
        servicesService.bulkCreateVehicleRangesPercentage(data).then(
            res => {
                toast.success("Created Vehicle Range Percentages Successfully", {
                    id:loadToast
                })
                createCommission(id)
            }, 
            err => {
                console.log(err);
                toast.error(utils.toasterError, {
                    id: loadToast
                })
            }
        )
    }
    function createCommission(id){
        const createToast = toast.loading("Creating Commission")
        const data = commission
        data["service_type"] = id
        servicesService.createCommission(data).then(
            res => {
                toast.success("Commission Created Successfull", {
                    id:createToast
                })
                closeModal()
                props.updateUI()
            }, 
            err => {
                console.log(err);
                toast.error(utils.toasterError, {
                    id:createToast
                })
            }
        )
    }
    function completeSaving(){
        const charge = charges
        charge["status"] = activeTab === 0 ? "Coming Soon":"Live"
        setCharges(charge)
        saveServiceType()
    }
    return (
        <div>
            <label htmlFor="service-charge-create-modal" id='service-charge-create-open-btn' className="hidden">Create Service Charge</label>
            <input type="checkbox" id="service-charge-create-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <CommissionModal
                        action={2}
                    />
                    <StartingCostCreationModal
                        action={action}
                        activeCategory={activeCategory}
                        setStartingCosts={setStartingCosts}
                    />
                    <label htmlFor="service-charge-create-modal" id='service-charge-create-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-2'>Add a Service</h2>
                    <div className='px-12'>
                        <PromotionSteps stage={stage}/>
                    </div>
                    {stage === 0 && (
                        <div className=''> 
                            <form>
                                <div className='flex w-full justify-between gap-5 mb-3'> 
                                    <label className="form-control w-full">
                                        <div className="label">
                                            <span className="label-text mb-1">Service Name <span className='text-red-600 ml-1'>*</span></span>
                                        </div>
                                        <input 
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            type="text" 
                                            required
                                            placeholder="Enter Service Name" 
                                            className="input input-bordered w-full" />
                                    </label>
                                    <label className="form-control w-full">
                                        <div className="label">
                                            <span className="label-text mb-1">Service Base Rate <span className='text-red-600 ml-1'>*</span></span>
                                        </div>
                                        <input 
                                            value={baseRate}
                                            onChange={(e) => setBaseRate(parseInt(e.target.value))}
                                            type="number"
                                            required 
                                            placeholder="Enter Amount" 
                                            className="input input-bordered w-full" />
                                    </label>
                                </div>
                                <div className='flex w-full justify-between gap-5 mb-3'>
                                    <label className="form-control w-full">
                                        <div className="label">
                                            <span className="label-text mb-1">Price per Kilometer <span className='text-red-600 ml-1'>*</span></span>
                                        </div>
                                        <input 
                                            value={pricePerKm}
                                            onChange={(e) => setPricePerKm(parseInt(e.target.value))}
                                            type="number"
                                            required 
                                            placeholder="Enter Service Name" 
                                            className="input input-bordered w-full" />
                                    </label>
                                    <label className="form-control w-full">
                                        <div className="label">
                                            <span className="label-text mb-1">Stop Cost <span className='text-red-600 ml-1'>*</span></span>
                                        </div>
                                        <input
                                            value={stopCost}
                                            onChange={(e) => setStopCost(parseInt(e.target.value))} 
                                            type="number" 
                                            required
                                            placeholder="Enter Amount" 
                                            className="input input-bordered w-full" />
                                    </label>
                                    <label className="form-control w-full">
                                        <div className="label">
                                            <span className="label-text mb-1">Transaction Fee <span className='text-red-600 ml-1'>*</span></span>
                                        </div>
                                        <input 
                                            value={tractactionFee}
                                            onChange={(e) => setTransactionFee(parseInt(e.target.value))} 
                                            type="number"
                                            required 
                                            placeholder="Enter Percentage" 
                                            className="input input-bordered w-full" />
                                    </label>
                                </div>
                                <div className='py-2'></div>
                                <div className='mb-3'>
                                    <div className="label">
                                        <span className="label-text mb-1">Starting Cost <span className='text-red-600 ml-1'>*</span></span>
                                    </div>
                                    <div className="flex justify-center bg-base-200" >
                                        <div className="border rounded-l flex-grow">
                                            <div className="label">
                                                <span className="label-text">For Personal Vehicles</span>
                                            </div>
                                            <div className=''>
                                                <div
                                                    onClick={() => openModal(1)} 
                                                    className='btn w-full justify-between'>Enter Amount <FaAngleDown/> </div>
                                            </div>
                                        </div>
                                        <div className="border rounded-r flex-grow">
                                            <div className="label">
                                                <span className="label-text">For Commercial Vehicles</span>
                                            </div>
                                            <div className='w-full'>
                                                <div
                                                    onClick={() => openModal(2)} 
                                                    className='btn w-full justify-between'>Enter Amount <FaAngleDown/></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className='py-2'></div>
                            <div>
                                <p className=' mb-2'>No of Vehicle Brackets</p>
                                <div className="overflow-x-auto mb-3">
                                    <table className="table table-md rounded-t">
                                        <thead className='bg-base-200 '>
                                            <tr className='text-md'>
                                                <td>Number Range</td>
                                                <td>Percentage Price of Price per Kilometer</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <CreationVehicleRangeRow
                                                setVehilceRangePerc={setVehilceRangePerc}
                                                vehilceRangePerc={vehilceRangePerc}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='modal-action flex justify-end gap-3'>
                                <div
                                    onClick={() => closeModal()}
                                    className='btn btn-ghost text-accent'>Cancel</div>
                                <button
                                    onClick={() => changeStageOne()}
                                    className='btn text-white btn-accent'>Save</button>
                            </div>
                        </div>
                    )}
                    {stage === 1 && (
                        <>
                            <div className="flex justify-center mb-6">
                                <img src={gift} alt='icon'/>
                            </div>
                            <p className='text-center text-gray-500 mb-6 md:mx-32'>
                                Service setup is complete. Choose to keep the service pending or launch to make it available to experts
                            </p>
                            <div className="flex justify-center mb-12">
                                <div className="tabs tabs-boxed">
                                    {tabs.map((tab, idx) => (
                                        <a 
                                            key={idx}
                                            className={activeTab === idx ? 'tab tab-active' : 'tab'}
                                            onClick={() => changeActiveTab(idx)}
                                        >{tab}</a>
                                    ))}
                                </div>
                            </div>
                            <div className='modal-action flex justify-end gap-3'>
                                <div
                                    onClick={() => setStage(0)}
                                    className='btn btn-ghost text-accent'>Cancel</div>
                                <button
                                    onClick={() => completeSaving()}
                                    className='btn text-white btn-accent'>Complete</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ServiceChargesCreateModal