/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BiSort } from "react-icons/bi";
import Select from 'react-select';
import FetchError from '../feedback/FetchError';
import Loading from '../feedback/Loading';
import servicesService from '../../services/ServicesService';
import Empty from '../feedback/Empty';
import ServiceMadeTableRow from './ServiceMadeTableRow';
import Pagination from '../nav/Pagination';

const options = [
    { value: 'none', label: 'Order By' },
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
]

function ServiceTypeGigs(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeTab, setActiveTab] = useState(0)
    const [services, setServices] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const orderingParam = searchParams.get("ordering")
    const [selectedValue, setSelectedValue] = useState(null);
    const charge = props.charge
    const [hasFiltered, setHasFiltered] = useState(false)

    useEffect(() => {
        if (charge?.id) {
            setSearchParams((params) => {
                params.set("service_type__id", charge?.id)
                return params
            })
        }
    }, [charge?.id, setSearchParams])

    useEffect(() => {
        if (activeTab === 0) {
            setSearchParams((params) => {
                params.set("status", "Active")
                return params
            })
        }
        if (activeTab === 1) {
            setSearchParams((params) => {
                params.set("scheduled", true)
                return params
            })
        }
        if (activeTab === 2){
            setSearchParams((params) => {
                params.set("status", "Finished")
                return params
            })
            
        }
        if (activeTab === 3){
            setSearchParams((params) => {
                params.set("status", "Cancelled")
                return params
            })
            
        }
    }, [activeTab, searchParams, setSearchParams])
    useEffect(() => {}, [charge])
    useEffect(() => {
        if (orderingParam && (!hasFiltered)) {
            if (orderingParam === 'creation_time') {
                const defaultOption = options.find(option => option.value === 'asc')
                setSelectedValue(defaultOption)
            } else if (orderingParam === '-creation_time') {
                const defaultOption = options.find(option => option.value === 'desc')
                setSelectedValue(defaultOption)
            }
        }
    }, [orderingParam, hasFiltered])
    useEffect(() => {
        if (!orderingParam) {
            const defaultOption = options.find(option => option.value === 'none')
            setSelectedValue(defaultOption);
        }
    }, [orderingParam])
    useEffect(() => {
        if (selectedValue) {
            if (selectedValue?.value === 'asc') {
                setSearchParams((params) => {
                    params.set("ordering", 'creation_time')
                    return params
                })
            }else if (selectedValue?.value === 'desc') {
                setSearchParams((params) => {
                    params.set("ordering", '-creation_time')
                    return params
                })
            } else if (selectedValue?.value === 'none') {
                setSearchParams((params) => {
                    params.delete("ordering")
                    return params
                })
            }
        }
    }, [selectedValue, setSearchParams])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getServices(params ? `?${params}` : '')
        }
    }, [searchParams])
    useEffect(() => {}, [services])
    
    const handleChange = (selectedOption) => {
        setHasFiltered(true)
        setSelectedValue(selectedOption);
    };

    const getServices = (params) => {
        setLoading(true)
        setError()
        servicesService.getServicesList(params ? `${params}` : '').then(
            res => {
                setServices(res.data)
                setLoading(false)
            }, 
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    function changeTab(val){
        const allParams = [...searchParams.entries()]
        const remove = allParams.filter((param) => param[0] !== 'service_type__id')
        if (remove.length) {
            remove.forEach(element => {
                setSearchParams((params) => {
                    params.delete(element[0])
                    return params
                })
            });
        } 
        setActiveTab(val)
    }
    return (
        <div className="rounded-md border pb-4 p-2">
            <div role="tablist" className="tabs tabs-bordered mb-6">
                <a 
                    onClick={() => changeTab(0)}
                    role="tab" 
                    className={activeTab === 0 ? 'tab tab-active':'tab'}>
                    <span className='mr-2'>Ongoing</span>
                </a>
                <a 
                    onClick={() => changeTab(1)}
                    role="tab" 
                    className={activeTab === 1 ? 'tab tab-active':'tab'}>
                    <span className='mr-2'>Scheduled</span>
                </a>
                <a 
                    onClick={() => changeTab(2)}
                    role="tab" 
                    className={activeTab === 2 ? 'tab tab-active':'tab'}>
                    <span className='mr-2'>Complete</span>
                </a>
                <a 
                    onClick={() => changeTab(3)}
                    role="tab" 
                    className={activeTab === 3 ? 'tab tab-active':'tab'}>
                    <span className='mr-2'>Cancelled</span>
                </a>
            </div>
            <div className="flex gap-6 items-center mb-6">
                <div className='flex items-center gap-3'>
                    <BiSort className='w-8 h-8' color='#42B883'/>
                    <Select
                        className='select select-ghost'
                        value={selectedValue}
                        onChange={handleChange}
                        options={options}
                    />
                </div>
            </div>
            {loading ? (
                <div className='flex items-center justify-center h-44'>
                    <Loading/>
                </div>
            ):(
                <div>
                    {error ? (<FetchError error={error}/>) : (
                        <div>
                            <div className="overflow-x-auto mb-6">
                                <table className="table table-zebra table-xs">
                                    <thead>
                                        <tr className='text-sm'>
                                            <td>Expert</td>
                                            <td>Buyer</td>
                                            <td>Pick Up</td>
                                            <td>Destination</td>
                                            <td>Progress</td>
                                            <td>Cost</td>
                                            <td>Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {services?.results?.map((service, idx) => (
                                            <ServiceMadeTableRow
                                                key={idx}
                                                service={service}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                                {services?.count < 1 ? (
                                    <Empty objectName={"Service Gigs"}/>
                                ):(
                                    <div className=" p-3">
                                        {services && (
                                            <Pagination
                                                count={services?.count}
                                                next={services?.next}
                                                previous={services?.previous}
                                                changeLimitParam={changeLimitParam}
                                                changePageParam={changePageParam}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default ServiceTypeGigs