import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import servicesService from '../../services/ServicesService'
import { useParams } from 'react-router-dom'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import { TfiLayoutGrid3 } from 'react-icons/tfi'
import { GrUserWorker } from 'react-icons/gr'
import { IoLocationOutline } from 'react-icons/io5'
import PersonalDetails from '../../components/applications/PersonalDetails'
import AccountDetails from '../../components/applications/AccountDetails'
import PaymentDetails from '../../components/applications/PaymentDetails'
import Uploads from '../../components/applications/Uploads'
import toast from 'react-hot-toast'
import expertsServices from '../../services/ExpertsServices'
import { ImCancelCircle } from "react-icons/im";

function ApplicationsViewPage() {
    const [expert, setExpert] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const idParam = useParams()

    useEffect(() => {
        if (idParam?.id) {
            getExpert(idParam?.id)
        }
    }, [idParam?.id])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [expert])

    const getExpert = (id) => {
        setError()
        setLoading(true)
        servicesService.getUser(id).then(
            res => {
                setExpert(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function scroll(sectionId){
        const scrollSection = document.getElementById(sectionId)
        scrollSection.scrollIntoView()
    }
    function approveExpert(id){
        const approvalToast = toast.loading("Approving Expert")
        const data= {
            "is_approved": 1
        }
        expertsServices.updateExpert(id, data).then(
            res => {
                console.log(res.data);
                toast.success("Approval Successfull", {
                    id:approvalToast
                })
                setExpert(res.data)
            },
            err => {
                toast.error(utils.toasterError, {
                    id:approvalToast
                })
            }
        )
    }
    function declineExpert(id){
        const approvalToast = toast.loading("Declining Expert")
        const data= {
            "is_dissaproved": 1
        }
        expertsServices.updateExpert(id, data).then(
            res => {
                console.log(res.data);
                toast.success("Declined Successfully", {
                    id:approvalToast
                })
                setExpert(res.data)
            },
            err => {
                toast.error(utils.toasterError, {
                    id:approvalToast
                })
            }
        )
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Account View - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <div className='flex justify-end mb-3'>
                                    <div>
                                        <div className="dropdown">
                                            <label tabIndex="0" className="flex items-center gap-3 btn btn-ghost text-accent">
                                                <TfiLayoutGrid3 className='h-6 w-6'/>
                                                <p className='font-bold'>Actions</p>
                                            </label>
                                            <ul 
                                                tabIndex="0"
                                                className="menu menu-vertical dropdown-content mt-3 shadow bg-base-200 rounded-box text-start">
                                                <li className='p-2 hover:cursor-pointer'>Action 1</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {expert && (
                                    <div className='flex justify-center mb-4'>
                                        <div className='avatar'>
                                            {utils.checkImage(expert) ? (
                                                <div className="w-20 rounded-full">
                                                    <img 
                                                        src={utils.baseUrl + expert?.image} 
                                                        alt={expert?.email} />
                                                </div>
                                            ):(
                                                <div>
                                                    <div className="avatar placeholder">
                                                        <div className="bg-neutral text-neutral-content rounded-full w-20">
                                                            <span className="text-xl">{utils.makeProfileLetters(expert)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="flex justify-center gap-3 items-center mb-3">
                                    <div className='text-lg font-bold'>
                                        {expert?.full_name ? (
                                            <p>{expert?.full_name}</p>
                                        ):(
                                            <p>{expert?.email}</p>
                                        )}
                                    </div>
                                    <div>
                                        <GrUserWorker className='h-7 w-5 text-accent font-bold'/>
                                    </div>
                                </div>
                                <div className="flex justify-center gap-3 items-center mb-6">
                                    <div className="flex gap-2 items-center">
                                        <IoLocationOutline className='h-5 w-5 text-accent'/>
                                        <p className='text-gray-500'>
                                            {expert?.location ? (
                                                <span>{expert?.location}</span>
                                            ):(
                                                <span>{expert?.location}</span>
                                            )}
                                            <span className='ml-1'>, {expert?.country}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className='md:flex gap-3 p-3'>
                                    <div className="md:w-4/12 lg:w-3/12 rounded-md">
                                        <div className='sticky top-1'>
                                            <div
                                                onClick={() => scroll("personal-details")} 
                                                className="join-item text-pretty text-center border py-4 hover:cursor-pointer">
                                                Personal Details
                                            </div>
                                            <div 
                                                onClick={() => scroll("account-details")} 
                                                className="join-item text-pretty text-center border py-4 hover:cursor-pointer">
                                                Account Details
                                            </div>
                                            <div 
                                                onClick={() => scroll("payment-details")}
                                                className="join-item text-pretty text-center border py-4 hover:cursor-pointer">
                                                Payment Details
                                            </div>
                                            <div
                                                onClick={() => scroll("uploads")}  
                                                className="join-item text-pretty text-center border py-4 hover:cursor-pointer">
                                                Uploads
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:w-8/12 lg:w-9/12">
                                        <div className="mb-3">
                                            <PersonalDetails
                                                expert={expert}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <AccountDetails
                                                expert={expert}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <PaymentDetails
                                                expert={expert}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <Uploads 
                                                expert={expert}/>
                                        </div>
                                        {expert?.is_dissaproved ? (
                                            <div className='p-3 mb-9'>
                                                <div className="rounded-md border p-4 pb-5">
                                                    <div className="flex gap-3 items-center">
                                                        <ImCancelCircle  className='h-7 w-7 text-red-600'/>
                                                        <p className='font-bold'>This Expert Account Application has been Declined.</p>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        ):(
                                            <>
                                                {!expert?.is_approved && (
                                                    <div className='mb-16 px-3'>
                                                        <div className="flex gap-5">
                                                            <button 
                                                                onClick={() => declineExpert(expert?.id)}
                                                                className='btn text-red-600'>Decline Application</button>
                                                            <button 
                                                                onClick={() => approveExpert(expert?.id)}
                                                                className='btn btn-accent text-white'>Approve Application</button>
                                                        </div>
                                                    </div>
                                                )}                                        
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default ApplicationsViewPage