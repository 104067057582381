import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import { useParams } from 'react-router-dom'
import servicesService from '../../services/ServicesService'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import { GiTowTruck } from "react-icons/gi";
import ServiceParticipants from '../../components/services/ServiceParticipants'
import ServiceSideBar from '../../components/services/ServiceSideBar'
import ServiceDetails from '../../components/services/ServiceDetails'
import ServiceTimeLine from '../../components/services/ServiceTimeLine'
import ServiceVehicles from '../../components/services/ServiceVehicles'
import ServiceProof from '../../components/services/ServiceProof'
import CostBreakDown from '../../components/services/CostBreakDown'
import PaymentTransaction from '../../components/services/PaymentTransaction'
import ServiceReviews from '../../components/services/ServiceReviews'

function ServiceViewPage() {
    const [isCompleted, setIsCompleted] = useState(false)
    const [service, setService] = useState(null)
    const [loading, setLoading] = useState(true)
    const [currentTab, setCurrentTab] = useState(0)
    const [error, setError] = useState()
    const idParam = useParams()
    
    useEffect(() => {
        if (idParam?.id) {
            getService(idParam?.id)
        }
    }, [idParam])
    useEffect(() => {
        if (service) {
            if (
                (service?.status === "Finished")
                ||
                (service?.status === "Cancelled")
             ) {
                setIsCompleted(true)
            }
        }
    }, [service])
    useEffect(() => {}, [currentTab])
    useEffect(() => {}, [error])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [isCompleted])

    const getService = (id) => {
        servicesService.getParticularService(id).then(
            res => {
                setService(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Service View - {utils.siteName}</title>
            </Helmet>
            {loading ? (
                <div className='flex items-center justify-center h-44'>
                    <Loading/>
                </div>
            ):(
                <div>
                    {error ? (<FetchError error={error}/>):(
                        <div className='p-3'>
                            <div className="mb-6 flex justify-center">
                                {service?.service_type?.name === "Towing" ? (
                                    <div className=' rounded-full p-4 shadow-md hover:shadow-xl'>
                                        <GiTowTruck className=' h-12 w-12' style={{color: "#42B883"}}/>
                                    </div>
                                ):null}
                            </div>
                            <p className="text-lg font-bold text-center mb-6">{service?.service_type?.name}</p>
                            <div className='md:mx-72 lg:mx-80 mb-12'>
                                <ServiceParticipants
                                    service={service}
                                />
                            </div>
                            <div className='md:flex gap-3'>
                                <div className="md:w-4/12 lg:w-3/12 rounded-md">
                                    <ServiceSideBar
                                        currentTab={currentTab}
                                        setCurrentTab={setCurrentTab}
                                    />
                                </div>
                                <div className="md:w-8/12 lg:w-9/12">
                                    {currentTab === 0 && (
                                        <ServiceDetails
                                            service={service}/>
                                    )}
                                    {currentTab === 1 && (
                                        <ServiceTimeLine
                                            isCompleted={isCompleted}
                                            service={service}/>
                                    )}
                                    {currentTab === 2 && (
                                        <ServiceVehicles
                                            service={service}/>
                                    )}
                                    {currentTab === 3 && (
                                        <ServiceProof
                                            isCompleted={isCompleted}
                                            service={service}/>
                                    )}
                                    {currentTab === 4 && (
                                        <CostBreakDown
                                            service={service}/>
                                    )}
                                    {currentTab === 5 && (
                                        <PaymentTransaction
                                            isCompleted={isCompleted}
                                            service={service}/>
                                    )}
                                    {currentTab === 6 && (
                                        <ServiceReviews
                                            isCompleted={isCompleted}
                                            service={service}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </HelmetProvider>
    )
}

export default ServiceViewPage