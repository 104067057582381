import React, { useEffect } from 'react'
import Subscription from './Subscription'
import { IoAddCircleOutline } from "react-icons/io5";
import AddSubscriptionModal from './AddSubscriptionModal';
import Empty from '../feedback/Empty';

function SubscriptionsSections(props) {
    const subcriptionPlans = props.subcriptionPlans
    const billingPeriods = props.billingPeriods

    useEffect(() => {}, [subcriptionPlans])
    useEffect(() => {}, [billingPeriods])

    function openModal(){
        const openBtn = document.getElementById("subscription-add-open-btn")
        openBtn.click()
    }
    return (
        <div className='p-3'>
            <div className='border rounded-md'>
                <AddSubscriptionModal
                    updateUI={props.updateUI}
                />
                <div className='p-4 border-b flex justify-between items-center'>
                    <p className='font-bold md:text-lg text-gray-600'>Subscription Plans</p> 
                    <div>
                        <button 
                            onClick={() => openModal()}
                            className='btn btn-accent text-white'><IoAddCircleOutline className='w-5 h-5'/> Add Subsription Plan</button>
                    </div>
                </div>
                <div className="p-4">
                    {subcriptionPlans && (
                        <div>
                            {subcriptionPlans?.length < 1 ? (
                                <Empty objectName="Subscription Plans"/>
                            ):(
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                                    {subcriptionPlans?.map((subscription, idx) => (
                                        <Subscription
                                            key={idx}
                                            subscription={subscription}
                                            billingPeriods={billingPeriods}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SubscriptionsSections