import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import utils from '../../utils'

function PayoutModal(props) {
    const activePayment= props.activePayment
    
    useEffect(() => {}, [activePayment])
    return (
        <div>
            <label htmlFor="pending-payout-modal" id='pending-payout-open-btn' className="hidden">Mark Commision Paid</label>
            <input type="checkbox" id="pending-payout-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <label htmlFor="pending-payout-modal" id='pending-payout-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-4'>Payout From Expert</h2>
                    <div className="p-7 mt-6">
                        <div className="flex justify-center mb-3">
                            {activePayment?.service_request?.responder && (
                                <div className="flex items-center gap-3">
                                    <div className='avatar'>
                                        {utils.checkImage(activePayment?.service_request?.responder) ? (
                                            <div className=" w-24 rounded-full">
                                                <img src={activePayment?.service_request?.responder?.image} alt={activePayment?.service_request?.responder?.email} />
                                            </div>
                                        ):(
                                            <div>
                                                <div className="avatar placeholder">
                                                    <div className="bg-neutral text-neutral-content rounded-full w-24">
                                                        <span className="text-xl">{utils.makeProfileLetters(activePayment?.service_request?.responder)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex justify-center mb-1">
                            <div className='flex flex-col gap-2'>
                                <div>
                                    {activePayment?.service_request?.responder?.full_name ? (
                                        <p>{activePayment?.service_request?.responder?.full_name}</p>
                                    ):(
                                        <p>{activePayment?.service_request?.responder?.email}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center mb-6">
                            <Link
                                className='text-accent' 
                                to={`/experts/${activePayment?.service_request?.responder?.id}`}>View Profile</Link>
                        </div>
                        <div className="border p-3 rounded-md mb-9">
                            <div className="flex justify-around">
                                <div>
                                    <p>Service Charges:</p>
                                    <p className='stat text-2xl'>{activePayment?.service_charge}</p>
                                </div>
                                <div>
                                    <p>Payout Amount:</p>
                                    <p className='stat text-2xl'>{activePayment?.paid_commission}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayoutModal