import React, { useEffect } from 'react'
import utils from '../../utils'
import moment from 'moment'
import { FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5"
import { useNavigate } from 'react-router-dom';

function ServiceTxTableRow(props) {
    const payment = props.payment
    const navigate = useNavigate()
    
    useEffect(() => {}, [payment])
    return (
        <tr className='mb-3'>
            <td>
                {payment?.service_request?.owner ? (
                    <div className="flex items-center gap-3">
                        <div className='avatar'>
                            {utils.checkImage(payment?.service_request?.owner) ? (
                                <div className=" w-11 rounded-full">
                                    <img 
                                        src={payment?.service_request?.owner?.image} 
                                        alt={payment?.service_request?.owner.email} />
                                </div>
                            ):(
                                <div>
                                    <div className="avatar placeholder">
                                        <div className="bg-neutral text-neutral-content rounded-full w-11">
                                            <span className="text-xl">{utils.makeProfileLetters(payment?.service_request?.owner)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className=''>
                            <div>
                                {payment?.service_request?.owner?.full_name ? (
                                    <p>{payment?.service_request?.owner?.full_name}</p>
                                ):(
                                    <p>{payment?.service_request?.owner?.email}</p>
                                )}
                            </div>
                        </div>
                    </div>
                ):(
                    <span>-</span>
                )}
            </td>
            <td>
                {payment?.service_request?.service_type?.name}
            </td>
            <td>
                {moment(payment?.last_updated_time).format("Do MMMM YYYY")}
            </td>
            <td>
                {utils.toCommas(payment?.total)}
            </td>
            <td>
                {payment?.has_disbursed ? <FaCheck/> : <FaXmark/>}
            </td>
            <td>
                <button 
                    onClick={() => navigate(`/service/${payment?.service_request?.id}`)}
                    className='btn btn-ghost' 
                    style={{color: "#42B883"}}><IoSettingsOutline/> Manage</button>
            </td>
        </tr>
    )
}

export default ServiceTxTableRow