import React, { useEffect } from 'react'
import utils from '../../utils'
import { AiOutlineUser } from "react-icons/ai"
import { GrUserWorker } from "react-icons/gr"
import { FaUserXmark } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function ServiceParticipants(props) {
    const service = props.service
    useEffect(() => {}, [service])
    return (
        <div>
            <div className="flex items-center gap-6">
                <div className="divider-start text-nowrap">
                    <div className=''>
                        <div className="flex justify-end">
                            {service?.owner && (
                                <div className='avatar'>
                                    {utils.checkImage(service?.owner) ? (
                                        <div className="w-16 rounded-full">
                                            <img 
                                                src={utils.baseUrl + service?.owner?.image} 
                                                alt={service?.owner?.email} />
                                        </div>
                                    ):(
                                        <div>
                                            <div className="avatar placeholder">
                                                <div className="bg-neutral text-neutral-content rounded-full w-16">
                                                    <span className="text-xl">{utils.makeProfileLetters(service?.owner)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="flex justify-end">
                            <div className='flex justify-between gap-3 items-center'>
                                <div className=''>
                                    {service?.owner?.full_name ? (
                                        <p>{service?.owner?.full_name}</p>
                                    ):(
                                        <p>{service?.owner?.email}</p>
                                    )}
                                </div>
                                <div>
                                    <AiOutlineUser className='h-7 w-5 text-accent font-bold'/>
                                </div>
                            </div>
                        </div>
                        <Link
                            to={`/buyers/${service?.owner?.id}`}        
                            className='text-accent'>Buyer Profile</Link>
                    </div>
                </div>
                <div className=" w-full divider"></div>
                <div className="divider-end text-nowrap">
                    {service?.responder ? (
                        <div>
                            <div className="flex justify-start">
                                <div className='avatar'>
                                    {utils.checkImage(service?.responder) ? (
                                        <div className="w-16 rounded-full">
                                            <img 
                                                src={utils.baseUrl + service?.responder?.image} 
                                                alt={service?.responder?.email} />
                                        </div>
                                    ):(
                                        <div>
                                            <div className="avatar placeholder">
                                                <div className="bg-neutral text-neutral-content rounded-full w-16">
                                                    <span className="text-xl">{utils.makeProfileLetters(service?.responder)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-start">
                                <div className='flex justify-between gap-3 items-center'>
                                    <div className=''>
                                        {service?.responder?.full_name ? (
                                            <p>{service?.responder?.full_name}</p>
                                        ):(
                                            <p>{service?.responder?.email}</p>
                                        )}
                                    </div>
                                    <div>
                                        <GrUserWorker className='h-7 w-5 text-accent font-bold'/>
                                    </div>
                                </div>
                            </div>
                            <Link
                                to={`/experts/${service?.responder?.id}`}        
                                className='text-accent'>Expert Profile</Link>
                        </div>
                    ):(
                        <div className='justify-start'>
                            <div className="avatar placeholder">
                                <div className="bg-neutral text-neutral-content rounded-full w-16">
                                    <span className="text-xl"><FaUserXmark/></span>
                                </div>
                            </div>
                            <p className='text-accent'>None Yet!</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ServiceParticipants