import React, { useEffect, useState } from 'react'

function VehicleInput(props) {
    const [startingCost, setStartingCost] = useState(0)
    const vehicle = props.vehicle

    useEffect(() => {
        if (vehicle) {
            setStartingCost(vehicle?.starting_cost)
        }
    }, [vehicle])

    function updateValue(val, id){
        setStartingCost(val)
        const data = {
            'id': id,
            'starting_cost': val
        }
        props.performChange(data)
    }
    return (
        <div className='flex justify-between items-center'>
            <div>
                <p>{vehicle?.vehicle_type?.name}</p>
            </div>
            <div>
                <label className="form-control w-full">
                    <input 
                        value={startingCost}
                        onChange={(e) => updateValue(e.target.value, vehicle?.id)}
                        type="number" 
                        placeholder="Enter Amount" 
                        className="input input-bordered w-full input-sm" />
                </label>
            </div>
        </div>
    )
}

export default VehicleInput