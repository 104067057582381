import React, { useEffect } from 'react'

function ChargesViewModal(props) {
    const activeCharge = props.activeCharge

    useEffect(() => {}, [activeCharge])
    return (
        <div>
            <label htmlFor="charge-view-modal" id='charge-view-open-btn' className="hidden">View Service Charge</label>
            <input type="checkbox" id="charge-view-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <label htmlFor="charge-view-modal" id='charge-view-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-4'>{activeCharge?.service_type?.name}</h2>
                    <div className="p-7 mt-9">
                        <p>Service Name: <span className='ml-2'>{activeCharge?.service_type?.name}</span></p>
                        <p>Base Rate: <span className='ml-2'>{activeCharge?.base_rate}</span></p>
                        <p>Price per Kilometer: <span className='ml-2'>{activeCharge?.price_per_km}</span></p>
                        <p>Stop Cost: <span className='ml-2'>{activeCharge?.stop_cost}</span></p>
                        <p>Transaction Fee: <span className='ml-2'>{activeCharge?.transaction_fee}</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChargesViewModal