import apiClient from './AxiosHttp'

const api = '/api/v1'

const getGeneralStatistics = () => {
    return apiClient.get(`${api}/dashboard/services/statistics/`)
}

const getServicesList = (params) => {
    return apiClient.get(`${api}/dashboard/services/list/${params}`)
}

const getParticularService = (id) => {
    return apiClient.get(`${api}/dashboard/service/get/${id}/`)
}

const getDeliveryProof = (id) => {
    return apiClient.get(`${api}/service/requests/get/delivery/proof/${id}/`)
}

const getReviews = (id) => {
    return apiClient.get(`${api}/dashboard/service/reviews/get/${id}/`)
}

const getUser = (id) => {
    return apiClient.get(`${api}/dashboard/user/get/${id}/`)
}

const getServiceCharges = (params) => {
    return apiClient.get(`${api}/dashboard/service/charges/list/${params}`)
}

const getParticularServiceCharge = (id) => {
    return apiClient.get(`${api}/dashboard/service/charges/get/${id}/`)
}

const modifyServiceCharge = (id, details) => {
    return apiClient.patch(`${api}/payments/service/charges/modify/${id}/`, details)
}

const getOfferedServices = () => {
    return apiClient.get(`${api}/services/offered/`)
}

const getVehicleCategories = () => {
    return apiClient.get(`${api}/vehicles/categories/`)
}

const updateStatingCosts = (data) => {
    return apiClient.put(`${api}/dashboard/update/starting/costs/`, data)
}

const getVehicleRangesPercentage = (params) => {
    return apiClient.get(`${api}/dashboard/vehicle/ranges/percentage/${params}`)
}

const updateVehicleRangesPercentage = (data) => {
    return apiClient.put(`${api}/dashboard/update/vehicle/ranges/percentage/`, data)
}

const createServiceType = (data) => {
    return apiClient.post(`${api}/services/offered/`, data)
}

const createServiceCharge = (data) => {
    return apiClient.post(`${api}/payments/service/charges/`, data)
}

const bulkCreateVehicleRangesPercentage = (data) => {
    return apiClient.post(`${api}/dashboard/bulk/create/vehicle/ranges/percentage/`, data)
}

const bulkCreateStartingCosts = (data) => {
    return apiClient.post(`${api}/dashboard/bulk/create/vehicle/starting/costs/`, data)
}

const getCommision = (params) => {
    return apiClient.get(`${api}/dashboard/commission/list/${params}`)
}

const createCommission = (details) => {
    return apiClient.post(`${api}/dashboard/commission/create/`, details)
}

const updateCommission = (id, details) => {
    return apiClient.patch(`${api}/dashboard/commission/modify/${id}/`, details)
}

const getStartingCosts = (params) => {
    return apiClient.get(`${api}/dashboard/starting/cost/list/${params}`)
}


const servicesService = {
    getGeneralStatistics,
    getServicesList,
    getParticularService,
    getDeliveryProof,
    getReviews,
    getUser,
    getServiceCharges,
    getParticularServiceCharge,
    getOfferedServices,
    modifyServiceCharge,
    getVehicleCategories,
    updateStatingCosts,
    getVehicleRangesPercentage,
    updateVehicleRangesPercentage,
    createServiceType,
    createServiceCharge,
    bulkCreateVehicleRangesPercentage,
    bulkCreateStartingCosts,
    getCommision,
    createCommission,
    updateCommission,
    getStartingCosts,
}

export default servicesService