import React, { useEffect, useState } from 'react'

function StartingCostInput(props) {
    const [startingCost, setStartingCost] = useState('')
    const vehicle = props.vehicle
    const activeCategory = props.activeCategory
    const changesOne = props.changesOne
    const changesTwo = props.changesTwo
    const action = props.action

    useEffect(() => {}, [vehicle])
    useEffect(() => {}, [activeCategory])
    useEffect(() => {}, [changesOne])
    useEffect(() => {}, [changesTwo])
    useEffect(() => {
        if (action === 1 && changesOne?.length) {
            const existingObject = changesOne?.find((item) => item?.vehicle_type === vehicle?.id)
            if (existingObject) {
                if (existingObject?.starting_cost !== startingCost) {
                    setStartingCost(existingObject?.starting_cost)
                }
            } else(
                setStartingCost(0)
            )
        }
        if (action === 2 && changesTwo?.length) {
            const existingObject = changesTwo?.find((item) => item?.vehicle_type === vehicle?.id)
            if (existingObject) {
                if (existingObject?.starting_cost !== startingCost) {
                    setStartingCost(existingObject?.starting_cost)
                }
            } else(
                setStartingCost(0)
            )
        }
    }, [action, changesOne, changesTwo, startingCost, vehicle])
    

    function updateValue(val, id){
        setStartingCost(parseInt(val))
        const data = {
            'vehicle_type': id,
            'starting_cost': val
        }
        props.performChange(data)
    }
    return (
        <div className='flex justify-between items-center'>
            <div>
                <p>{vehicle?.name}</p>
            </div>
            <div>
                <label className="form-control w-full">
                    <input 
                        value={startingCost}
                        onChange={(e) => updateValue(parseInt(e.target.value), vehicle?.id)}
                        type="number" 
                        min={1}
                        placeholder="Enter Amount" 
                        className="input input-bordered w-full input-sm" />
                </label>
            </div>
        </div>
    )
}

export default StartingCostInput