import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import ServicesSummary from '../../components/services/ServicesSummary'
import servicesService from '../../services/ServicesService'
import ServiceTypeTable from '../../components/services/ServiceTypeTable'
import { useSearchParams } from 'react-router-dom'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'

function ManageServicesPage() {
    const [statistics, setStatistics] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    const [vehicleCategories, setVehicleCategories] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [charges, setCharges] = useState()

    useEffect(() => {
        getStatistics()
        getCharges()
        getCategories()
    }, [])
    useEffect(() => {}, [statistics])
    useEffect(() => {}, [vehicleCategories])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getCharges(params)
        }
    }, [searchParams])
    
    const getStatistics = () => {
        servicesService.getGeneralStatistics().then(
            res => {
                setStatistics(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }
    const getCharges =  (params) => {
        setError()
        setLoading(true)
        servicesService.getServiceCharges(params ? `?${params}` : '').then(
            res => {
                setCharges(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setLoading(false)
                setError(err)
            }
        )
    }
    const getCategories = () => {
        servicesService.getVehicleCategories().then(
            res => {
                setVehicleCategories(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }
    
    function updateUI(){
        getCharges()
        getStatistics()
        getCategories()
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Manage Services - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (
                            <FetchError error={error}/>
                        ):(
                            <div>
                                <div className="mb-3">
                                    <ServicesSummary
                                        statistics={statistics}
                                    />
                                </div>
                                <div className="mb-3">
                                    <ServiceTypeTable
                                        updateUI={updateUI}
                                        charges={charges}
                                        vehicleCategories={vehicleCategories}
                                        changePageParam={changePageParam}
                                        changeLimitParam={changeLimitParam}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default ManageServicesPage