import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import BuyersSummary from '../../components/buyers/BuyersSummary'
import BuyersTable from '../../components/buyers/BuyersTable'
import { useSearchParams } from 'react-router-dom'
import buyersServices from '../../services/BuyersServices'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'

function BuyersPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [statistics, setStatistics] = useState()
    const [buyers, setBuyers] = useState()

    const getStatistics = () => {
        buyersServices.getBuyerStatistics().then(
            res => {
                setStatistics(res.data)
            }, 
            err => {
                console.log(err);
            }
        )
    }

    useEffect(() => {
        getStatistics()
        getBuyers()
    }, [])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getBuyers(params)
        }
    }, [searchParams])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [statistics])
    useEffect(() => {}, [buyers])

    const getBuyers = (params) => {
        setError()
        setLoading(true)
        buyersServices.getBuyers(params ? `?${params}` : '').then(
            res => {
                console.log(res.data);
                setBuyers(res.data)
                setLoading(false)
            }, 
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Buyers - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <div className='mb-5'>
                                    <BuyersSummary statistics={statistics}/>
                                </div>
                                <div>
                                    <BuyersTable
                                        buyers={buyers}
                                        getBuyers={getBuyers}
                                        changePageParam={changePageParam}
                                        changeLimitParam={changeLimitParam}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                
            </div>
        </HelmetProvider>
    )
}

export default BuyersPage