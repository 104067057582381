import React, { useEffect, useState } from 'react'
import VehicleInput from './VehicleInput';
import toast from 'react-hot-toast';
import servicesService from '../../services/ServicesService';
import utils from '../../utils';

function VehicleStartingCostModal(props) {
    const activeCategory = props.activeCategory
    const startingCosts = props.startingCosts
    const [filteredStartingCosts, setFilteredStartingCosts] = useState()
    const [changeValue, setChangevalue] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {}, [changeValue])
    useEffect(() => {
            const vehicleTypes = activeCategory?.vehicle_types
            if (vehicleTypes) {
                const filteredItems = startingCosts.filter((item) =>
                    vehicleTypes.some((filterId) => filterId.id === item?.vehicle_type?.id)
                );
                setFilteredStartingCosts(filteredItems)
            }
    }, [activeCategory, startingCosts])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [filteredStartingCosts])

    function performChange(data){
        // check if data in change values
        const existingObject = changeValue.find(
            (object) => object.id === data.id 
        );

        // if existing update else add obj to change arr
        if (existingObject) {
            setChangevalue([
                ...changeValue.filter((object) => object !== existingObject),
                { ...existingObject, ...data },
            ])
        } else {
            setChangevalue([...changeValue, data])
        }
    }

    function submitForms(){
        setLoading(true)
        const updateToast = toast.loading("Updating Vehicle Starting Costs")
        servicesService.updateStatingCosts(changeValue).then(
            res=> {
                toast.success("Starting Cost updated successfully", {
                    id:updateToast
                })
                setChangevalue([])
                setLoading(false)
                props.updateStartingCosts()
            },
            err => {
                console.log(err);
                toast.error(utils.toasterError, {
                    id:updateToast
                })
                setLoading(false)
            }
        )
    }
    return (
        <div>
            <label htmlFor="vehicle-starting-cost-modal" id='vehicle-starting-cost-open-btn' className="hidden">Edit Vehicle Type Cost</label>
            <input type="checkbox" id="vehicle-starting-cost-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box">
                    <label htmlFor="vehicle-starting-cost-modal" id='vehicle-starting-cost-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <div className="py-7 mt-3">
                        <div className="mb-3">
                            {activeCategory && (
                                <div className='space-y-2'>
                                    {filteredStartingCosts?.map((vehicle, idx) => (
                                        <VehicleInput
                                            key={idx}
                                            vehicle={vehicle}
                                            performChange={performChange}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        {changeValue?.length ? (
                            <div className='modal-action flex justify-end gap-3'>
                                <button
                                    onClick={() => submitForms()}
                                    className='btn text-white btn-accent'>Save</button>
                            </div>
                        ):null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VehicleStartingCostModal