import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import SubscriptionSummary from '../../components/subscriptions/SubscriptionSummary'
import SubscriptionsSections from '../../components/subscriptions/SubscriptionsSections'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import subscriptionServices from '../../services/SubscriptionService'

function SubscriptionPage() {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [statistics, setStatistics] = useState()
    const [billingPeriods, setBillingPeriods] = useState()
    const [subcriptionPlans, setSubscriptionPlans] = useState()

    useEffect(() => {}, [error])
    useEffect(() => {}, [statistics])
    useEffect(() => {}, [billingPeriods])
    useEffect(() => {}, [subcriptionPlans])
    useEffect(() => {
        getStatistics()
        getBillingPeriods()
        getSubscriptionPlans()
    }, [])

    const getStatistics = () => {
        subscriptionServices.getSubscriptionStatistics().then(
            res => {
                setStatistics(res.data)
            },
            err => {console.log(err);}
        )
    }
    const getBillingPeriods = () => {
        subscriptionServices.getBillingPeriods().then(
            res => {
                setBillingPeriods(res.data)
            },
            err => {console.log(err);}
        )
    }
    const getSubscriptionPlans = () => {
        setLoading(true)
        setError()
        subscriptionServices.getSubscriptionPlans().then(
            res => {
                setSubscriptionPlans(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function updateUI(){
        setLoading(true)
        getStatistics()
        getBillingPeriods()
        getSubscriptionPlans()
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Subscriptions - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <div className="mb-3">
                                    <SubscriptionSummary
                                        statistics={statistics}
                                    />
                                </div>
                                <div className="mb-3">
                                    <SubscriptionsSections
                                        billingPeriods={billingPeriods}
                                        subcriptionPlans={subcriptionPlans}
                                        updateUI={updateUI}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default SubscriptionPage