import React from 'react'
import { TbNetworkOff } from 'react-icons/tb'

function FetchError(props) {
    const error = props.error
    return (
        <div className='p-3'>
            <div className='alert alert-warning rounded-lg mt-4'>
                <TbNetworkOff className='h-7 w-7'/>
                <p>
                    {error?.message && (
                        <span>{error?.message}</span>
                    )}
                </p>
            </div>
        </div>
    )
}

export default FetchError