import React, { useEffect, useState } from 'react'
import Empty from '../feedback/Empty'
import { CiEdit } from "react-icons/ci";
import SubscriptionPricingModal from './SubscriptionPricingModal';
import AddSubscriptionPricingModal from './AddSubscriptionPricingModal';
import { IoAddCircleOutline } from "react-icons/io5";

function PricingAndBilling(props) {
    const [activePeriod, setActivePeriod] = useState()
    const planBillingPeriods = props.planBillingPeriods
    const subscriptionplan= props.subscriptionplan

    useEffect(() => {}, [planBillingPeriods])
    useEffect(() => {}, [subscriptionplan])
    useEffect(() => {}, [activePeriod])

    function openModal(period){
        setActivePeriod(period)
        const openBtn = document.getElementById("subscription-pricing-open-btn")
        openBtn.click()
    }
    function openAddPricingModal(){
        const openBtn = document.getElementById("pricing-add-open-btn")
        openBtn.click()
    }
    return (
        <div className='mb-5'>
            <div className="rounded-md border pb-7">
                <SubscriptionPricingModal
                    subscriptionplan={subscriptionplan}
                    activePeriod={activePeriod}
                    updateBillingPeriods={props.updateBillingPeriods}
                />
                <AddSubscriptionPricingModal
                    subscriptionplan={subscriptionplan}
                    updateBillingPeriods={props.updateBillingPeriods}
                />
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Pricing and Billing</p>    
                    <div>
                        <button 
                            onClick={() => openAddPricingModal()}
                            className='btn btn-accent text-white'><IoAddCircleOutline className='w-5 h-5'/> Add Billing Period</button>
                    </div>
                </div>
                <div className="p-4">
                    <div className="overflow-x-auto">
                        <table className="table table-zebra table-xs">
                            <thead>
                                <tr className='text-sm'>
                                    <td>Billing Period</td>
                                    <td>Price</td>
                                    <td>Subscribers</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {planBillingPeriods?.map((plan, idx) => (
                                    <tr key={idx}>
                                        <td>{plan?.period_name?.name}</td>
                                        <td>{plan?.amount}</td>
                                        <td>{plan?.active_subscribers}</td>
                                        <td>
                                            <button
                                                onClick={() => openModal(plan)}
                                                className='btn btn-ghost text-accent'
                                            ><CiEdit/> Edit</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {planBillingPeriods?.length < 1 && (
                        <Empty objectName={"Plan Billing Periods"}/>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PricingAndBilling