import React, { useEffect, useState } from 'react'
import utils from '../../utils'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import { TfiLayoutGrid3 } from "react-icons/tfi";
import servicesService from '../../services/ServicesService';
import FetchError from '../../components/feedback/FetchError';
import Loading from '../../components/feedback/Loading';
import { GrUserWorker } from "react-icons/gr"
import { IoLocationOutline } from "react-icons/io5";
import { IoStarOutline } from "react-icons/io5";
import ExpertSideBar from '../../components/experts/ExpertSideBar';
import ExpertGigs from '../../components/experts/ExpertGigs';
import ExpertProfile from '../../components/experts/ExpertProfile';
import ExpertEmployees from '../../components/experts/ExpertEmployees';
import ExpertReferral from '../../components/experts/ExpertReferral';
import ExpertReviews from '../../components/experts/ExpertReviews';
import expertsServices from '../../services/ExpertsServices';
import toast from 'react-hot-toast';

function ExpertViewPage() {
    const [expert, setExpert] = useState(null)
    const [loading, setLoading] = useState(true)
    const [currentTab, setCurrentTab] = useState(0)
    const [error, setError] = useState(null)
    const idParam = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (idParam?.id) {
            getExpert(idParam?.id)
        }
    }, [idParam?.id])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [expert])
    useEffect(() => {}, [currentTab])

    const getExpert = (id) => {
        setError()
        setLoading(true)
        servicesService.getUser(id).then(
            res => {
                console.log(res.data);
                setExpert(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function suspendExpert(val){
        const msg = val === 0 ? "Removing Expert Suspension":"Suspending Expert"
        const suspensionToast = toast.loading(msg)
        const data = {
            "is_suspended": val
        }
        expertsServices.updateExpert(expert?.id, data).then(
            res => {
                const msgs = val === 0 ? "Suspension Removed Successfully":"Suspended Successfully"
                toast.success(msgs, {
                    id:suspensionToast
                })
                setExpert(res.data)
            },
            err => {
                toast.error(utils.toasterError, {
                    id:suspensionToast
                })
            }
        )
    }
    function deleteExpert(id){
        const deleteToast = toast.loading("Deleting Expert")
        setLoading(true)
        expertsServices.deleteExpert(id).then(
            res => {
                toast.success("Expert Deleted Successfully", {
                    id:deleteToast
                })
                navigate('/experts')
            },
            err => {
                toast.error(utils.toasterError, {
                    id:deleteToast
                })
                setLoading(false)
            }
        )   
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Expert - {utils.siteName}</title>
            </Helmet>
            <div className='p-2'>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>): null}
                        {expert ? (
                            <div>
                                <div className='flex justify-end mb-3'>
                                    <div>
                                        <div className="dropdown">
                                            <label tabIndex="0" className="flex items-center gap-3 btn btn-ghost text-accent">
                                                <TfiLayoutGrid3 className='h-6 w-6'/>
                                                <p className='font-bold'>Actions</p>
                                            </label>
                                            <ul 
                                                tabIndex="0"
                                                className="menu menu-vertical dropdown-content mt-3 shadow bg-base-200 rounded-box text-start">
                                                <li className='hover:cursor-pointer p-2'>
                                                    {expert?.is_suspended ? (
                                                        <span
                                                            onClick={() => suspendExpert(0)}>
                                                            Remove Suspension
                                                        </span>
                                                    ):(
                                                        <span
                                                            onClick={() => suspendExpert(1)}>
                                                            Suspend Account
                                                        </span>
                                                    )}
                                                    
                                                </li>
                                                <li className='hover:cursor-pointer p-2 text-red-600'>
                                                    <span
                                                        onClick={() => deleteExpert(expert?.id)}>
                                                        Delete Account
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {expert && (
                                    <div className='flex justify-center mb-6'>
                                        <div className='avatar'>
                                            {utils.checkImage(expert) ? (
                                                <div className="w-20 rounded-full">
                                                    <img 
                                                        src={utils.baseUrl + expert?.image} 
                                                        alt={expert?.email} />
                                                </div>
                                            ):(
                                                <div>
                                                    <div className="avatar placeholder">
                                                        <div className="bg-neutral text-neutral-content rounded-full w-20">
                                                            <span className="text-xl">{utils.makeProfileLetters(expert)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="flex justify-center gap-3 items-center mb-6">
                                    <div className='text-lg font-bold'>
                                        {expert?.full_name ? (
                                            <p>{expert?.full_name}</p>
                                        ):(
                                            <p>{expert?.email}</p>
                                        )}
                                    </div>
                                    <div>
                                        <GrUserWorker className='h-7 w-5 text-accent font-bold'/>
                                    </div>
                                </div>
                                <div className="flex justify-center gap-3 items-center mb-6">
                                    <div className="flex gap-2 items-center">
                                        <IoLocationOutline className='h-5 w-5 text-accent'/>
                                        <p className='text-gray-500'>
                                            {expert?.location ? (
                                                <span>{expert?.location}</span>
                                            ):(
                                                <span>{expert?.location}</span>
                                            )}
                                            <span className='ml-1'>{expert?.country}</span>
                                        </p>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <IoStarOutline className='h-5 w-5 text-accent'/>
                                        <p className='text-gray-500'><span className='mr-1'>{expert?.ratings}</span> <span>Ratings</span></p>
                                    </div>
                                </div>
                                <div className='md:flex gap-3'>
                                    <div className="md:w-4/12 lg:w-3/12 rounded-md">
                                        <ExpertSideBar
                                            currentTab={currentTab}
                                            setCurrentTab={setCurrentTab}
                                        />
                                    </div>
                                    <div className="md:w-8/12 lg:w-9/12">
                                        {currentTab === 0 && (
                                            <ExpertGigs
                                                expert={expert}/>
                                        )}
                                        {currentTab === 1 && (
                                            <ExpertProfile
                                                user={expert}/>
                                        )}
                                        {currentTab === 2 && (
                                            <ExpertEmployees
                                                expert={expert}/>
                                        )}
                                        {currentTab === 3 && (
                                            <ExpertReferral
                                                user={expert}/>
                                        )}
                                        {currentTab === 4 && (
                                            <ExpertReviews
                                                expert={expert}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ):null}
                    </div>
                )}
                
            </div>
        </HelmetProvider>
    )
}

export default ExpertViewPage