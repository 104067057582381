import React from 'react'

function FInanceStatistics(props) {
    return (
        <div className="rounded-md border pb-5 mb-6">
            <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                <p className="font-bold text-lg">Account Balance</p>
            </div>

        </div>
    )
}

export default FInanceStatistics