import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import expertsServices from '../services/ExpertsServices'
import Loading from '../components/feedback/Loading'
import FetchError from '../components/feedback/FetchError'
import DashboardSummary from '../components/dashboard/DashboardSummary'

function DashboardPage() {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [statistics, setStatistics] = useState()

    const getStatistics = () => {
        setError()
        setLoading(true)
        expertsServices.getDashboardStatistics().then(
            res => {
                setStatistics(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }

    useEffect(() => {
        getStatistics()
    }, [])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [statistics])

    return (
        <HelmetProvider>
            <Helmet>
                <title>Dashboard - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <div>
                                    <DashboardSummary
                                        statistics={statistics}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default DashboardPage