/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { ReactComponent as Sharp } from '../../assets/ic_sharp-auto-mode.svg'
import PromotionSteps from './PromotionSteps'
import gift from '../../assets/amico.png'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from 'react-hot-toast';
import Loading from '../feedback/Loading';
import promotionsServices from '../../services/PromotionsServices';


function PromotionModal(props) {
    const [stage, setStage] = useState(0)
    const [title, setTitle] = useState('')
    const [count, setCount] = useState(9)
    const [code, setCode] = useState('')
    const [discount, setDiscount] = useState(9)
    const [activeTab, setActiveTab] = useState(0)
    let tabs = [
        "Schedule Launch",
        "Launch Now"
    ]
    const [scheduleDate, setScheduleDate] = useState()
    const [expiryDate, setExpiryDate] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    useEffect(() => {}, [stage])
    useEffect(() => {
        if (activeTab && (activeTab === 1)) {
            const time = new Date()
            setScheduleDate(time)
        }
    }, [activeTab])
    useEffect(() => {}, [title])
    useEffect(() => {}, [count])
    useEffect(() => {}, [discount])
    useEffect(() => {}, [error])

    function formOneSubmit(e){
        e.preventDefault()
        setStage(1)
    }
    function autoGenerateCode(){
        if (!title) {
            toast.error("Please fill in the Promotion Title first")
            return
        }
        if (title.length < 5) {
            toast.error("Promotion Title must be longer than 5 characters")
            return
        }

        // generate code from first 6 characters then generate 3 random numbers
        let codeStr = title.slice(0,6).toUpperCase() + '-' + String(
            Math.floor(Math.random()*(999-100+1)+100)
        )
        setCode(codeStr)
    }
    function formTwoSubmit(e){
        e.preventDefault()
        if (!scheduleDate) {
            toast.error("Please fill in the Schedule Date")
            return
        }
        if (!expiryDate) {
            toast.error("Please fill in the Expiry Date")
            return
        }
        const data = {
            "title": title,
            "usage_count": count,
            "code": code,
            "discount": discount,
            "launch_date": scheduleDate,
            "expiry_date": expiryDate
        }
        setLoading(true)
        setError()
        const closeBtn = document.getElementById("promo-create-modal-close-btn")
        const feedback = toast.loading("Creating Promotion")
        promotionsServices.createPromotion(data).then(
            res => {
                setLoading(false)
                toast.success("Promotion Added successfully", {
                    id: feedback
                })
                closeBtn.click()
                props.updateUI()
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
                toast.error("Something went wrong", {
                    id: feedback
                })
            }
        )
    }
    function changeActiveTab(val){
        setActiveTab(val)
    }
    function closeModal(){
        const closeBtn = document.getElementById("promo-create-modal-close-btn")
        closeBtn.click()
    }
    return (
        <div>
            <label htmlFor="promo-create-modal" id='promo-create-open-btn' className="hidden">Create Promotion</label>
            <input type="checkbox" id="promo-create-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-12/12 max-w-5xl">
                    <label htmlFor="promo-create-modal" id='promo-create-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-6'>Create a Promotion</h2>
                    <div className='px-20'>
                        <PromotionSteps stage={stage}/>
                    </div>
                    {stage === 0 && (
                        <div className=''>                                                        
                            <form onSubmit={formOneSubmit}>
                                <div className="form-control mb-3">
                                    <div className="label">
                                        <span className='label-text'>Promotion Title *</span>
                                    </div>
                                    <input 
                                        type="text" 
                                        value={title} 
                                        onChange={(e) => setTitle(e.target.value)} 
                                        required 
                                        className='input input-bordered' 
                                        placeholder='Enter title' />
                                </div>
                                <div className="form-control mb-3">
                                    <div className="label">
                                        <span className='label-text'>Usable Times *</span>
                                    </div>
                                    <input 
                                        value={count}
                                        onChange={(e) => setCount(e.target.value)}
                                        type="number" 
                                        required 
                                        min={1}
                                        className='input input-bordered' 
                                        placeholder='Enter number' />
                                </div>
                                <div className="form-control mb-3">
                                    <div className="label">
                                        <span className='label-text'>(%) Discount Percent *</span>
                                    </div>
                                    <input 
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                        type="number" 
                                        required 
                                        min={1}
                                        className='input input-bordered' 
                                        placeholder='Enter number' />
                                </div>
                                <div className="form-control mb-12">
                                    <div className="label">
                                        <span className='label-text'>Promotion Code *</span>
                                    </div>
                                    <div className="join">
                                        <input 
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                            type="text" 
                                            required 
                                            className="input input-bordered join-item w-full" 
                                            placeholder='Enter or autogenerate'/>
                                        <div 
                                            onClick={() => autoGenerateCode()}
                                            className="btn join-item btn-accent btn-outline text-white">
                                            <Sharp/>
                                            Auto-generate
                                        </div>
                                    </div>                                  
                                </div>
                                <div className='modal-action flex justify-end gap-3'>
                                    <div
                                        onClick={() => closeModal()}
                                        className='btn btn-ghost text-accent'>Cancel</div>
                                    <button type='submit' className='btn text-white btn-accent'>Save</button>
                                </div>
                            </form>
                        </div>
                    )}
                    {stage === 1 && (
                        <div>
                            <div className="flex justify-center mb-6">
                                <img src={gift} alt='icon'/>
                            </div>
                            <p className="text-center text-gray-500 mb-6 md:mx-32">Your promotion is ready. Choose whether you want to launch it now for customers to start benefiting or schedule it for later.</p>
                            <div className="flex justify-center mb-6">
                                <div className="tabs tabs-boxed">
                                    {tabs.map((tab, idx) => (
                                        <a 
                                            key={idx}
                                            className={activeTab === idx ? 'tab tab-active' : 'tab'}
                                            onClick={() => changeActiveTab(idx)}
                                        >{tab}</a>
                                    ))}
                                </div>
                            </div>
                            <div className='mb-6 '>
                                <form onSubmit={formTwoSubmit}>
                                    <div className="flex gap-3 justify-center">
                                        <div className="form-control">
                                            <div className="label">
                                                <span className='label-text'>Schedule Date *</span>
                                            </div>
                                            <DatePicker
                                                selected={scheduleDate}
                                                onChange={(dateTime) => setScheduleDate(dateTime)}
                                                className='input input-bordered w-full'
                                                showTimeSelect
                                                dateFormat="MMMM d yyyy h:mm aa"
                                            />
                                        </div>
                                        <div className="form-control">
                                            <div className="label">
                                                <span className='label-text'>Expiry Date *</span>
                                            </div>
                                            <DatePicker
                                                selected={expiryDate}
                                                onChange={(dateTime) => setExpiryDate(dateTime)}
                                                className='input input-bordered w-full'
                                                showTimeSelect
                                                dateFormat="MMMM d yyyy h:mm aa"
                                            />
                                        </div>
                                    </div>                                    
                                    <div className='modal-action flex justify-end gap-3'>
                                        {loading ? (
                                            <Loading/>
                                        ):(
                                            <>
                                                <div 
                                                    onClick={() => setStage(0)}
                                                    className='btn btn-ghost text-accent'>Cancel</div>
                                                <button type='submit' className='btn text-white btn-accent'>Complete</button>
                                            </>
                                        )}                                        
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PromotionModal