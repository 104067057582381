import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import ServiceSummary from '../../components/services/ServiceSummary'
import ServicesTable from '../../components/services/ServicesTable'
import servicesService from '../../services/ServicesService'
import { useSearchParams } from 'react-router-dom'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'

function ScheduledServicesPage() {
    const [services, setServices] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [statistics, setStatistics] = useState()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        getStatistics()
        setSearchParams((params) => {
            params.set("scheduled", true)
            return params
        })
    }, [setSearchParams])
    useEffect(() => {}, [services])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [statistics])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            setLoading(true)
            setError()
            servicesService.getServicesList(params ? `?${params}` : '').then(
                res => {
                    setServices(res.data)
                    setLoading(false)
                },
                err => {
                    console.log(err);
                    setError(err)
                    setLoading(false)
                }
            )
        }
    }, [searchParams])

    const getStatistics = () => {
        servicesService.getGeneralStatistics().then(
            res => {
                setStatistics(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }

    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Scheduled Services - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <div className="mb-3">
                                    <ServiceSummary
                                        statistics={statistics}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <ServicesTable
                                        changeLimitParam={changeLimitParam}
                                        changePageParam={changePageParam}
                                        services={services}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default ScheduledServicesPage