import React, { useEffect } from 'react'
import utils from '../../utils'
import { GrUserWorker } from "react-icons/gr"
import { AiOutlineUser } from "react-icons/ai"
import { GiTowTruck } from "react-icons/gi";
import { IoCarSportOutline } from "react-icons/io5";

function ExpertsSumary(props) {
    const statistics = props.statistics
    useEffect(() => {}, [statistics])
    return (
        <div className='p-3'>
            {statistics && (
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3'>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.registered_experts)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Registered Experts</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <GrUserWorker className='w-10 h-10' style={{color: "#42B883"}}/>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.busy_experts)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Busy Experts</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <AiOutlineUser className='w-10 h-10' style={{color: "#42B883"}}/>
                        </figure>
                    </div>
                    <div className="flex items-center border p-4 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.active_experts)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Active Accounts</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <GiTowTruck className='w-10 h-10' style={{color: "#42B883"}}/>
                        </figure>
                    </div>
                    <div className="flex items-center border p-4 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.inactive_experts)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Inactive Experts</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <IoCarSportOutline className='w-10 h-10' style={{color: "#42B883"}}/>
                        </figure>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ExpertsSumary