import React, { useEffect } from 'react'
import { IoIosSearch } from "react-icons/io"
import { BiSort } from "react-icons/bi";
import Empty from '../feedback/Empty';
import utils from '../../utils';
import moment from 'moment'
import Pagination from '../nav/Pagination';

function ParticipatntsTable(props) {
    const participants = props.participants

    useEffect(() => {}, [participants])
    return (
        <div>
            <div className='mb-3 flex gap-6 items-center'>
                <div className='btn btn-outline'>
                    <IoIosSearch className='w-5 h-5'/>
                </div>
                <div className='flex items-center gap-3'>
                    <BiSort className='w-8 h-8' color='#42B883'/>
                    <select className="select select-ghost" defaultValue={0}>
                        <option value={0} disabled>Sort By</option>
                        <option value={'desc'}>Descending</option>
                        <option value={'asc'}>Ascending</option>
                    </select>
                </div>
            </div>
            <div>
                <div className="overflow-x-auto mb-6">
                    <table className="table table-zebra table-xs">
                        <thead>
                            <tr className='text-sm'>
                                <td>User</td>
                                <td>Account Type</td>
                                <td>Participation Date</td>
                            </tr>
                        </thead>
                        <tbody>
                            {participants?.results?.map((participant, idx) => (
                                <tr
                                    key={idx}>
                                    <td>
                                        {participant?.user && (
                                            <div className="flex items-center gap-3">
                                                <div className='avatar'>
                                                    {utils.checkImage(participant?.user) ? (
                                                        <div className=" w-11 rounded-full">
                                                            <img src={participant?.user?.image} alt={participant?.user?.email} />
                                                        </div>
                                                    ):(
                                                        <div>
                                                            <div className="avatar placeholder">
                                                                <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                    <span className="text-xl">{utils.makeProfileLetters(participant?.user)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='flex flex-col gap-2'>
                                                    <div>
                                                        {participant?.user?.full_name ? (
                                                            <p>{participant?.user?.full_name}</p>
                                                        ):(
                                                            <p>{participant?.user?.email}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {participant?.user?.is_expert ? 'Expert': ''}
                                        {participant?.user?.is_buyer ? 'Buyer': ''}
                                    </td>
                                    <td>
                                        {moment(participant?.creation_date).format("Do MMMM YYYY")}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {participants?.count < 1 ? (
                        <Empty objectName="Participants"/>
                    ):(
                        <div>
                            {participants && (
                                <Pagination
                                    count={participants?.count}
                                    next={participants?.next}
                                    previous={participants?.previous}
                                    changeLimitParam={props.changeLimitParam}
                                    changePageParam={props.changePageParam}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ParticipatntsTable