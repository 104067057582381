import React, { useEffect } from 'react'
import utils from '../../utils'

function Uploads(props) {
    const user = props.expert
    useEffect(() => {}, [user])
    return (
        <div id="uploads" className='p-3'>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Uploads</p>
                </div> 
                <>
                    {user?.user_documents ? (
                        <div className='p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3'>
                            <div className=''>
                                <div 
                                    className='card border rounded-md'>
                                    {user?.image ? (
                                        <figure>
                                            <img
                                                className='w-full rounded-md h-56' 
                                                src={utils.baseUrl + user?.image} 
                                                alt="profile" />
                                        </figure>
                                    ):(
                                        <div className="avatar placeholder">
                                            <div className="bg-neutral text-neutral-content rounded-md w-full h-56">
                                                <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="p-3">
                                        <p>Profile Picture</p>
                                        <p className='text-gray-500'>
                                            {user?.image ? (
                                                <span>
                                                    {user?.image}
                                                </span>
                                            ):(
                                                <span>None Yet</span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div 
                                    className='card border rounded-md'>
                                    {user?.user_documents?.licence ? (
                                        <figure>
                                            <img
                                                className='w-full rounded-md h-56' 
                                                src={utils.baseUrl + user?.user_documents?.licence} 
                                                alt="profile" />
                                        </figure>
                                    ):(
                                        <div className="avatar placeholder">
                                            <div className="bg-neutral text-neutral-content rounded-md w-full h-56">
                                                <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="p-3">
                                        <p>Professional Licence</p>
                                        <p className='text-gray-500'>
                                            {user?.user_documents?.licence ? (
                                                <span>
                                                    {user?.user_documents?.licence}
                                                </span>
                                            ):(
                                                <span>None Yet</span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div 
                                    className='card border rounded-md'>
                                    {user?.user_documents?.insurance ? (
                                        <figure>
                                            <img
                                                className='w-full rounded-md h-56' 
                                                src={utils.baseUrl + user?.user_documents?.insurance} 
                                                alt="profile" />
                                        </figure>
                                    ):(
                                        <div className="avatar placeholder">
                                            <div className="bg-neutral text-neutral-content rounded-md w-full h-56">
                                                <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="p-3">
                                        <p>Insurance </p>
                                        <p className='text-gray-500'>
                                            {user?.user_documents?.insurance ? (
                                                <span>
                                                    {user?.user_documents?.insurance}
                                                </span>
                                            ):(
                                                <span>None Yet</span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ):(
                        <>
                            <p className='p-3'>User has not uploaded Any Documents yet!</p>
                        </>
                    )}  
                </>
            </div>
        </div>
    )
}

export default Uploads