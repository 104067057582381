import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { BiSort } from "react-icons/bi";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Empty from '../feedback/Empty';
import Pagination from '../nav/Pagination';
import utils from '../../utils';

const options = [
    { value: 'none', label: 'Order By' },
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
]

function ApplicationsTable(props) {
    const experts = props.experts
    const [selectedValue, setSelectedValue] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams()
    const [hasFiltered, setHasFiltered] = useState(false)
    const orderingParam = searchParams.get("ordering")
    const navigate = useNavigate()

    useEffect(() => {}, [hasFiltered])
    useEffect(() => {}, [selectedValue])
    useEffect(() => {}, [experts])
    useEffect(() => {
        if (orderingParam && (!hasFiltered)) {
            if (orderingParam === 'creation_time') {
                const defaultOption = options.find(option => option.value === 'asc')
                setSelectedValue(defaultOption)
            } else if (orderingParam === '-creation_time') {
                const defaultOption = options.find(option => option.value === 'desc')
                setSelectedValue(defaultOption)
            }
        }
    }, [orderingParam, hasFiltered])
    useEffect(() => {
        if (!orderingParam) {
            const defaultOption = options.find(option => option.value === 'none')
            setSelectedValue(defaultOption);
        }
    }, [orderingParam])
    useEffect(() => {
        if (selectedValue) {
            if (selectedValue?.value === 'asc') {
                setSearchParams((params) => {
                    params.set("ordering", 'creation_time')
                    return params
                })
            }else if (selectedValue?.value === 'desc') {
                setSearchParams((params) => {
                    params.set("ordering", '-creation_time')
                    return params
                })
            } else if (selectedValue?.value === 'none') {
                setSearchParams((params) => {
                    params.delete("ordering")
                    return params
                })
            }
        }
    }, [selectedValue, setSearchParams])

    const handleChange = (selectedOption) => {
        setHasFiltered(true)
        setSelectedValue(selectedOption);
    };

    function openApplication(id){
        navigate(`/applications/${id}`)
    }
    return (
        <div className='p-3'>
            <div className='border rounded-md'>
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Registration Applications</p>
                </div>
                <div className='mb-3 flex justify-between items-center gap-6'>
                    <div className='flex items-center'>
                        <BiSort className='w-8 h-8' color='#42B883'/>
                        <Select
                            className='select select-ghost'
                            value={selectedValue}
                            onChange={handleChange}
                            options={options}
                        />
                    </div>
                </div>
                <div>
                    <div className="overflow-x-auto mb-9 p-3">
                        <table className="table table-zebra table-xs mb-5">
                            <thead>
                                <tr className='te text-sm'>
                                    <td>Name</td>
                                    <td>Phonenumber</td>
                                    <td>Email</td>
                                    <td>Service</td>
                                    <td>Location</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {experts?.results?.map((expert, idx) => (
                                    <tr
                                        key={idx}>
                                        <td>
                                            {expert && (
                                                <div className="flex items-center gap-3">
                                                    <div className='avatar'>
                                                        {utils.checkImage(expert) ? (
                                                            <div className=" w-11 rounded-full">
                                                                <img src={expert?.image} alt={expert?.email} />
                                                            </div>
                                                        ):(
                                                            <div>
                                                                <div className="avatar placeholder">
                                                                    <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                        <span className="text-xl">{utils.makeProfileLetters(expert)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {expert?.full_name}
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            {expert?.phone_number ? (
                                                <span>{expert?.phone_number}</span>
                                            ):(
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>
                                            {expert?.email ? (
                                                <span>{expert?.email}</span>
                                            ):(
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>
                                            {expert?.expert_service_type?.name ? (
                                                <span>{expert?.expert_service_type?.name}</span>
                                            ):(
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>
                                            {expert?.location ? (
                                                <span>{expert?.location}</span>
                                            ):(
                                                <span>-</span>
                                            )}
                                        </td>
                                        <td>
                                            <div 
                                                onClick={() => openApplication(expert?.id)}
                                                className="flex flex-shrink btn btn-ghost text-accent items-center">
                                                <p>Review</p>
                                                <FaArrowRightLong className='h-4 w-4'/>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {experts?.count < 1 ? (
                            <Empty objectName={"Applications"}/>
                        ):(
                            <div>
                                {experts && (
                                    <Pagination
                                        count={experts?.count}
                                        next={experts?.next}
                                        previous={experts?.previous}
                                        changeLimitParam={props.changeLimitParam}
                                        changePageParam={props.changePageParam}
                                    />
                                )}
                            </div>
                        )}
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default ApplicationsTable