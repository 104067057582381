import React, { useEffect } from 'react'
import moment from 'moment'

function PromotionDetails(props) {
    const promotion = props.promotion
    useEffect(() => {}, [promotion])
    return (
        <div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Applicable Groups</p>
                </div>
                <div className='p-2 space-y-3'>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Title</p>
                        <p>{promotion?.title}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Created On</p>
                        <p className='flex gap-1'>
                            <span>{moment(promotion?.creation_time).format("Do MMMM YYYY")}</span>
                            <span>.</span>
                            <span>{moment(promotion?.creation_time).format("HH:MM:SS")}</span>
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Participants</p>
                        <p>{promotion?.participants}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Sharable Times</p>
                        <p>{promotion?.usage_count}</p>
                    </div>
                </div>
            </div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Promotion Settings</p>
                </div>
                <div className='p-2 space-y-3'>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Promotion Title</p>
                        <p>{promotion?.title}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Launch Date</p>
                        <p>
                            {promotion?.launch_date ? (
                                <span>{moment(promotion?.launch_date).format("Do MMMM YYYY")}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Expiry Date</p>
                        <p>
                            {promotion?.expiry_date ? (
                                <span>{moment(promotion?.expiry_date).format("Do MMMM YYYY")}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Promotion Code</p>
                        <p>{promotion?.code}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Benefits</p>
                        <p>{promotion?.discount} % off every service offered</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionDetails