import React, { useEffect, useState } from 'react'
import moment from 'moment'
import CommissionDetails from '../serviceCharges/CommissionDetails'
import servicesService from '../../services/ServicesService'

function ServiceTypeOverview(props) {
    const [commision, setCommission] = useState()
    const charge = props.charge

    useEffect(() => {
        if (charge) {
            getCommission(charge?.service_type?.id)
        }
    }, [charge])
    useEffect(() => {}, [commision])

    const getCommission = (id) => {
        servicesService.getCommision(`?service_type__id=${id}`).then(
            res => {
                setCommission(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }
    return (
        <div>
            <CommissionDetails
                service_type={charge?.service_type}
                commision={commision}
                setCommission={setCommission}
            />
            <div className='mb-5'>
                <div className="rounded-md border pb-7">
                    <div className="py-5 border-b mb-3 p-4">
                        <p className="font-bold text-lg">Service Details</p>
                    </div>
                    <div className="p-4 space-y-2">
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Service Name</p>
                            <p>{charge?.service_type?.name}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Number of Times Offered</p>
                            <p>
                                {charge?.times_used}
                            </p>
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Added On</p>
                            <p>
                                {moment(charge?.creation_time).format("MMMM Do YYYY")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mb-5'>
                <div className="rounded-md border pb-7">
                    <div className="py-5 border-b mb-3 p-4">
                        <p className="font-bold text-lg">Service Pricing</p>
                    </div>
                    <div className="p-4 space-y-2">
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Base Rate</p>
                            <p>{charge?.base_rate}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Price per Km</p>
                            <p>
                                {charge?.price_per_km}
                            </p>
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Stop Cost</p>
                            <p>
                                {charge?.stop_cost}
                            </p>
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Transaction Fee</p>
                            <p>
                                {charge?.transaction_fee}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceTypeOverview