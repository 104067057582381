import React, { useEffect } from 'react'

function ReassignExpertModal(props) {
    const service = props.service
    useEffect(() => {}, [service])
    return (
        <div>
            <label htmlFor="reassign-expert-modal" id='reassign-expert-open-btn' className="hidden">{service?.responder ? 'Reassign':'Assign'} Expert</label>
            <input type="checkbox" id="reassign-expert-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <label htmlFor="reassign-expert-modal" id='reassign-expert-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-4'>{service?.responder ? 'Reassign':'Assign'} Expert</h2>
                    <div className="p-7 mt-9">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReassignExpertModal