import React, { useEffect, useState } from 'react'
import StartingCostInput from './StartingCostInput'

function StartingCostCreationModal(props) {
    const activeCategory= props.activeCategory
    const [changesOne, setChangesOne] = useState([])
    const [changesTwo, setChangesTwo] = useState([])
    const [combinedCosts, setCombinedCosts] = useState([])
    const action = props.action
    const setStartingCosts = props.setStartingCosts

    useEffect(() => {}, [activeCategory])
    useEffect(() => {}, [action])
    useEffect(() => {}, [changesOne])
    useEffect(() => {}, [changesTwo])
    useEffect(() => {
        if (changesOne?.length || changesTwo?.length) {
            setCombinedCosts([...changesOne, ...changesTwo])
        }
    }, [changesTwo, changesOne])
    useEffect(() => {
        if (combinedCosts?.length) {
            setStartingCosts([...combinedCosts])

        }
    }, [combinedCosts, setStartingCosts])

    function performChange(data){
        if (action === 1) {
            const existingObject = changesOne.find(
                (object) => object.vehicle_type === data.vehicle_type
            );
    
            if (existingObject) {
                setChangesOne([
                    ...changesOne.filter((object) => object !== existingObject),
                    { ...existingObject, ...data },
                ])
            } else {
                setChangesOne([...changesOne, data])
            }
        }
        if (action === 2) {
            const existingObject = changesTwo.find(
                (object) => object.vehicle_type === data.vehicle_type
            );
    
            if (existingObject) {
                setChangesTwo([
                    ...changesTwo.filter((object) => object !== existingObject),
                    { ...existingObject, ...data },
                ])
            } else {
                setChangesTwo([...changesTwo, data])
            }
        }
    }
    return (
        <div>
            <label htmlFor="vehicle-starting-cost-create-modal" id='vehicle-starting-cost-create-open-btn' className="hidden">Edit Vehicle Type Cost</label>
            <input type="checkbox" id="vehicle-starting-cost-create-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box">
                    <label htmlFor="vehicle-starting-cost-create-modal" id='vehicle-starting-cost-create-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <div className="py-7 mt-3">
                        <div className="mb-3">
                            {activeCategory && (
                                <div className='space-y-2'>
                                    {activeCategory?.vehicle_types?.map((vehicle, idx) => (
                                        <StartingCostInput
                                            activeCategory={activeCategory}
                                            key={idx}
                                            vehicle={vehicle}
                                            performChange={performChange}
                                            changesOne={changesOne}
                                            changesTwo={changesTwo}
                                            action={action}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StartingCostCreationModal