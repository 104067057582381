import React, { useEffect, useState } from 'react'
import { BiSolidEditAlt } from "react-icons/bi";
import CommissionModal from './CommissionModal';

function CommissionDetails(props) {
    const service_type = props.service_type
    const commision = props.commision
    const [action, setAction] = useState(0)

    useEffect(() => {}, [action])
    useEffect(() => {}, [service_type])
    useEffect(() => {}, [commision])

    function openModal(val){
        setAction(val)
        const openBtn = document.getElementById("commission-view-modal-open-btn")
        openBtn.click()
    }
    return (
        <div>
            <div className='mb-5'>
                <CommissionModal
                    action={action}
                    service_type={service_type}
                    commision={commision}
                    setCommission={props.setCommission}
                />
                <div className="rounded-md border pb-7 ">
                    <div className="flex justify-between border-b items-center p-4">
                        <p className="font-bold text-lg">Commission Pricing</p>
                        <div>
                            <button 
                                onClick={() => openModal(1)}
                                className='btn btn-ghost'
                                style={{color: "#42B883"}}><BiSolidEditAlt className=''/> Edit</button>
                        </div>
                    </div>
                    <div className="p-4 space-y-2">
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Percentage of Commission of Service Fee</p>
                            <span>{commision ? (
                                <span>{commision[0]?.rate}</span>
                            ):(
                                <span>None</span>
                            )}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommissionDetails