import React from 'react'
import {FaCreativeCommonsZero} from 'react-icons/fa'

function Empty({objectName}) {
    return (
        <div className='alert border-info rounded-md mt-3 px-4 bg-base-200'>
            <FaCreativeCommonsZero className='h-7 w-7 text-info'/>
            <p>No {objectName} found yet.</p>
        </div>
    )
}

export default Empty