import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import { FaCat } from "react-icons/fa6"

function PageNotFound() {
    return (
        <HelmetProvider>
            <Helmet>Page Not Found - {utils.siteName}</Helmet>
            <div className='p-3 py-9'>
                <div className='alert rounded-lg alert-info mb-3'>
                    <FaCat className='h-7 w-7'/>
                    <p>The page / resource you are looking for could not be found.</p>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default PageNotFound