import React, { useEffect, useState } from 'react'
import ActivePlanDetails from './ActivePlanDetails'
import PricingAndBilling from './PricingAndBilling'
import Benefits from './Benefits'
import utils from '../../utils'

function PlanDetails(props) {
    const [planBillingPeriods, setPlanBillingPeriod] = useState([])
    const subscriptionplan = props.subscriptionplan
    const currentTab = props.currentTab
    const billingPeriods = props.billingPeriods

    useEffect(() => {
        if (subscriptionplan && billingPeriods) {
            const planBillings = utils.getMonthlyPlan(subscriptionplan, billingPeriods)
            setPlanBillingPeriod(planBillings)
        }
    }, [subscriptionplan, billingPeriods])
    useEffect(() => {}, [currentTab])
    return (
        <div>
            <ActivePlanDetails
                subscriptionplan={subscriptionplan}
                planBillingPeriods={planBillingPeriods}
            />
            <PricingAndBilling
                subscriptionplan={subscriptionplan}
                planBillingPeriods={planBillingPeriods}
                updateBillingPeriods={props.updateBillingPeriods}
            />
            <Benefits
                updateSubscriptionPlan={props.updateSubscriptionPlan}
                subscriptionplan={subscriptionplan}
            />
        </div>
    )
}

export default PlanDetails