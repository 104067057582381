import React, { useEffect, useState } from 'react'
import { IoIosSearch } from "react-icons/io"
import { LuFilter } from "react-icons/lu";
import { useSearchParams } from 'react-router-dom';
import financeServices from '../../services/FInanceServices';
import Loading from '../feedback/Loading';
import FetchError from '../feedback/FetchError';
import Empty from '../feedback/Empty';
import Pagination from '../nav/Pagination';
import utils from '../../utils';
import moment from 'moment';

function Payouts(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [payments, setPayments] = useState()
    const currentTab = props.currentTab

    useEffect(() => {
        if (currentTab === 5) {
            setSearchParams((params) => {
                params.set("has_disbursed", true)
                return params
            })
        }
    }, [currentTab, setSearchParams])
    useEffect(() => {}, [loading])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getPayments(params)
        }
    }, [searchParams])
    useEffect(() => {}, [error])
    useEffect(() => {}, [payments])

    const getPayments = (params) => {
        setLoading(true)
        setError()
        financeServices.getRecentTransactions(params ? `?${params}` : '').then(
            res => {
                setPayments(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    return (
        <div>
            <div className='border rounded-md pb-6'>
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Payouts</p>
                </div>
                <div className='mb-3 flex gap-6 items-center px-3'>
                    <div className='btn btn-outline'>
                        <IoIosSearch className='w-5 h-5'/>
                    </div>
                    <div className='flex items-center gap-3'>
                        <LuFilter className='w-8 h-8' color='#42B883'/>
                        <select className="select select-ghost" defaultValue={0}>
                            <option value={0} disabled>Filter By</option>
                            <option value={1}>Active</option>
                            <option value={2}>Inactive</option>
                        </select>
                    </div>
                </div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div className="p-3">
                                <div className="overflow-x-auto">
                                    <table className="table table-zebra">
                                        <thead>
                                            <tr>
                                                <td>Expert</td>
                                                <td>Paid Amount</td>
                                                <td>Date</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments?.results?.map((payment, idx) => (
                                                <tr
                                                    key={idx}>
                                                    <td>
                                                        {payment?.service_request?.responder && (
                                                            <div className="flex items-center gap-3">
                                                                <div className='avatar'>
                                                                    {utils.checkImage(payment?.service_request?.responder) ? (
                                                                        <div className=" w-11 rounded-full">
                                                                            <img src={payment?.service_request?.responder?.image} alt={payment?.service_request?.responder?.email} />
                                                                        </div>
                                                                    ):(
                                                                        <div>
                                                                            <div className="avatar placeholder">
                                                                                <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                                    <span className="text-xl">{utils.makeProfileLetters(payment?.service_request?.responder)}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='flex flex-col gap-2'>
                                                                    <div>
                                                                        {payment?.service_request?.responder?.full_name ? (
                                                                            <p>{payment?.service_request?.responder?.full_name}</p>
                                                                        ):(
                                                                            <p>{payment?.service_request?.responder?.email}</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {utils.toCommas(payment?.paid_commission)}
                                                    </td>
                                                    <td>
                                                        {moment(payment?.payment_time).format("MMMM Do YYYY")}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {payments?.count < 1 ? (
                                        <div className="mb-7">
                                            <Empty objectName={"Payouts"}/>
                                        </div>                            
                                    ):(
                                        <div className=" p-3 mt-3">
                                            {payments && (
                                                <Pagination
                                                    count={payments?.count}
                                                    next={payments?.next}
                                                    previous={payments?.previous}
                                                    changeLimitParam={props.changeLimitParam}
                                                    changePageParam={props.changePageParam}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>                
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Payouts