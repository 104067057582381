import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import FinanceSummary from '../../components/finances/FinanceSummary'
import FInanceStatistics from '../../components/finances/FInanceStatistics'
import FinanceSidebar from '../../components/finances/FinanceSidebar'
import ServiceTransactions from '../../components/finances/ServiceTransactions'
import PendingDisbursements from '../../components/finances/PendingDisbursements'
import SubscriptionTransactions from '../../components/finances/SubscriptionTransactions'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import financeServices from '../../services/FInanceServices'
import PendingCommissions from '../../components/finances/PendingCommissions'
import ReceivedCommission from '../../components/finances/ReceivedCommission'
import Payouts from '../../components/finances/Payouts'
import { useSearchParams } from 'react-router-dom'

function FinancePage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentTab, setCurrentTab] = useState(0)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [summary, setSummary] = useState(null)
    const [statistics, setStatistics] = useState(null)

    useEffect(() => {
        getSummary()
        getStatistics()
    }, [])
    useEffect(() => {}, [currentTab])
    useEffect(() => {}, [summary])
    useEffect(() => {}, [statistics])
    useEffect(() => {}, [searchParams])

    const getSummary = () => {
        financeServices.getFinanceSummary().then(
            res => {
                setSummary(res.data)
            },
            err => {
                console.log(err);
            }
        )

    }
    const getStatistics = () => {
        setError(null)
        setLoading(true)
        financeServices.getFinanceStatistics().then(
            res => {
                setStatistics(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
            }
        )
    }

    function changeActiveTab(val){
        const allParams = [...searchParams.entries()]
        if (allParams.length) {
            allParams.forEach(element => {
                setSearchParams((params) => {
                    params.delete(element[0])
                    return params
                })
            })
        }
        setCurrentTab(val)
    }
    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Finance - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                   <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div> 
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <div className="mb-3">
                                    <FinanceSummary
                                        summary={summary}/>
                                </div>
                                <div className="mb-4 p-3">
                                    <FInanceStatistics
                                        statistics={statistics}/>
                                </div>
                                <div className='md:flex gap-3 p-3'>
                                    <div className="md:w-4/12 lg:w-3/12 rounded-md">
                                        <FinanceSidebar
                                            currentTab={currentTab}
                                            setCurrentTab={setCurrentTab}
                                            changeActiveTab={changeActiveTab}
                                        />
                                    </div>
                                    <div className="md:w-8/12 lg:w-9/12">
                                        {currentTab === 0 && (
                                            <ServiceTransactions
                                                currentTab={currentTab}/>
                                        )}
                                        {currentTab === 1 && (
                                            <PendingDisbursements
                                                changePageParam={changePageParam}
                                                changeLimitParam={changeLimitParam}
                                                currentTab={currentTab}/>
                                        )}
                                        {currentTab === 2 && (
                                            <SubscriptionTransactions
                                                currentTab={currentTab}/>
                                        )}
                                        {currentTab === 3 && (
                                            <PendingCommissions
                                                changePageParam={changePageParam}
                                                changeLimitParam={changeLimitParam}
                                                currentTab={currentTab}/>
                                        )}
                                        {currentTab === 4 && (
                                            <ReceivedCommission
                                                changePageParam={changePageParam}
                                                changeLimitParam={changeLimitParam}
                                                currentTab={currentTab}/>
                                        )}
                                        {currentTab === 5 && (
                                            <Payouts
                                                changePageParam={changePageParam}
                                                changeLimitParam={changeLimitParam}
                                                currentTab={currentTab}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default FinancePage