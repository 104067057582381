import moment from 'moment'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function AccountDetails(props) {
    const user = props.expert
    useEffect(() => {}, [user])
    return (
        <div id="account-details" className='p-3'>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Account Details</p>
                </div> 
                <div className='p-2 space-y-3'>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Account Type</p>
                        <p>Expert</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Created On</p>
                        <p className='flex gap-1'>
                            <span>{moment(user?.creation_time).format("Do MMMM YYYY")}</span>
                            <span>.</span>
                            <span>{moment(user?.creation_time).format("HH:MM:SS")}</span>
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Joined from</p>
                        <p className='flex gap-1'>
                            {user?.referred_code ? (
                                <span>Referral</span>
                            ):(
                                <span>Joined Directly</span>
                            )}
                        </p>
                    </div>
                    {user?.referred_code && (
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Refered by</p>
                            <p className='flex gap-1'>
                                <Link to={
                                    user?.referred_code?.owner?.is_buyer 
                                    ? 
                                    `/buyers/${user?.referred_code?.owner?.id}`
                                    :
                                    `/experts/${user?.referred_code?.owner?.id}`}>
                                        {user?.referred_code?.owner?.full_name ? (
                                            <span className='text-accent'>{user?.referred_code?.owner?.full_name}</span>
                                        ):(
                                            <span>{user?.referred_code?.owner?.email}</span>
                                        )}
                                </Link>
                            </p>
                        </div>
                    )}
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Service</p>
                        <p>{user?.expert_service_type?.name}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>DOT Number</p>
                        <p className='flex gap-1'>
                            {user?.user_documents?.dot_number ? (
                                <span>{user?.user_documents?.dot_number}</span>
                            ):(
                                <span>Not yet Saved</span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountDetails