import React, { useEffect } from 'react'
import utils from '../../utils'
import { IoSettingsOutline } from "react-icons/io5"
import { useNavigate } from 'react-router-dom'

function ServiceMadeTableRow(props) {
    const service = props.service
    const navigate = useNavigate()

    useEffect(() => {}, [service])
    return (
        <tr>
            <td>
                {service?.owner ? (
                    <div className="flex items-center gap-3">
                        <div className='avatar'>
                            {utils.checkImage(service?.owner) ? (
                                <div className=" w-11 rounded-full">
                                    <img src={service?.owner?.image} alt={service?.owner?.email} />
                                </div>
                            ):(
                                <div>
                                    <div className="avatar placeholder">
                                        <div className="bg-neutral text-neutral-content rounded-full w-11">
                                            <span className="text-xl">{utils.makeProfileLetters(service?.owner)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className=''>
                            <div>
                                {service?.owner?.full_name ? (
                                    <p>{service?.owner?.full_name}</p>
                                ):(
                                    <p>{service?.owner?.email}</p>
                                )}
                            </div>
                        </div>
                    </div>
                ):(
                    <span>-</span>
                )}
            </td>
            <td>
                <div>
                    {service?.responder ? (
                        <span>
                            {service?.responder?.full_name ? (
                                <p>{service?.responder?.full_name}</p>
                            ):(
                                <p>{service?.responder?.email}</p>
                            )}
                        </span>
                    ):(
                        <span>-</span>
                    )}                    
                </div>
            </td>
            <td>
                {service?.from_formated_address}
            </td>
            <td>
                {service?.destination_formated_address}
            </td>
            <td>
                {service?.status}
            </td>
            <td>
                {service?.payment_amount}
            </td>
            <td>
                <button 
                    onClick={() => navigate(`/service/${service?.id}`)}
                    className='btn btn-ghost' 
                    style={{color: "#42B883"}}><IoSettingsOutline/> Manage</button>
            </td>
        </tr>
    )
}

export default ServiceMadeTableRow