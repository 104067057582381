import React, { useEffect } from 'react'
import utils from '../../utils'
import moment from 'moment'
// import { FaCircle } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function ExpertTableRow(props) {
    const expert = props.expert
    // const status = "offline"
    const navigate = useNavigate()

    useEffect(()=>{}, [expert])

    function viewExpert(id){
        navigate(`/experts/${id}`)
    }
    return (
        <tr 
            onClick={() => viewExpert(expert?.id)}
            className='mb-2 hover:cursor-pointer'>
            <td>
                {expert && (
                    <div className="flex items-center gap-3">
                        <div className='avatar'>
                            {utils.checkImage(expert) ? (
                                <div className=" w-11 rounded-full">
                                    <img src={expert?.image} alt={expert?.email} />
                                </div>
                            ):(
                                <div>
                                    <div className="avatar placeholder">
                                        <div className="bg-neutral text-neutral-content rounded-full w-11">
                                            <span className="text-xl">{utils.makeProfileLetters(expert)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div>
                                {expert?.full_name ? (
                                    <p>{expert?.full_name}</p>
                                ):(
                                    <p>{expert?.email}</p>
                                )}
                            </div>
                            <div>
                                <p className='text-gray-500 text-xs'>Joined: {moment(expert?.creation_time).format("MMMM Do YYYY")}</p>
                            </div>
                        </div>
                    </div>
                )}                
            </td>
            <td>
                <span>{expert?.city}, {expert?.country ? (expert?.country):('Kenya')}</span>
            </td>
            <td>
                {expert?.business_name ? (expert?.business_name):('-')}
            </td>
            <td>
                <span>{expert?.employees}</span>
            </td>
            {/* <td>
                {status === "offline"  && (
                    <span className='flex gap-3 text-gray-600 items-center'>
                        <FaCircle className='text-gray-600 h-4 w-4'/>
                        <p>Offline</p>
                    </span>
                )}
            </td> */}
            <td>
                {expert?.gigs_completed}
            </td>
            <td>
                <div className='flex gap-3 items-center'>
                    <FaRegStar className='text-warning h-4 w-4'/>
                    <span>{expert?.ratings}</span>
                </div>
            </td>
        </tr>
    )
}

export default ExpertTableRow