/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext, useEffect } from 'react'
import logo from'../../assets/sm_logo.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IoIosLogOut, IoMdAlarm } from "react-icons/io"
import { IoGiftOutline, IoBriefcaseOutline } from "react-icons/io5"
import { AiOutlineUser } from "react-icons/ai"
import { GrUserWorker } from "react-icons/gr"
import { MdPersonOutline } from "react-icons/md";
import toast from 'react-hot-toast'
import UserContext from '../../context/UserContext'

function SideBar() {
    const location = useLocation()
    const navigate = useNavigate()
    const userCtx = useContext(UserContext)
    
    useEffect(() => {}, [location])

    function logOut(){
        const logoutToast = toast.loading("Signing out...")
        userCtx.logout()
        setTimeout(() => {
            toast.success("Finished", {
                id:logoutToast
            })
        }, [900]);
        navigate('/auth/login')
    }
    return (
        <div className='drawer-side z-40 overflow-y-scroll no-scrollbar' style={{scrollBehavior: 'smooth'}}>
            <label htmlFor="nav-drawer" className="drawer-overlay"></label> 
            <div className='bg-base-100 min-h-full w-80 p-5 border-l border lg:mr-3'>
                <div className="flex justify-center">
                    <img src={logo} alt="towgig logo" />
                </div>
                <p className="text-accent text-center font-lg font-bold" style={{marginTop: '-3.9px', color: '#42B883'}}>TowGig</p>
                <ul className="menu space-y-2 text-md mt-9">
                    <li>
                        <Link to='/' className={location.pathname === '/' ? 'active': ''}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path 
                                    d="M3.5 15V16.5H6.9395L2 21.4395L3.0605 22.5L8 17.5605V21H9.5V15H3.5ZM18.5 15.75H20V19.5H18.5V15.75ZM15.5 12H17V19.5H15.5V12ZM12.5 13.5H14V19.5H12.5V13.5Z" 
                                    fill={location.pathname === '/' ? 'white': '#1E1E1E'}/>
                                <path 
                                    d="M21.5 1.5H3.5C3.1023 1.5004 2.721 1.65856 2.43978 1.93978C2.15856 2.221 2.0004 2.6023 2 3V12H3.5V9.75H21.5007L21.5015 21H12.5V22.5H21.5C21.8976 22.4994 22.2788 22.3412 22.56 22.06C22.8412 21.7788 22.9994 21.3976 23 21V3C22.9996 2.6023 22.8414 2.221 22.5602 1.93978C22.279 1.65856 21.8977 1.5004 21.5 1.5ZM9.5 8.25H3.5V3H9.5V8.25ZM11 8.25V3H21.5V8.25H11Z" 
                                    fill={location.pathname === '/' ? 'white': '#1E1E1E'}/>
                            </svg>
                            <span className='ml-4 py-3 font-semibold'>Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <details >
                            <summary>
                                <IoBriefcaseOutline className='h-6 w-6'/>
                                <span className='ml-4 py-3 font-semibold'>Gigs / Services</span>
                            </summary>
                            <ul className='space-y-2'>
                                <li>
                                    <Link 
                                        to={'/services/manage'}
                                        className={String(location.pathname).includes('/services/manage') ? 'py-2 active' : 'py-2'}
                                    >Manage Services</Link>
                                </li>
                                <li>
                                    <Link 
                                        to={'/services/ongoing'}
                                        className={location.pathname === '/services/ongoing' ? 'py-2 active' : 'py-2'}
                                    >Ongoing Services</Link>
                                </li>
                                <li>
                                    <Link 
                                        to={'/services/scheduled'}
                                        className={location.pathname === '/services/scheduled' ? 'py-2 active' : 'py-2'}
                                    >Scheduled Services</Link>
                                </li>
                                <li>
                                    <Link 
                                        to={'/services/completed'}
                                        className={location.pathname === '/services/completed' ? 'py-2 active' : 'py-2'}
                                    >Completed Services</Link>
                                </li>
                                <li>
                                    <Link 
                                        to={'/services/cancelled'}
                                        className={location.pathname === '/services/cancelled' ? 'py-2 active' : 'py-2'}
                                    >Cancelled Services</Link>
                                </li>
                            </ul>
                        </details>
                    </li>
                    <li>
                        <Link to='/finance' className={location.pathname === '/finance' ? 'active': ''}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path 
                                    d="M3.672 20.828C4.843 22 6.729 22 10.5 22H14.5C18.271 22 20.157 22 21.328 20.828C22.5 19.657 22.5 17.771 22.5 14C22.5 12.83 22.5 11.842 22.465 11M21.328 7.172C20.157 6 18.271 6 14.5 6H10.5C6.729 6 4.843 6 3.672 7.172C2.5 8.343 2.5 10.229 2.5 14C2.5 15.17 2.5 16.158 2.535 17M12.5 2C14.386 2 15.328 2 15.914 2.586C16.5 3.172 16.5 4.114 16.5 6M9.086 2.586C8.5 3.172 8.5 4.114 8.5 6" 
                                    stroke={location.pathname === '/finance' ? 'white': '#1E1E1E'}
                                    strokeWidth="1.5" 
                                    strokeLinecap="round"/>
                                <path 
                                    d="M12.5 17.333C13.605 17.333 14.5 16.587 14.5 15.667C14.5 14.747 13.605 14 12.5 14C11.395 14 10.5 13.254 10.5 12.333C10.5 11.413 11.395 10.667 12.5 10.667M12.5 17.333C11.395 17.333 10.5 16.587 10.5 15.667M12.5 17.333V18M12.5 10.667V10M12.5 10.667C13.605 10.667 14.5 11.413 14.5 12.333" 
                                    stroke={location.pathname === '/finance' ? 'white': '#1E1E1E'}
                                    strokeWidth="1.5" 
                                    strokeLinecap="round"/>
                            </svg>
                            <span className='ml-4 py-3 font-semibold'>Finances</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='/applications' className={String(location.pathname).includes('/applications') ? 'active': ''}>
                            <MdPersonOutline className='h-7 w-7'/>
                            <span className='ml-4 py-3 font-semibold'>Account Applications</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='/experts' className={String(location.pathname).includes('/experts') ? 'active': ''}>
                            <GrUserWorker className='h-6 w-6'/>
                            <span className='ml-4 py-3 font-semibold'>Experts</span>
                        </Link>
                    </li>
                    <li>
                        <Link to='/buyers' className={String(location.pathname).includes('/buyers') ? 'active': ''}>
                            <AiOutlineUser className='h-6 w-6'/>
                            <span className='ml-4 py-3 font-semibold'>Buyers</span>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to='/subscriptions' 
                            className={String(location.pathname).includes('/subscriptions') ? 'active': ''}>
                            <IoMdAlarm className='h-6 w-6'/>
                            <span className='ml-4 py-3 font-semibold'>Subscriptions</span>
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to='/promotions' 
                            className={String(location.pathname).includes('/promotions') ? 'active': ''}>
                            <IoGiftOutline className='h-6 w-6'/>
                            <span className='ml-4 py-3 font-semibold'>Promotions</span>
                        </Link>
                    </li>
                </ul>
                <ul className='menu space-y-2 text-md mt-20'>
                    {/* <li>
                        <Link to='/auth/settings' className={location.pathname === '/auth/settings' ? 'active': ''}>
                            <IoSettingsOutline className='h-6 w-6' />
                            <span className='ml-4 py-3 font-semibold'>Settings</span>
                        </Link>
                    </li> */}
                    <li>
                        <a onClick={() => logOut()}>
                            <IoIosLogOut className='h-6 w-6'/>
                            <span className='ml-4 py-3 font-semibold'>Logout</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SideBar