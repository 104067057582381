import React from 'react'
import { FaRegCircleCheck } from "react-icons/fa6"

function PromotionSteps({stage}) {
    return (
        <div className=''>
            <div className="flex items-center mb-9 gap-6">
                <div className="divider-start flex gap-3 items-center text-nowrap text-accent"><FaRegCircleCheck/> Set details</div>
                <div className=" w-full divider"></div>
                <span className={stage === 0 ? "text-gray-500":"text-accent"}>
                    <div className="divider-end flex gap-3 items-center text-nowrap"> <FaRegCircleCheck/>Launch or schedule</div>
                </span>
            </div>
        </div>
    )
}

export default PromotionSteps