import React, { useEffect, useState } from 'react'
import promotionsServices from '../../services/PromotionsServices'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import ParticipatntsTable from './ParticipatntsTable'
import { useSearchParams } from 'react-router-dom'

function PromotionParticipants(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [participants, setPartcipants] = useState(null)
    const promotion = props.promotion

    useEffect(() => {
        if (promotion?.id) {
            getPartcipants(promotion?.id)
        }
    }, [promotion, searchParams])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [participants])

    const getPartcipants = (id) => {
        setError()
        setLoading(true)
        promotionsServices.getPromotionParticipants(id).then(
            res => {
                setPartcipants(res.data)
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }
    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    return (
        <div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Promotion Participants</p>
                </div>
                <div className='p-2 space-y-3'>
                    {loading ? (
                        <div className='flex items-center justify-center h-44'>
                            <Loading/>
                        </div>
                    ):(
                        <div>
                            {error ? (<FetchError error={error}/>):(
                                <div>
                                    {participants ? (
                                        <ParticipatntsTable
                                            participants={participants}
                                            changeLimitParam={changeLimitParam}
                                            changePageParam={changePageParam}
                                        />
                                    ):null}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PromotionParticipants