import React, { useEffect, useRef, useState } from 'react'
import { IoTicketOutline } from "react-icons/io5";
import subscriptionServices from '../../services/SubscriptionService';
import Loading from '../feedback/Loading';
import toast from 'react-hot-toast';
import utils from '../../utils';
import { IoAddCircleOutline } from "react-icons/io5";
import PeriodNameModal from './PeriodNameModal';

function AddSubscriptionPricingModal(props) {
    const [priceId, setPriceId] = useState('')
    const [amount, setAmount] = useState(0)
    const subscriptionplan = props.subscriptionplan
    const [periodNames, setPeriodNames] = useState()
    const [currencies, setCurrencies] = useState()
    const periodRef = useRef()
    const currencyRef = useRef()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getPeriodNames()
        getAllowedCurrencies()
    },[])
    useEffect(() => {}, [subscriptionplan])

    const getPeriodNames = () => {
        subscriptionServices.getPeriodNames().then(
            res => {
                setPeriodNames(res.data)
            }, 
            err => {console.log(err);}
        )
    }
    const getAllowedCurrencies = () => {
        subscriptionServices.getAllowedCurrencies().then(
            res => {
                setCurrencies(res.data)
            },
            err => {console.log(err);}
        )
    }
    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        const data = {
            "amount": amount,
            "subscription_plan": subscriptionplan?.id,
            "period_name": periodRef.current.value,
            "allowed_currency": currencyRef.current.value,
            "stripe_price_id": priceId
        }
        const createToast = toast.loading("Creating billing period")
        subscriptionServices.addBillingPeriod(data).then(
            res => {
                setAmount(0)
                setPriceId('')
                setLoading(false)
                toast.success("Successfully created billing period", {
                    id:createToast
                })
                closeModal()
                props.updateBillingPeriods()
            },
            err => {
                setLoading(false)
                toast.error(utils?.toasterError, {
                    id:createToast
                })
            }
        )
    }
    function closeModal(){
        const closeBtn = document.getElementById("pricing-add-modal-close-btn")
        closeBtn.click()
    }
    function openModal(){
        const opneBtn = document.getElementById("period-add-open-btn")
        opneBtn.click()
    }
    return (
        <div>
            <label htmlFor="pricing-add-modal" id='pricing-add-open-btn' className="hidden">Add Subscription Pricing</label>
            <input type="checkbox" id="pricing-add-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-7/12 max-w-5xl">
                    <PeriodNameModal
                        getPeriodNames={getPeriodNames}
                    />
                    <label htmlFor="pricing-add-modal" id='pricing-add-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-6'>Add Subscription Pricing</h2>
                    <div className="flex justify-center mb-3">
                        <div className="p-3 rounded-full border-2">
                            <IoTicketOutline className='h-9 w-9 text-accent'/>
                        </div>
                    </div>
                    <p className="text-lg font-bold text-center mb-4">{subscriptionplan?.name}</p>
                    <form onSubmit={submitHandler}>

                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Billing Period *</span>
                            </div>
                            <div className="flex gap-3">
                                <select ref={periodRef} required className="select select-bordered flex flex-grow">
                                    {periodNames?.map((period, idx) => (
                                        <option 
                                            key={idx}
                                            value={period?.id}>{period?.name}</option>
                                    ))}
                                </select>
                                <div className='flex-grow-0'>
                                    <div 
                                        onClick={() => openModal()}
                                        className='btn btn-accent btn-outline'><IoAddCircleOutline className='w-5 h-5'/> Add Period Name</div>
                                </div>
                            </div>                            
                        </div>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Currency *</span>
                            </div>
                            <div className="flex gap-3">
                                <select ref={currencyRef} required className="select select-bordered flex flex-grow">
                                    {currencies?.map((currency, idx) => (
                                        <option 
                                            key={idx}
                                            value={currency?.id}>{currency?.name}</option>
                                    ))}
                                </select>
                                <div className='flex-grow-0'>
                                    <div className='btn btn-accent btn-outline btn-disabled'><IoAddCircleOutline className='w-5 h-5'/> Add Currency</div>
                                </div>
                            </div>                            
                        </div>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Stripe Price ID *</span>
                            </div>
                            <input 
                                value={priceId}
                                onChange={(e) => setPriceId(e.target.value)}
                                type="text" 
                                required 
                                className='input input-bordered' 
                                placeholder='Enter Stripe Price ID' />
                        </div>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Amount *</span>
                            </div>
                            <input 
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                type="number" 
                                required 
                                min={1}
                                className='input input-bordered' 
                                placeholder='Enter number' />
                        </div>
                        <div className='modal-action flex justify-end gap-3'>
                            {loading ? (<Loading/>):(
                                <>
                                    <div 
                                        onClick={() => closeModal()}
                                        className='btn btn-ghost text-accent'>Cancel</div>
                                    <button type='submit' className='btn text-white btn-accent'>Save</button>
                                </>
                            )}                                
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddSubscriptionPricingModal