import React, { useEffect, useRef, useState } from 'react'
import { MdMailOutline } from "react-icons/md"
import OTPInput, { ResendOTP } from "otp-input-react";
import toast from 'react-hot-toast';
import { FiLock } from "react-icons/fi"
import authServices from '../../services/AuthServices';
import Loading from '../feedback/Loading';
import utils from '../../utils';
import { useNavigate } from 'react-router-dom';

const renderTime = (remainingTime) => {
    return (
        <span className='mt-9 mb-10'>
            <span>Didn't get code?</span> <span style={{color: '#42B883'}}>Resend code in : {remainingTime}</span>
        </span>
    )
}
function ResetPasswordForm() {
    const [loading, setLoading] = useState(false)
    const [stage, setStage] = useState(0)
    const emailRef = useRef()
    const [code, setCode] = useState(0)
    const [email, setEmail] = useState('')
    const [passwordType, setPasswordType] = useState("password")
    const passwordRef = useRef()
    const confirmPassRef = useRef()
    const navigate = useNavigate()

    useEffect(() => {}, [stage])

    function changeStage(val){
        setStage(val)
    }
    function togglePassword(){
        if(passwordType==="password"){
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    function submitRequestOTP(e){
        e.preventDefault()
        const mail = emailRef.current.value
        setEmail(mail)
        setLoading(true)
        const emailToast = toast.loading("Checking email...")
        const data = {
            "email": mail
        }
        authServices.checkEmail(data).then(
            res => {
                const exists = res.data?.email_exists
                if (exists) {
                    authServices.requestOtp(data).then(
                        res => {
                            console.log(res.data);
                            toast.success("Submited to the email successfully", {
                                id: emailToast
                            })
                            setLoading(false)
                            changeStage(stage + 1)
                        }, 
                        err => {
                            setLoading(false)
                            console.log(err);
                        }
                    )
                } else{
                    setLoading(false)
                    toast.error("Email entered does not exist", {
                        id:emailToast
                    })
                }
            },
            err => {
                setLoading(false)
                toast.error(utils.toasterError, {
                    id:emailToast
                })
            }
        )
    }
    function checkValid(val){
        if (val === 0) {
            return false
        }
        if (val.length < 4) {
            return false
        } else {
            return true
        }
    }
    function submitOTP(e){
        e.preventDefault()
        setLoading(true)
        const otpToast = toast.loading("Checking OTP...")
        const isValid = checkValid(code)
        if (isValid) {
            const data = {
                "otp": code
            }
            authServices.checkOtp(data).then(
                res => {
                    const exists = res.data?.otp_exists
                    setLoading(false)
                    if (exists) {
                        toast.success("OTP confirmed", {
                            id: otpToast
                        })
                        changeStage(stage + 1)
                        // const element = document.getElementById("first-stage")
                        // element.remove()
                    } else{
                        toast.error("OTP entered does not exist", {
                            id:otpToast
                        })
                    }                    
                },
                err => {
                    setLoading(false)
                    toast.error(utils.toasterError, {
                        id:otpToast
                    })
                }
            )
        } else {
            setLoading(false)
            toast.error("Please enter the correct OTP length", {
                id:otpToast
            })
            return
        }
    }
    function submitPassword(e){
        e.preventDefault()
        const pass1 = passwordRef.current.value
        const pass2 = confirmPassRef.current.value 
        if (pass1 !== pass2) {
            toast.error("Your passwords do not match")
            return
        }
        if (pass1.length < 8) {
            toast.error("Incorrect password length")
            return
        }
        setLoading(true)
        const submitToast = toast.loading("Submitting details...")
        const data = {
            "otp": code,
            "password": pass1,
            "password2": pass2
        }
        authServices.resetPassword(data).then(
            res => {
                setLoading(false)
                if (res.data?.message) {
                    toast.success(res.data?.message, {
                        id:submitToast
                    })
                } else {
                    toast.success("Submited successfully, use credentials to sign in", {
                        id:submitToast
                    })
                }
                navigate('/auth/login')
            },
            err => {
                setLoading(false)
                toast.error(utils.toasterError, {
                    id:submitToast
                })
                console.log(err);
            }
        )
    }
    return (
        <div className='w-9/12 md:w-7/12'>
            <div id='first-stage'>
                {(stage === 1 | stage === 0) && (
                    <p className="text-2xl font-bold text-primary mb-9">Reset your password</p>
                )}
            </div>
            {stage === 2 && (
                <p className="text-2xl font-bold text-primary mb-10">Create a new password</p>
            )}
            {stage === 0 && (
                <form onSubmit={submitRequestOTP}>
                    <p className='text-gray-600 text-lg mb-16'>Enter your email address and we’ll send a confirmation email to reset your password</p>
                    <p className='text-lg font-bold text-primary mb-4'>Enter your Email</p>
                    <div className="flex space-x-4 py-4 px-3 border rounded-sm mb-36 items-center">
                        <MdMailOutline className='h-7 w-8 text-accent' style={{color: "#42B883"}}/>
                        <input ref={emailRef} type='email' required name='email' className="input w-full" placeholder="Email"/>
                    </div>
                    <div className="flex justify-center">
                        {(loading && (stage === 0) ? (<Loading/>):(
                            <button type='submit' style={{borderRadius: '0.8em'}} className='btn-accent text-white btn btn-lg w-full rounded-md'>Request Code</button>
                        ))}                        
                    </div>
                </form>
            )}
            {stage === 1 && (
                <form onSubmit={submitOTP}>
                    <p className='text-gray-600 text-lg mb-16'>Enter the 4 PIN Code that was sent to {email} to reset your password</p>
                    <p className='text-lg font-bold text-primary mb-4'>Enter the code</p>
                    <div className='mb-16'>
                        <OTPInput 
                            value={code}
                            inputClassName="border"
                            inputStyles={{
                                width: "63px",
                                height: "63px",
                            }} 
                            onChange={setCode} 
                            autoFocus={true}
                            OTPLength={4} 
                            otpType="number" 
                            disabled={false} 
                            secure={false} />
                        <ResendOTP renderTime={renderTime} />
                    </div>
                    <div className="flex justify-center">
                        {(loading && (stage === 1) ? (<Loading/>):(
                            <button type='submit' style={{borderRadius: '0.8em'}} className='btn-accent text-white btn btn-lg w-full rounded-md'>Proceed</button>
                        ))}
                    </div>
                </form>
            )}
            {stage === 2 && (
                <form onSubmit={submitPassword}>
                    <p className='text-lg font-bold text-primary mb-4'>Enter new password</p>
                    <div className="flex space-x-4 py-4 px-3 border rounded-sm mb-4 items-center">
                        <FiLock className='h-7 w-8 text-accent' style={{color: "#42B883"}}/>
                        <input type={passwordType} required ref={passwordRef} name='password' className='input w-full' placeholder='Password'/>
                    </div>
                    <div className="flex space-x-4 py-4 px-3 border rounded-sm mb-4 items-center">
                        <FiLock className='h-7 w-8 text-accent' style={{color: "#42B883"}}/>
                        <input type={passwordType} required ref={confirmPassRef} name='password' className='input w-full' placeholder='Confirm Password'/>
                    </div>
                    <div className='mb-20'>
                        <label className="label">
                            <span className="label-text">Show Password</span>
                        </label>
                        <input 
                            type="checkbox" 
                            className="checkbox"
                            onClick={togglePassword} />
                    </div>
                    <div className="flex justify-center">
                        {(loading && (stage === 2) ? (<Loading/>):(
                            <button type='submit' style={{borderRadius: '0.8em'}} className='btn-accent text-white btn btn-lg w-full rounded-md'>Save Password</button>
                        ))}
                    </div>
                </form>
            )}    
        </div>
    )
}

export default ResetPasswordForm