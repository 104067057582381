const siteName = process.env.REACT_APP_SITE_NAME || "TowGig"
const env = process.env.REACT_APP_ENV || "development"
const urls = {
    "production": process.env.REACT_APP_API_URL,
    "development": process.env.REACT_APP_API_URL_DEV,
}
const baseUrl = urls[env]

const multipartHeader = {"Content-Type": "multipart/form-data"}

const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const toasterPosition = "bottom-center"

const toasterOptions = {
    className: "rounded-md border"
}

const toasterError = "Something went wrong"

const makeProfileLetters = (user) => {
    if (user?.full_name) {
        const name = user?.full_name
        return name.charAt(0)
    } else{
        const mail = user?.email
        return mail.charAt(0)
    }
}

const checkImage = (user) => {
    if (user?.image) {
        return true
    }
    return false
}


const getPageSize = (next, previous) =>{
    let pageSize = 0
    if (next !== null) {
        pageSize = parseInt(utils.getLimitValue(next))
    } else{
        if (previous !== null) {
            pageSize = parseInt(utils.getLimitValue(previous))
        }
    }
    return pageSize
}

const getLimitValue = (url) => {
    const urlString = url.split("?")[1]
    const param = new URLSearchParams(urlString)
    return param.get("limit")
}

const getDefaultPageSize = (next, previous, count) => {
    const pageSize = utils.getPageSize(next, previous)
    if (pageSize > 0) {
        return pageSize
    }
    return count
}

const getPageValue = (url) =>{
    const urlString = url.split("?")[1]
    const param = new URLSearchParams(urlString)
    return param.get("page")
}

const getCurrentPage = (previous, next) => {
    if (next !== null) {
        const nextPage = getPageValue(next)
        return parseInt(nextPage) - 1
    } else {
        if (previous !== null) {
            const previousPage = getPageValue(previous)
            if(isNaN(parseInt(previousPage))){
                return 2
            }
            return parseInt(previousPage) + 1
        }
        if (next === null && previous === null) {
            return 1
        }
    }
}

const getMonthlyPlan = (subscription, billingPeriods) => {
    const subscriptionPlan = billingPeriods.filter(period => period?.subscription_plan?.id === subscription?.id)
    return subscriptionPlan
}

const utils = {
    siteName,
    baseUrl,
    multipartHeader,
    toCommas,
    toasterPosition,
    toasterOptions,
    toasterError,
    makeProfileLetters,
    checkImage,
    getLimitValue,
    getPageSize,
    getDefaultPageSize,
    getMonthlyPlan,
    getPageValue,
    getCurrentPage,
}

export default utils
