import apiClient from './AxiosHttp'

const api = '/api/v1'

const getPromotions = (params) => {
    return apiClient.get(`${api}/dashboard/promotions/${params}`)
}

const getPromotionsStatistics = () => {
    return apiClient.get(`${api}/dashboard/promotions/statistics/`)
}

const createPromotion = (details) => {
    return apiClient.post(`${api}/payments/promo/codes/`, details)
}

const getPromotion = (id) => {
    return apiClient.get(`${api}/dashboard/promotion/get/${id}/`)
}

const getPromotionParticipants = (id) => {
    return apiClient.get(`${api}/dashboard/promotion/participants/${id}/`)
}

const getReferalClaims = (id) => {
    return apiClient.get(`${api}/dashboard/referal/claims/${id}/`)
}

const getUserPromotions  = (id) => {
    return apiClient.get(`${api}/dashboard/promo/code/user/${id}/`)
}

const promotionsServices = {
    getPromotionsStatistics,
    getPromotions,
    createPromotion,
    getPromotion,
    getPromotionParticipants,
    getReferalClaims,
    getUserPromotions
}

export default promotionsServices