import React, { useEffect } from 'react'

function ServiceSideBar(props) {
    const currentTab = props.currentTab
    const setCurrentTab = props.setCurrentTab

    useEffect(() => {}, [currentTab])
    return (
        <div className='sticky top-1'>
            <div className="join join-vertical w-full">
                <div 
                    onClick={() => setCurrentTab(0)}
                    className={
                        currentTab === 0 
                        ? 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                        : 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                    }>
                    Service Details
                </div>
                <div
                    onClick={() => setCurrentTab(1)}
                    className={
                        currentTab === 1 
                        ? 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                        : 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                    }>
                    Gig Progression
                </div>
                <div
                    onClick={() => setCurrentTab(2)}
                    className={
                        currentTab === 2 
                        ? 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                        : 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                    }>
                    Vehicles
                </div>
                <div
                    onClick={() => setCurrentTab(3)}
                    className={
                        currentTab === 3 
                        ? 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                        : 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                    }>
                    Proof of Work
                </div>
                <div
                    onClick={() => setCurrentTab(4)}
                    className={
                        currentTab === 4 
                        ? 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                        : 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                    }>
                    Cost Breakdown
                </div>
                <div
                    onClick={() => setCurrentTab(5)}
                    className={
                        currentTab === 5
                        ? 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                        : 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                    }>
                    Transaction
                </div>
                <div
                    onClick={() => setCurrentTab(6)}
                    className={
                        currentTab === 6 
                        ? 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                        : 
                        'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                    }>
                    Reviews
                </div>
            </div>
        </div>
    )
}

export default ServiceSideBar