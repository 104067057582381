import React, { useEffect } from 'react'
import utils from '../../utils'

function CostBreakDown(props) {
    const service = props.service
    useEffect(() => {}, [service])
    return (
        <div className="rounded-md border pb-7">
            <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                <p className="font-bold text-lg">Cost Breakdown</p>    
            </div>
            <div className='p-2 space-y-3'>
                {service?.payment_object ? (
                    <div className='space-y-3'>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Service Charge</p>
                            <div className='flex gap-2'>
                                <span>{service?.payment_object?.user_payment_method?.currency} .</span>
                                <p>
                                    {utils.toCommas(service?.payment_object?.service_charge)}
                                </p>
                            </div>                            
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Promo Code Discount</p>
                            <p>{utils.toCommas(service?.payment_object?.promo_code_discount)}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Referral Discount</p>
                            <p>{utils.toCommas(service?.payment_object?.referral_discount)}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Subscription Discount</p>
                            <p>{utils.toCommas(service?.payment_object?.subscription_discount)}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Tip</p>
                            <p>{0}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Total</p>
                            <div className='flex gap-2'>
                                <span>{service?.payment_object?.user_payment_method?.currency} .</span>
                                <p>{utils.toCommas(service?.payment_object?.total)}</p>
                            </div>                              
                        </div>
                    </div>
                ):(
                    <div>
                        <p>No Payment Created!</p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CostBreakDown