import React, { useEffect } from 'react'

function FinanceSidebar(props) {
    const currentTab = props.currentTab
    const changeActiveTab = props.changeActiveTab

    useEffect(() => {}, [currentTab])
    return (
        <div className='sticky top-1'>
            <div 
                onClick={() => changeActiveTab(0)}
                className={
                    currentTab === 0 
                    ? 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                    : 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                }>
                Recent Transactions
            </div>
            <div
                onClick={() => changeActiveTab(1)}
                className={
                    currentTab === 1 
                    ? 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                    : 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                }>
                Pending Disbursements
            </div>
            <div
                onClick={() => changeActiveTab(5)}
                className={
                    currentTab === 5
                    ? 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                    : 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                }>
                Payouts
            </div>
            <div
                onClick={() => changeActiveTab(3)}
                className={
                    currentTab === 3
                    ? 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                    : 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                }>
                Pending Commissions
            </div>
            <div
                onClick={() => changeActiveTab(4)}
                className={
                    currentTab === 4
                    ? 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                    : 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                }>
                Received Commissions
            </div>
            <div
                onClick={() => changeActiveTab(2)}
                className={
                    currentTab === 2 
                    ? 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                    : 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                }>
                Subscription Transactions
            </div>
        </div>
    )
}

export default FinanceSidebar