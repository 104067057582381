import React, { useEffect, useState } from 'react'
import servicesService from '../../services/ServicesService'
import toast from 'react-hot-toast'
import utils from '../../utils'
import Loading from '../feedback/Loading'

function CommissionModal(props) {
    const commision = props.commision
    const action = props.action
    const service_type = props.service_type
    const [percentage, setPercentage] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (action === 1 && commision) {
            setPercentage(commision[0]?.rate)
        }
    }, [action, commision])
    useEffect(() => {}, [percentage])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [commision])
    useEffect(() => {}, [service_type])

    function closeModal(){
        const closeBtn = document.getElementById("commission-view-modal-close-btn")
        closeBtn.click()
    }
    function submitHandler(e){
        e.preventDefault()
        if (action === 0) {
            const data = {
                "rate": percentage,
            }
            props.setCommission(data)
            closeModal()
            props.openCreateModal()
        }
        if (action === 1) {
            setLoading(true)
            const updateToast = toast.loading("Updating Commission")
            const data = {
                "rate": percentage
            }
            servicesService.updateCommission(commision[0]?.id, data).then(
                res => {
                    props.setCommission([res.data])
                    toast.success("Commission Updated Successfull", {
                        id:updateToast
                    })
                    setLoading(false)
                    closeModal()
                },
                err => {
                    console.log(err);
                    setLoading(false)
                    toast.error(utils.toasterError, {
                        id:updateToast
                    })
                }
            )
        }
    }
    return (
        <div>
            <label htmlFor="commission-view-modal" id='commission-view-modal-open-btn' className="hidden">View Commission Pricing</label>
            <input type="checkbox" id="commission-view-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <label htmlFor="commission-view-modal" id='commission-view-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-6'>{action === 0 ? "Add":"Edit"} Commission Pricing</h2>
                    <p className='text-gray-600 mb-7'>This percentage is what comes in as revenue for the platform when buyers hire experts and pay.</p>
                    <form onSubmit={submitHandler}>
                        <label className="form-control w-full mb-16">
                            <div className="label">
                                <span className="label-text mb-1">Percentage of Service Cost <span className='text-red-600 ml-1'>*</span></span>
                            </div>
                            <input 
                                value={percentage}
                                onChange={(e) => setPercentage(parseInt(e.target.value))}
                                type="number" 
                                required
                                placeholder="Enter Amount" 
                                className="input input-bordered w-full" />
                        </label>
                        <div className='modal-action flex justify-end gap-3'>
                            {loading ? (<Loading/>):(
                                <>
                                <div
                                    onClick={() => closeModal()}
                                    className='btn btn-ghost text-accent'>Cancel</div>
                                <button
                                    className='btn text-white btn-accent'>Save</button>
                                </>                            
                            )}
                        </div>                        
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CommissionModal