import React, { useEffect, useState } from 'react'

function VehicleRangeRow(props) {
    const [percentage, setPercentage] = useState(0)
    const vehicleRange = props.vehicleRange

    useEffect(() => {
        if (vehicleRange) {
            setPercentage(vehicleRange?.percentage)
        }
    }, [vehicleRange])
    useEffect(() => {}, [percentage])

    function updateValue(val, id){
        setPercentage(val)
        const data = {
            'id': id,
            'percentage': val
        }
        props.performChange(data)
    }
    return (
        <tr>
            <td>
                <span>{vehicleRange?.min_value}</span> 
                {vehicleRange?.max_value && (
                    <span> - {vehicleRange?.max_value}</span>
                )}
                <span className='ml-1'>Vehicle{vehicleRange?.max_value && "s"}</span>
            </td>
            <td>
                <label className="form-control w-full">
                    <input 
                        value={percentage}
                        onChange={(e) => updateValue(e.target.value, vehicleRange?.id)}
                        type="number" 
                        max={100}
                        min={1}
                        placeholder="Enter Percentage" 
                        className="input input-bordered w-full input-sm input-disabled" />
                </label>
            </td>
        </tr>
    )
}

export default VehicleRangeRow