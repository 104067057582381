import React, { useEffect } from 'react'
import utils from '../../utils'
import moment from 'moment'

function ReceivedCommisionRow(props) {
    const payment = props.payment

    useEffect(() => {}, [payment])
    return (
        <tr>
            <td>
                {payment?.service_request?.responder && (
                    <div className="flex items-center gap-3">
                        <div className='avatar'>
                            {utils.checkImage(payment?.service_request?.responder) ? (
                                <div className=" w-11 rounded-full">
                                    <img src={payment?.service_request?.responder?.image} alt={payment?.service_request?.responder?.email} />
                                </div>
                            ):(
                                <div>
                                    <div className="avatar placeholder">
                                        <div className="bg-neutral text-neutral-content rounded-full w-11">
                                            <span className="text-xl">{utils.makeProfileLetters(payment?.service_request?.responder)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div>
                                {payment?.service_request?.responder?.full_name ? (
                                    <p>{payment?.service_request?.responder?.full_name}</p>
                                ):(
                                    <p>{payment?.service_request?.responder?.email}</p>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </td>
            <td>
                {moment(payment?.service_request?.creation_time).format("MMMM Do YYYY")}
            </td>
            <td>
                {payment && (
                    <span>
                        {utils.toCommas(
                            payment?.paid_commission
                        )}
                    </span>
                )}
            </td>
            <td>
                {moment(payment?.payment_time).format("MMMM Do YYYY")}
            </td>
        </tr>
    )
}

export default ReceivedCommisionRow