import React, { useEffect } from 'react'
import utils from '../../utils'
import { GrUserWorker } from "react-icons/gr"
import { GiMoneyStack } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";

function FinanceSummary(props) {
    const summary = props.summary

    useEffect(() => {}, [summary])
    return (
        <div className='p-3'>
            {summary && (
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3'>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(summary?.total_revenue)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Total Revenue</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <GiMoneyStack className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(summary?.transactions)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Transactions</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <BiTransfer className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(summary?.gig_earnings)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Earned from gigs</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <GiReceiveMoney className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(summary?.owed_experts)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Owed to experts</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <GrUserWorker className='w-10 h-10'/>
                            </div>
                        </figure>
                    </div>
                </div>
            )}
            
        </div>
    )
}

export default FinanceSummary