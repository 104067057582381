import React from 'react'

function Loading() {
    return (
        <div className="">
            <div
                style={{color: "#42B883"}}
                className="inline-block text-accent h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
        </div>
    )
}

export default Loading