import React from 'react'

function RegistrationProgress(props) {
    const val = props.progress
    return (
        <div className='flex justify-center'>
            <progress className="progress progress-accent w-100 mx-7" value={val} max="100"></progress>
        </div>
    )
}

export default RegistrationProgress