import React, { useEffect } from 'react'

function ActivePlanDetails(props) {
    const subscriptionplan = props.subscriptionplan
    const planBillingPeriods = props.planBillingPeriods
    const totalSubscribers = planBillingPeriods.reduce((a, p) => a + parseFloat(p?.active_subscribers), 0)

    useEffect(() => {}, [subscriptionplan])
    useEffect(() => {}, [planBillingPeriods])
    return (
        <div className='mb-5'>
            <div className="rounded-md border pb-7">
                <div className="py-5 border-b mb-3 p-4">
                    <p className="font-bold text-lg">Plan Details</p>
                </div>
                <div className="p-4 space-y-2">
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Name</p>
                        <p>{subscriptionplan?.name}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Plan Tagline</p>
                        <p>
                            {subscriptionplan?.tag_line ? (
                                <span>{subscriptionplan?.tag_line}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Current Subscribers</p>
                        <p>{totalSubscribers}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Availability Status</p>
                        <p>{subscriptionplan?.status}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Accepted Billing</p>
                        <p>
                            {planBillingPeriods?.map((plan, idx) => (
                                <span
                                    className="comma" 
                                    key={idx}>{plan?.period_name?.name}</span>
                            ))}
                            {planBillingPeriods?.length < 1 && (
                                <span>-</span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActivePlanDetails