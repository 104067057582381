import React, { useEffect } from 'react'

function PaymentTransaction(props) {
    const service = props.service
    const isCompleted = props.isCompleted

    useEffect(() => {
        console.log(service);
    }, [service])
    useEffect(() => {}, [isCompleted])
    return (
        <div className="rounded-md border pb-7">
            <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                <p className="font-bold text-lg">Payment Transaction</p>    
            </div>
            <div className='p-2 space-y-3'>
                {isCompleted ? (
                    <div>
                        <div className='space-y-3'>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Paid From</p>
                                <p>{service?.service_type?.name}</p>
                            </div>
                        </div>
                    </div>
                ):(
                    <div>This gig is not Completed!</div>
                )}
            </div>
        </div>
    )
}

export default PaymentTransaction