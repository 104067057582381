import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import PromotionsSummary from '../../components/promotions/PromotionsSummary'
import PromotionsTable from '../../components/promotions/PromotionsTable'
import { useSearchParams } from 'react-router-dom'
import promotionsServices from '../../services/PromotionsServices'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'

function PromotionsPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const [statistics, setStatistics] = useState()
    const [promotions, setPromotions] = useState()

    useEffect(() => {
        getPromotions()
        getStatistics()
    }, [])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getPromotions(params)
        }
    }, [searchParams])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [statistics])
    useEffect(() => {}, [promotions])

    const getPromotions = (params) => {
        setError()
        setLoading(true)
        promotionsServices.getPromotions(params ? `?${params}` : '').then(
            res => {
                setPromotions(res.data)
                setLoading(false)
            }, 
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }
    const getStatistics = () => {
        promotionsServices.getPromotionsStatistics().then(
            res => {
                setStatistics(res.data)
            }, 
            err => {
                console.log(err);
            }
        )
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
            getPromotions()
        }
    }
    function updateUI(){
        getStatistics()
        const params = searchParams.toString()
        getPromotions(params)
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Promotions - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <div className="mb-3">
                                    <PromotionsSummary statistics={statistics}/>
                                </div>
                                <div className="mb-3">
                                    <PromotionsTable
                                        getPromotions={getPromotions}
                                        promotions={promotions}
                                        updateUI={updateUI}
                                        changePageParam={changePageParam}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default PromotionsPage