import React, { useState } from 'react'
import Loading from '../feedback/Loading'
import toast from 'react-hot-toast'
import subscriptionServices from '../../services/SubscriptionService'
import utils from '../../utils'

function PeriodNameModal(props) {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')

    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        const data = {
            "name": name
        }
        const createToast = toast.loading("Creating Period Name")
        subscriptionServices.addPeriodName(data).then(
            res => {
                setName('')
                toast.success("Successfully created Period Name", {
                    id:createToast
                })
                setLoading(false)
                props.getPeriodNames()
                closeModal()
            }, 
            err => {
                console.log(err);
                setLoading(false)
                toast.error(utils.toasterError, {
                    id:createToast
                })
            }
        )
    }
    function closeModal(){
        const closeBtn = document.getElementById("period-add-modal-close-btn")
        closeBtn.click()
    }
    return (
        <div>
            <label htmlFor="period-add-modal" id='period-add-open-btn' className="hidden">Add Period Name</label>
            <input type="checkbox" id="period-add-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-7/12 max-w-5xl">
                    <label htmlFor="period-add-modal" id='period-add-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-6'>Add Billing Period Name</h2>
                    <form onSubmit={submitHandler}>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Name *</span>
                            </div>
                            <input 
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text" 
                                required 
                                className='input input-bordered' 
                                placeholder='E.g Monthly, Yearly, Quaterly' />
                        </div>
                        <div className='modal-action flex justify-end gap-3'>
                            {loading ? (<Loading/>):(
                                <>
                                    <div 
                                        onClick={() => closeModal()}
                                        className='btn btn-ghost text-accent'>Cancel</div>
                                    <button type='submit' className='btn text-white btn-accent'>Save</button>
                                </>
                            )}                                
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PeriodNameModal