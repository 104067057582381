/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import expertsServices from '../../services/ExpertsServices'
import { BiSort } from "react-icons/bi";
import ExpertServicesTab from './ExpertServicesTab';
import { useSearchParams } from 'react-router-dom';
import servicesService from '../../services/ServicesService';
import Loading from '../feedback/Loading';
import FetchError from '../feedback/FetchError';

import Select from 'react-select';

const options = [
    { value: 'none', label: 'Order By' },
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
]

function ExpertGigs(props) {
    const [services, setServices] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [statistics, setStatistics] = useState(null)
    const [activeTab, setActiveTab] = useState(0)
    const expert = props.expert
    const [searchParams, setSearchParams] = useSearchParams()
    const orderingParam = searchParams.get("ordering")
    const [selectedValue, setSelectedValue] = useState(null);
    const [hasFiltered, setHasFiltered] = useState(false)

    useEffect(() => {
        if (expert?.id) {
            getExpertStats(expert?.id)
            setSearchParams((params) => {
                params.set("responder__id", expert?.id)
                return params
            })
        }
    }, [expert, setSearchParams])
    useEffect(() => {}, [services])
    useEffect(() => {
        if (activeTab === 0) {
        }
        if (activeTab === 1) {
            setSearchParams((params) => {
                params.set("status", "Active")
                return params
            })
        }
        if (activeTab === 2){
            setSearchParams((params) => {
                params.set("status", "Finished")
                return params
            })
        }
        if (activeTab === 3){
            setSearchParams((params) => {
                params.set("status", "Cancelled")
                return params
            })
        }
    }, [activeTab, setSearchParams])
    useEffect(() => {}, [statistics])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getExpertServices(params ? `?${params}` : '')
        }
    }, [searchParams])

    const getExpertStats = (id) => {
        expertsServices.getSpecificExpertStatistics(id).then(
            res => {
                setStatistics(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }

    const getExpertServices = (params) => {
        setLoading(true)
        setError()
        servicesService.getServicesList(params ? `${params}` : '').then(
            res => {
                setServices(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }

    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }

    useEffect(() => {
        if (orderingParam && (!hasFiltered)) {
            if (orderingParam === 'creation_time') {
                const defaultOption = options.find(option => option.value === 'asc')
                setSelectedValue(defaultOption)
            } else if (orderingParam === '-creation_time') {
                const defaultOption = options.find(option => option.value === 'desc')
                setSelectedValue(defaultOption)
            }
        }
    }, [orderingParam, hasFiltered])
    useEffect(() => {
        if (!orderingParam) {
            const defaultOption = options.find(option => option.value === 'none')
            setSelectedValue(defaultOption);
        }
    }, [orderingParam])
    const handleChange = (selectedOption) => {
        setHasFiltered(true)
        setSelectedValue(selectedOption);
    };
    useEffect(() => {
        if (selectedValue) {
            if (selectedValue?.value === 'asc') {
                setSearchParams((params) => {
                    params.set("ordering", 'creation_time')
                    return params
                })
            }else if (selectedValue?.value === 'desc') {
                setSearchParams((params) => {
                    params.set("ordering", '-creation_time')
                    return params
                })
            } else if (selectedValue?.value === 'none') {
                setSearchParams((params) => {
                    params.delete("ordering")
                    return params
                })
            }
        }
    }, [selectedValue, setSearchParams])
    function changeTab(val){
        const allParams = [...searchParams.entries()]
        const remove = allParams.filter((param) => param[0] !== 'owner__id')
        if (remove.length) {
            remove.forEach(element => {
                setSearchParams((params) => {
                    params.delete(element[0])
                    return params
                })
            });
        } 
        setActiveTab(val)
    }
    return (
        <div className="rounded-md border pb-4 p-2">
            <div role="tablist" className="tabs tabs-bordered mb-6">
                <a 
                    onClick={() => changeTab(0)}
                    role="tab" 
                    className={activeTab === 0 ? 'tab tab-active':'tab'}>
                    <span className='mr-2'>All</span>
                    {statistics && (
                        <span>( {statistics?.all_services} )</span>
                    )}
                </a>
                <a 
                    onClick={() => changeTab(1)}
                    role="tab" 
                    className={activeTab === 1 ? 'tab tab-active':'tab'}>
                    <span className='mr-2'>Ongoing</span>
                    {statistics && (
                        <span>( {statistics?.ongoing_services} )</span>
                    )}
                </a>
                <a 
                    onClick={() => changeTab(2)}
                    role="tab" 
                    className={activeTab === 2 ? 'tab tab-active':'tab'}>
                    <span className='mr-2'>Complete</span>
                    {statistics && (
                        <span>( {statistics?.completed_services} )</span>
                    )}
                </a>
                <a 
                    onClick={() => changeTab(3)}
                    role="tab" 
                    className={activeTab === 3 ? 'tab tab-active':'tab'}>
                    <span className='mr-2'>Cancelled</span>
                    {statistics && (
                        <span>( {statistics?.cancelled_services} )</span>
                    )}
                </a>
            </div>
            <div className="flex gap-6 items-center">
                <div className='flex items-center gap-3'>
                    <BiSort className='w-8 h-8' color='#42B883'/>
                    <Select
                        className='select select-ghost'
                        value={selectedValue}
                        onChange={handleChange}
                        options={options}
                    />
                </div>
            </div>
    
            {loading ? (
                <div className='flex items-center justify-center h-44'>
                    <Loading/>
                </div>
            ):(
                <div>
                    {error && (<FetchError error={error}/>)}
                    {services ? (
                        <ExpertServicesTab
                            services={services}
                            changeLimitParam={changeLimitParam}
                            changePageParam={changePageParam}
                            activeTab={activeTab}/>
                    ):null}
                </div>
            )}
        </div>
    )
}

export default ExpertGigs