import React, { useEffect } from 'react'

function PromotionSideBar(props) {
    const currentTab = props.currentTab
    const setCurrentTab = props.setCurrentTab

    useEffect(() => {}, [currentTab])
    return (
        <div className='sticky top-1'>
            <div 
                onClick={() => setCurrentTab(0)}
                className={
                    currentTab === 0 
                    ? 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                    : 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                }>
                Promotion Details
            </div>
            <div
                onClick={() => setCurrentTab(1)}
                className={
                    currentTab === 1 
                    ? 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                    : 
                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                }>
                Participants
            </div>
        </div>
    )
}

export default PromotionSideBar