import React from 'react'
import hero from '../../assets/splash_img.jpeg'
import logo from '../../assets/logo_white.png'

function Hero() {
    return (
        <div className='hidden md:flex md:w-6/12 lg:w-7/12'>
            <div className="hero h-screen" style={{background: `linear-gradient(180deg, rgba(6, 74, 203, 0.85) 0%, rgba(66, 184, 131, 0.85) 100%), url(${hero}), lightgray -486.583px 1.617px / 273.602% 100%`}}>
                <div className="hero-content text-center">
                    <div className="">
                        <div className="flex justify-center">
                            <img src={logo} alt="Towgig logo" />
                        </div>
                        <h1 className="text-lg font-bold text-white">TowGig</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero