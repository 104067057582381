import React, { useEffect } from 'react'
import Empty from '../feedback/Empty'

function BuyerVehicles(props) {
    const user = props.user
    useEffect(() => {}, [user])
    return (
        <div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Vehicles</p>
                </div>
                <div className='p-2'>
                    {user?.owner_vehicles ? (
                        <>
                            <div className="overflow-x-auto mb-6">
                                <table className="table table-zebra">
                                    <thead>
                                        <tr>
                                            <td>Registation Number</td>
                                            <td>Vehicle Type</td>
                                            <td>Vehicle Brand</td>
                                            <td>Vehicle Color</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {user?.owner_vehicles?.map((vehicle, idx) => (
                                            <tr
                                                key={idx}>
                                                <td>{vehicle?.reg_number}</td>
                                                <td>{vehicle?.vehicle_category?.name}</td>
                                                <td>{vehicle?.vehicle_brand}</td>
                                                <td>{vehicle?.vehicle_color?.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {user?.owner_vehicles?.length < 1 && (
                                <Empty objectName={"Vehicles"}/>
                            )}
                        </>
                    ):(
                        <span>
                            <p>User Has not Saved any vehicles yet.</p>
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BuyerVehicles