import React, { useEffect } from 'react'
import utils from '../../utils'
import { IoMdTrendingUp } from "react-icons/io"
import { GrUserWorker } from "react-icons/gr"
import { BiBriefcase } from "react-icons/bi";
import { AiOutlineGift } from "react-icons/ai"
import { BiUser } from "react-icons/bi"

function DashboardSummary(props) {
    const statistics = props.statistics
    useEffect(() => {}, [statistics])
    return (
        <div className='p-3'>
            {statistics && (
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3'>
                    <div className='border rounded-md gap-2'>
                        <div className="flex items-center p-5 gap-2 justify-between">
                            <div className="grow">
                                <p className='font-bold text-2xl'>{utils.toCommas(statistics?.active_buyers)}</p>
                                <p className='uppercase text-gray-500 font-bold text-lg'>Active Buyers</p>
                            </div>
                            <figure className='border p-2 rounded-md grow-0' style={{borderColor: "#42B883"}}>
                                <BiUser className='w-10 h-10' style={{color: "#42B883"}}/>
                            </figure>
                        </div>
                        <div className='flex gap-2 pb-5 pl-3 items-center'>
                            <IoMdTrendingUp color='#42B883'/>
                            <p className='' style={{color: "#42B883"}} >{statistics?.active_buyers_today} new today</p>
                        </div>
                    </div>
                    <div className='border rounded-md gap-2'>
                        <div className="flex items-center p-5 gap-2 justify-between">
                            <div className="grow">
                                <p className='font-bold text-2xl'>{utils.toCommas(statistics?.active_experts)}</p>
                                <p className='uppercase text-gray-500 font-bold text-lg'>Active Experts</p>
                            </div>
                            <figure className='border p-2 rounded-md grow-0' style={{borderColor: "#42B883"}}>
                                <GrUserWorker className='w-10 h-10' style={{color: "#42B883"}}/>
                            </figure>
                        </div>
                        <div className='flex gap-2 pb-5 pl-3 items-center'>
                            <IoMdTrendingUp color='#42B883'/>
                            <p className='' style={{color: "#42B883"}} >{statistics?.active_experts_today} new today</p>
                        </div>
                    </div>
                    <div className='border rounded-md gap-2'>
                        <div className="flex items-center p-5 gap-2 justify-between">
                            <div className="grow">
                                <p className='font-bold text-2xl'>{utils.toCommas(statistics?.active_gigs)}</p>
                                <p className='uppercase text-gray-500 font-bold text-lg'>Active Gigs</p>
                            </div>
                            <figure className='border p-2 rounded-md grow-0' style={{borderColor: "#42B883"}}>
                                <BiBriefcase className='w-10 h-10' style={{color: "#42B883"}}/>
                            </figure>
                        </div>
                        <div className='flex gap-2 pb-5 pl-3 items-center'>
                            <IoMdTrendingUp color='#42B883'/>
                            <p className='' style={{color: "#42B883"}} >{statistics?.active_gigs_today} new today</p>
                        </div>
                    </div>
                    <div className='border rounded-md gap-2'>
                        <div className="flex items-center p-5 gap-2 justify-between">
                            <div className="grow">
                                <p className='font-bold text-2xl'>{utils.toCommas(statistics?.completed_gigs)}</p>
                                <p className='uppercase text-gray-500 font-bold text-lg'>Completed Gigs</p>
                            </div>
                            <figure className='border p-2 rounded-md grow-0' style={{borderColor: "#42B883"}}>
                                <AiOutlineGift className='w-10 h-10' style={{color: "#42B883"}}/>
                            </figure>
                        </div>
                        <div className='flex gap-2 pb-5 pl-3 items-center'>
                            <IoMdTrendingUp color='#42B883'/>
                            <p className='' style={{color: "#42B883"}} >{statistics?.completed_gigs_today} new today</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DashboardSummary