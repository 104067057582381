import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import UnderConstruction from '../../components/feedback/UnderConstruction'

function SettingsPage() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Settings - {utils.siteName}</title>
            </Helmet>
            <div className='p-3'>
                <UnderConstruction/>
            </div>
        </HelmetProvider>
    )
}

export default SettingsPage