import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import Hero from '../../components/auth/Hero'
import ResetPasswordForm from '../../components/auth/ResetPasswordForm'

function ResetPassPage() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Reset Password - {utils.siteName}</title>
            </Helmet>
            <div className='m-0 md:flex'>
                <Hero/>
                <div className="h-screen flex items-center justify-center w-100 md:w-6/12 lg:w-5/12">
                    <ResetPasswordForm/>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default ResetPassPage