import React, { useContext, useRef, useState } from 'react'
import { MdMailOutline } from "react-icons/md"
import { FiLock } from "react-icons/fi"
import { Link, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import authServices from '../../services/AuthServices';
import UserContext from '../../context/UserContext';
import utils from '../../utils';
import Loading from '../feedback/Loading';

function LoginForm() {
    const [loading, setLoading] = useState(false)
    const userCtx = useContext(UserContext)
    const emailRef = useRef()
    const passwordRef = useRef()
    const navigate = useNavigate()

    function submitHandler(e){
        setLoading(true)
        e.preventDefault()
        const loginToast = toast.loading("Signing in to your account")
        const data = {
            "email": emailRef.current.value,
            "password": passwordRef.current.value
        }
        authServices.login(data).then(
            res => {
                userCtx.setCredentials(res.data)
                authServices.getUserDetails().then(
                    res => {
                        userCtx.setUser(res.data)
                        toast.success("Finished", {
                            id:loginToast
                        })
                        setLoading(false)
                        navigate('/')
                    },
                    err => {
                        setLoading(false)
                        console.log(err);
                        toast.error(utils.toasterError, {
                            id:loginToast
                        })
                    }
                )
            },
            err => {
                setLoading(false)
                if (err?.response?.data?.detail) {
                    toast.error(err?.response?.data?.detail, {
                        id:loginToast
                    })
                } else{
                    toast.error(utils.toasterError, {
                        id:loginToast
                    })
                }

            }
        )
    }
    return (
        <div className='w-9/12 md:w-7/12'>
            <p className="text-lg md:text-2xl font-bold text-primary mb-6 md:mb-6">Sign in to your account</p>
            <form onSubmit={submitHandler}>
                <p className='text-lg font-bold text-primary mb-3 md:mb-4'>Enter your Email</p>
                <div className="flex space-x-3 md:space-x-4 py-4 px-3 border rounded-sm mb-4 items-center">
                    <MdMailOutline className='h-7 w-7 text-accent' style={{color: "#42B883"}}/>
                    <input ref={emailRef} type='email' required name='email' className="input w-full" placeholder="Email"/>
                </div>
                <div className="flex space-x-4 py-4 px-3 border rounded-sm mb-4 items-center">
                    <FiLock className='h-7 w-7 text-accent' style={{color: "#42B883"}}/>
                    <input type='password' ref={passwordRef} required name='password' className='input w-full' placeholder='Password'/>
                </div>
                <div className="flex justify-end mb-6 md:mb-9">
                    <Link to='/auth/reset/password'><p className='text-accent underline'>Forgot Password?</p></Link>
                </div>
                <div className="flex justify-center">
                    {loading ? (<Loading/>):(
                        <button type='submit' style={{borderRadius: '0.8em'}} className='btn-accent text-white btn btn-lg w-full rounded-md'>Continue</button>
                    )} 
                </div>
            </form>
        </div>
    )
}

export default LoginForm