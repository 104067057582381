import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import NavBar from './components/nav/NavBar';
import DashboardPage from './pages/DashboardPage';
import PageNotFound from './pages/PageNotFound';
import SideBar from './components/nav/SideBar';
import LoginPage from './pages/auth/LoginPage';
import ResetPassPage from './pages/auth/ResetPassPage';
import SettingsPage from './pages/auth/SettingsPage';
import ExpertsPage from './pages/experts/ExpertsPage';
import BuyersPage from './pages/buyers/BuyersPage';
import FinancePage from './pages/payments/FinancePage';
import SubscriptionPage from './pages/payments/SubscriptionPage';
import PromotionsPage from './pages/payments/PromotionsPage';
import { useContext, useEffect } from 'react';
import UserContext from './context/UserContext';
import ProtectedRoutes from './ProtectedRoutes';
import { Toaster } from 'react-hot-toast';
import utils from './utils';
import SessionContext from './context/SessionContext';
import SubscriptionViewPage from './pages/payments/SubscriptionViewPage';
import ExpertViewPage from './pages/experts/ExpertViewPage';
import BuyerViewPage from './pages/buyers/BuyerViewPage';
import ManageServicesPage from './pages/services/ManageServicesPage';
import OngoingServicesPage from './pages/services/OngoingServicesPage';
import CompetedServicesPage from './pages/services/CompetedServicesPage';
import CancelledServicesPage from './pages/services/CancelledServicesPage';
import ScheduledServicesPage from './pages/services/ScheduledServicesPage';
import ServiceViewPage from './pages/services/ServiceViewPage';
import PromotionViewPage from './pages/payments/PromotionViewPage';
import ServiceChargeViewPage from './pages/services/ServiceChargeViewPage';
import ApplicationsPage from './pages/experts/ApplicationsPage';
import ApplicationsViewPage from './pages/experts/ApplicationsViewPage';

function App() {
  const sessionCtx = useContext(SessionContext)
  const hasCheckedAuth = sessionCtx.hasCheckedAuth
  const userCtx = useContext(UserContext)
  const isAuth = userCtx.isAuth
  const refreshTime = userCtx.refreshTime
  const navigate = useNavigate()

  useEffect(() => {}, [isAuth])
  useEffect(() => {
    if (!hasCheckedAuth && isAuth) {
      // check difference btn now and the last tkn refresh time
      const refreshDate = new Date(refreshTime)
      const now = new Date()
      const difference = now  - refreshDate
      const dayDifference = difference / (1000 * 60 * 60 * 24)

      // log out user if the day difference is > 5 days
      if (dayDifference > 4){
        userCtx.logout()
        navigate('/auth/login')
        console.log(dayDifference);
      }
    }
  }, [hasCheckedAuth, isAuth, navigate, refreshTime, userCtx])
  return (
    <div className="App">
      <div className={isAuth ? "drawer lg:drawer-open" : "drawer"} >
        <input id="nav-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col">
          
          {isAuth && (<NavBar/>)}

          <Routes>
            <Route 
              path='/' 
              element={<ProtectedRoutes><DashboardPage/></ProtectedRoutes>}/>

            <Route path='/auth/login' element={<LoginPage/>}/>

            <Route path='/auth/reset/password' element={<ResetPassPage/>}/>

            <Route 
              path='/auth/settings' 
              element={<ProtectedRoutes><SettingsPage/></ProtectedRoutes>}/>

            <Route 
              path='/applications' 
              element={<ProtectedRoutes><ApplicationsPage/></ProtectedRoutes>}/>

            <Route 
              path='/applications/:id' 
              element={<ProtectedRoutes><ApplicationsViewPage/></ProtectedRoutes>}/>

            <Route 
              path='/experts' 
              element={<ProtectedRoutes><ExpertsPage/></ProtectedRoutes>}/>

            <Route 
              path='/experts/:id'
              element={<ProtectedRoutes><ExpertViewPage/></ProtectedRoutes>}/>

            <Route 
              path='/buyers' 
              element={<ProtectedRoutes><BuyersPage/></ProtectedRoutes>}/>

            <Route
              path='/buyers/:id'
              element={<ProtectedRoutes><BuyerViewPage/></ProtectedRoutes>}/>

            <Route 
              path='/finance' 
              element={<ProtectedRoutes><FinancePage/></ProtectedRoutes>}/>

            <Route 
              path='/subscriptions' 
              element={<ProtectedRoutes><SubscriptionPage/></ProtectedRoutes>}/>

            <Route 
              path='/subscriptions/:id' 
              element={<ProtectedRoutes><SubscriptionViewPage/></ProtectedRoutes>}/>

            <Route
              path='/service/:id'
              element={<ProtectedRoutes><ServiceViewPage/></ProtectedRoutes>}
            />

            <Route
              path='/services/manage'
              element={<ProtectedRoutes><ManageServicesPage/></ProtectedRoutes>}
            />

            <Route
              path='/services/cancelled'
              element={<ProtectedRoutes><CancelledServicesPage/></ProtectedRoutes>}
            />

            <Route
              path='/services/completed'
              element={<ProtectedRoutes><CompetedServicesPage/></ProtectedRoutes>}
            />

            <Route
              path='/services/ongoing'
              element={<ProtectedRoutes><OngoingServicesPage/></ProtectedRoutes>}
            />

            <Route
              path='/services/scheduled'
              element={<ProtectedRoutes><ScheduledServicesPage/></ProtectedRoutes>}
            />

            <Route
              path='/services/manage/charges/:id'
              element={<ProtectedRoutes><ServiceChargeViewPage/></ProtectedRoutes>}
            />

            <Route 
              path='/promotions' 
              element={<ProtectedRoutes><PromotionsPage/></ProtectedRoutes>}/>

            <Route 
              path='/promotions/:id' 
              element={<ProtectedRoutes><PromotionViewPage/></ProtectedRoutes>}/>

            <Route path='*' exact={true} element={<PageNotFound/>}/>
          </Routes>
          <Toaster
            toastOptions={utils.toasterOptions}
            position={utils.toasterPosition}
          />
        </div>
        <SideBar/>
      </div>
    </div>
  );
}

export default App;
