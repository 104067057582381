import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import promotionsServices from '../../services/PromotionsServices'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import { TfiLayoutGrid3 } from "react-icons/tfi";
import gift from '../../assets/amico.png'
import PromotionSideBar from '../../components/promotions/PromotionSideBar'
import PromotionDetails from '../../components/promotions/PromotionDetails'
import PromotionParticipants from '../../components/promotions/PromotionParticipants'
import PromotionUpdateModal from '../../components/promotions/PromotionUpdateModal'

function PromotionViewPage() {
    const [promotion, setPromotion] = useState(null)
    const [loading, setLoading] = useState(true)
    const [currentTab, setCurrentTab] = useState(0)
    const [error, setError] = useState(null)
    const idParam = useParams()

    useEffect(() => {
        if (idParam?.id) {
            getPromotion(idParam?.id)
        }
    }, [idParam])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [promotion])
    useEffect(() => {}, [currentTab])

    const getPromotion = (id) => {
        setError(null)
        setLoading(true)
        promotionsServices.getPromotion(id).then(
            res => {
                setPromotion(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function openCreateModal(){
        const openBtn = document.getElementById('promotion-update-open-btn')
        openBtn.click()
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Promotion View - {utils.siteName}</title>
            </Helmet>
            <div className='p-2'>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>): null}
                        {promotion ? (
                            <div>
                                <PromotionUpdateModal
                                    promotion={promotion}
                                />
                                <div className='flex justify-end mb-3'>
                                    <div>
                                        <div className="dropdown">
                                            <label tabIndex="0" className="flex items-center gap-3 btn btn-ghost text-accent">
                                                <TfiLayoutGrid3 className='h-6 w-6'/>
                                                <p className='font-bold'>Actions</p>
                                            </label>
                                            <ul 
                                                tabIndex="0"
                                                className="menu menu-vertical dropdown-content mt-3 shadow bg-base-200 rounded-box text-start">
                                                <li 
                                                    onClick={() => openCreateModal()}
                                                    className='p-2 hover:cursor-pointer'>Update Promotion</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center mb-6">
                                    <img src={gift} alt='icon'/>
                                </div>
                                <div className="flex justify-center gap-3 items-center mb-6">
                                    <div className='text-lg font-bold'>
                                        <p>{promotion?.title}</p>
                                    </div>
                                </div>
                                <div className='md:flex gap-3'>
                                    <div className="md:w-4/12 lg:w-3/12 rounded-md">
                                        <PromotionSideBar
                                            currentTab={currentTab}
                                            setCurrentTab={setCurrentTab}
                                        />
                                    </div>
                                    <div className="md:w-8/12 lg:w-9/12">
                                        {currentTab === 0 && (
                                            <PromotionDetails
                                                promotion={promotion}/>
                                        )}
                                        {currentTab === 1 && (
                                            <PromotionParticipants
                                                promotion={promotion}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ):null}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default PromotionViewPage