import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import { useParams } from 'react-router-dom'
import servicesService from '../../services/ServicesService'
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { GiTowTruck } from "react-icons/gi";
import ServiceTypeOverview from '../../components/services/ServiceTypeOverview'
import ServiceTypeGigs from '../../components/services/ServiceTypeGigs'
import ServiceTypeExperts from '../../components/services/ServiceTypeExperts'
import EditServiceChargeModal from '../../components/serviceCharges/EditServiceChargeModal'

function ServiceChargeViewPage() {
    const [charge, setCharge] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const idParam = useParams()
    const [currentTab, setCurrentTab] = useState(0)
    const [activeCharge, setActiveCharge] = useState()
    const [vehicleRanges, setVehicleRanges] = useState([])
    const [startingCosts, setStatingCosts] = useState([])
    
    useEffect(() => {
        if (idParam?.id) {
            getCharge(idParam?.id)
        }
    }, [idParam])
    useEffect(() => {
        if (charge) {
            getVehicleRangePercentage(charge?.service_type?.id)
            getStartingCosts(charge?.service_type?.id)
        }
    }, [charge])
    useEffect(() => {}, [currentTab])
    useEffect(() => {}, [activeCharge])
    useEffect(() => {}, [vehicleRanges])
    useEffect(() => {}, [startingCosts])

    const getCharge = (id) => {
        servicesService.getParticularServiceCharge(id).then(
            res => {
                setCharge(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    const getVehicleRangePercentage = (id) => {
        servicesService.getVehicleRangesPercentage(`?service_type__id=${id}`).then(
            res => {
                setVehicleRanges(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }
    const getStartingCosts = (id) => {
        servicesService.getStartingCosts(`?service_type__id=${id}`).then(
            res => {
                setStatingCosts(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }

    function openModal(charge){
        setActiveCharge(charge)
        const openBtn = document.getElementById("service-charge-create-open-btn")
        openBtn.click()
    }
    function updateStartingCosts(){
        getStartingCosts(charge?.service_type?.id)
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Service Charge - {utils.siteName}</title>
            </Helmet>
            {loading ? (
                <div className='flex items-center justify-center h-44'>
                    <Loading/>
                </div>
            ):(
                <div>
                    {error ? (<FetchError error={error}/>):(
                        <div className='p-3'>
                            <Helmet>
                                <title>{charge?.service_type?.name} - {utils.siteName}</title>
                            </Helmet>
                            <EditServiceChargeModal
                                activeCharge={activeCharge}
                                setCharge={setCharge}
                                vehicleRanges={vehicleRanges}
                                setVehicleRanges={setVehicleRanges}
                                startingCosts={startingCosts}
                                updateStartingCosts={updateStartingCosts}
                            />
                            <div className='rounded-md border p-5 py-7 mb-3'>
                                <div className="flex justify-between items-center">
                                    <div>
                                        <div className='flex gap-4 items-center'>
                                        <div className=' rounded-full p-4 shadow-md hover:shadow-xl'>
                                            <GiTowTruck className=' h-12 w-12' style={{color: "#42B883"}}/>
                                        </div>
                                            <div>
                                                <p className='font-bold'>{charge?.service_type?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="dropdown">
                                            <label tabIndex="0" className="flex items-center gap-3 btn btn-ghost text-accent">
                                                <TfiLayoutGrid3 className='h-6 w-6'/>
                                                <p className='font-bold'>Actions</p>
                                            </label>
                                            <ul 
                                                tabIndex="0"
                                                className="menu menu-vertical dropdown-content mt-3 shadow bg-base-200 rounded-box text-start">
                                                <li className='p-2 hover:cursor-pointer'>
                                                    <span
                                                        onClick={() => openModal(charge)} 
                                                        className=' text-nowrap'>Edit Service</span>
                                                </li>
                                                <li className='p-2 hover:cursor-pointer'>
                                                    <span className='text-rose-600 text-nowrap'>Delete Service</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='md:flex gap-5'>
                                <div className="md:w-4/12 lg:w-3/12 rounded-md">
                                    <div className="join join-vertical w-full">
                                        <div 
                                            onClick={() => setCurrentTab(0)}
                                            className={
                                                currentTab === 0 
                                                ? 
                                                'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                                                : 
                                                'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                                            }>
                                            Service Overview
                                        </div>
                                        <div
                                            onClick={() => setCurrentTab(1)}
                                            className={
                                                currentTab === 1 
                                                ? 
                                                'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                                                : 
                                                'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                                            }>
                                            Gigs
                                        </div>
                                        <div
                                            onClick={() => setCurrentTab(2)}
                                            className={
                                                currentTab === 2
                                                ? 
                                                'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                                                : 
                                                'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                                            }>
                                            Experts
                                        </div>
                                    </div>
                                </div>
                                <div className="md:w-8/12 lg:w-9/12">
                                    {currentTab === 0 ? (
                                        <ServiceTypeOverview
                                            charge={charge}
                                        />
                                    ): null}
                                    {currentTab === 1 ? (
                                        <ServiceTypeGigs
                                            charge={charge}
                                        />
                                    ): null}
                                    {currentTab === 2 ? (
                                        <ServiceTypeExperts
                                            charge={charge}
                                        />
                                    ): null}
                                </div>
                            </div>
                        </div>
                    )} 
                </div>
            )}

        </HelmetProvider>
    )
}

export default ServiceChargeViewPage