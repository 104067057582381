import React, { useEffect, useState } from 'react'
import expertsServices from '../../services/ExpertsServices'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import Empty from '../feedback/Empty'
import utils from '../../utils'
import { AiOutlineUser } from "react-icons/ai"
import { GrUserWorker } from "react-icons/gr"
import { FaArrowRightLong } from "react-icons/fa6";
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Pagination from '../nav/Pagination'

function ExpertReviews(props) {
    const [reviews, setReviews] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [searchParams, setSearchParams] = useSearchParams()
    const expert = props.expert
    const navigate = useNavigate()

    useEffect(() => {
        if (expert?.id) {
            const params = searchParams.toString()
            getReviews(expert?.id, params)
        }
    }, [expert, searchParams])
    useEffect(() => {}, [reviews])
    useEffect(() => {}, [error])
    
    const getReviews = (id, params) => {
        setError(null)
        setLoading(true)
        expertsServices.getExpertReviews(id, params ? `?${params}` : '').then(
            res => {
                setReviews(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }

    function openService(review){
        if (review?.service_request) {
            toast.success("Opening service")
            navigate(`/service/${review?.service_request?.id}`)
        } else{
            toast.error("Service Request not Attached")
        }
    }
    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    return (
        <div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Reviews</p>
                </div>
                <div className='p-2 space-y-3'>
                    {loading ? (
                        <div className='flex items-center justify-center h-44'>
                            <Loading/>
                        </div>
                    ):(
                        <div>
                            {error && (<FetchError error={error}/>)}
                            {reviews?.results?.map((review, idx) => (
                                <div
                                    key={idx}
                                    className='bg-base-200 p-2 mb-3'>
                                    <div className="flex items-center">
                                        <div className='flex-grow'>
                                            <div className="flex gap-3">
                                                {review?.rated_by && (
                                                    <div className='avatar mb-3'>
                                                        {utils.checkImage(review?.owner) ? (
                                                            <div className="w-12 rounded-full">
                                                                <img 
                                                                    src={review?.rated_by?.image} 
                                                                    alt={review?.rated_by?.email} />
                                                            </div>
                                                        ):(
                                                            <div>
                                                                <div className="avatar placeholder">
                                                                    <div className="bg-neutral text-neutral-content rounded-full w-12">
                                                                        <span className="text-xl">{utils.makeProfileLetters(review?.rated_by)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                <div>
                                                    <div className="flex gap-3 items-center">
                                                        <p className='text-gray-500'>
                                                            {review?.rated_by.full_name ? (
                                                                <span>{review?.rated_by?.full_name}</span>
                                                            ):(
                                                                <span>{review?.rated_by?.email}</span>
                                                            )}
                                                        </p>
                                                        {review?.rated_by?.is_buyer ? (
                                                            <AiOutlineUser className='h-7 w-5 text-accent font-bold'/>
                                                        ): null}
                                                        {review?.rated_by?.is_expert ? (
                                                            <GrUserWorker className='h-7 w-5 text-accent font-bold'/>
                                                        ): null}
                                                    </div>                                                    
                                                    <p>Ratings: {review?.ratings}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex-grow-0'>
                                            <div 
                                                onClick={() => openService(review)}
                                                className="flex btn btn-ghost text-accent items-center">
                                                <p>View Gig</p>
                                                <FaArrowRightLong className='h-4 w-4'/>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {review?.text}
                                    </div>
                                </div>
                            ))}
                            <div className="border-t p-3">
                                {((reviews) && (reviews?.count > 0)) && (
                                    <Pagination
                                        count={reviews?.count}
                                        next={reviews?.next}
                                        previous={reviews?.previous}
                                        changeLimitParam={changeLimitParam}
                                        changePageParam={changePageParam}
                                    />
                                )}
                            </div>
                            {reviews?.count < 1 && (
                                <Empty objectName={"Reviews"}/>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ExpertReviews