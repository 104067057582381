import React, { useEffect, useState } from 'react'
import { IoSettingsOutline } from "react-icons/io5";
import utils from '../../utils';
import { Link } from 'react-router-dom';

function Subscription(props) {
    const [monthPlan, setMonthPlan] = useState()
    const subscription= props.subscription
    const billingPeriods = props.billingPeriods

    useEffect(() => {
        if (subscription && (billingPeriods?.length)) {
            const subscriptionPlan = utils.getMonthlyPlan(subscription, billingPeriods)
            const monthlyPlan = subscriptionPlan.filter(plan => plan?.period_name?.name.toLowerCase() === "monthly")
            setMonthPlan(monthlyPlan)
        }
    }, [subscription, billingPeriods])
    useEffect(() => {}, [monthPlan])
    return (
        <div className='bg-base-200 rounded-md card'>
            <div className="card-body space-y-5">
                <div className='flex items-center gap-5 rounded-md justify-between'>
                    <div 
                        style={{background: `linear-gradient(106deg, ${subscription?.hex_code_one}, ${subscription?.hex_code_two})`}} 
                        className="w-4/12 rounded-md h-full">
                    </div>
                    <div className='w-8/12 py-2'>
                        <p className='font-bold text-lg'>{subscription?.name}</p>
                        {monthPlan?.length ? (
                            <p className='text-3xl'>{monthPlan[0]?.amount} / {monthPlan[0]?.period_name?.name}</p>
                        ):(
                            <span className='text-2xl'>Add Billings</span>
                        )}
                    </div>
                </div>
                <div>
                    <p className="card-title text-2xl mb-1">Benefits</p>
                    <p>{subscription?.discount} % Off discount</p>
                    <p>Share with up to {subscription?.usage_count} People</p>
                </div>
                <div className="card-actions justify-center">
                    <Link 
                        to={`/subscriptions/${subscription?.id}`}
                        className='btn w-full btn-accent btn-outline'><IoSettingsOutline/> Manage</Link>
                </div>
            </div>
        </div>
    )
}

export default Subscription