import apiClient from './AxiosHttp'

const api = '/api/v1'

const getFinanceSummary = () => {
    return apiClient.get(`${api}/dashboard/finance/summary/`)
}

const getFinanceStatistics = () => {
    return apiClient.get(`${api}/dashboard/finance/statistics/`)   
}

const getRecentTransactions = (params) => {
    return apiClient.get(`${api}/dashboard/finances/services/${params}`)
}

const getSubscriptionTransactions = (params) => {
    return apiClient.get(`${api}/dashboard/finances/subscriptions/${params}`)
}

const getCashPayments= (params) => {
    return apiClient.get(`${api}/dashboard/finances/cash/${params}`)
}

const updatePayment = (id, details) => {
    return apiClient.patch(`${api}/dashboard/payment/modify/${id}/`, details)
}

const getPendingPayments = (params) => {
    return apiClient.get(`${api}/dashboard/finances/pending/${params}`)
}

const financeServices = {
    getFinanceSummary,
    getFinanceStatistics,
    getRecentTransactions,
    getSubscriptionTransactions,
    getCashPayments,
    updatePayment,
    getPendingPayments,
}

export default financeServices