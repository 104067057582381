import React, { useEffect, useState } from 'react'
import expertsServices from '../../services/ExpertsServices'
import FetchError from '../feedback/FetchError'
import Empty from '../feedback/Empty'
import Loading from '../feedback/Loading'
import utils from '../../utils'
import { SlFrame } from "react-icons/sl";
import { BiSolidEditAlt } from "react-icons/bi"


function ExpertEmployees(props) {
    const [employees, setEmployees] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const expert = props.expert

    useEffect(() => {
        if (expert?.owner_service?.id) {
            getEmployees(expert?.owner_service?.id)
        } else{
            setLoading(false)
        }
    }, [expert])
    useEffect(() => {}, [employees])
    useEffect(() => {}, [error])
    useEffect(() => {}, [loading])

    const getEmployees = (id) => {
        setError()
        setLoading(true)
        expertsServices.getServiceEMployees(id).then(
            res => {
                console.log(res.data);
                setEmployees(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    return (
        <div className="rounded-md border pb-5 mb-6">
            <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                <p className="font-bold text-lg">Added Employees</p>
            </div>
            <div className='p-2'>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div> 
                ):(
                    <div>
                        {expert?.owner_service ? (
                            <div>
                                {error && (<FetchError error={error}/>)}
                                {employees && (
                                    <div className="overflow-x-auto mb-6">
                                        <table className="table table-zebra">
                                            <thead>
                                                <tr>
                                                    <td>User</td>
                                                    <td>Service Offered</td>
                                                    <td>Serviced Gigs</td>
                                                    <td>View</td>
                                                    <td>Edit</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {employees?.map((employee, idx) => (
                                                    <tr key={idx}>
                                                        <td>
                                                            <div className="flex items-center gap-3">
                                                                <div className='avatar'>
                                                                    {utils.checkImage(employee) ? (
                                                                        <div className=" w-11 rounded-full">
                                                                            <img src={employee?.image} alt={employee?.email} />
                                                                        </div>
                                                                    ):(
                                                                        <div>
                                                                            <div className="avatar placeholder">
                                                                                <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                                    <span className="text-xl">{utils.makeProfileLetters(employee)}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='flex flex-col gap-2'>
                                                                    <div>
                                                                        {employee?.full_name ? (
                                                                            <p>{employee?.full_name}</p>
                                                                        ):(
                                                                            <p>{employee?.email}</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{employee?.expert_service_type?.name}</td>
                                                        <td>{employee?.gigs_completed}</td>
                                                        <td>
                                                            <button 
                                                                className='btn btn-ghost' 
                                                                style={{color: "#42B883"}}><SlFrame/> View</button>
                                                        </td>
                                                        <td>
                                                            <button 
                                                                className='btn btn-ghost' 
                                                                style={{color: "#42B883"}}><BiSolidEditAlt/> Edit</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {employees?.length < 1 && (
                                            <Empty objectName={'Employees'}/>
                                        )}
                                    </div>
                                )}
                            </div>
                        ):(
                            <div>
                                <p>User has no Services</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ExpertEmployees