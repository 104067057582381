import React, { useEffect } from 'react'
import moment from 'moment'
import { IoSettingsOutline } from "react-icons/io5"
import { useNavigate } from 'react-router-dom'

function PromotionTableRow(props) {
    const promotion = props.promotion
    const navigate = useNavigate()

    useEffect(() => {}, [promotion])
    return (
        <tr>
            <td>
                {promotion?.title ? (
                    <span>{promotion?.title}</span>
                ):(
                    <span>-</span>
                )}
            </td>
            <td>{promotion?.discount} % off</td>
            <td>{promotion?.participants}</td>
            <td>
                <span>
                    {promotion?.launch_date ? (
                        <>
                            {moment(promotion?.launch_date).format("MMMM Do YYYY")}
                        </>
                    ):(
                        <>-</>
                    )}
                </span>
            </td>
            <td>{promotion?.status}</td>
            <td>
                <button 
                    onClick={() => navigate(`/promotions/${promotion?.id}`)}
                    className='btn btn-ghost' 
                    style={{color: "#42B883"}}><IoSettingsOutline/> Manage</button>
            </td>
        </tr>
    )
}

export default PromotionTableRow