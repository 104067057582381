import React, { useEffect } from 'react'
import utils from '../../utils'
import moment from 'moment'
import { FaRegStar } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function BuyersTableRow(props) {
    const buyer = props.buyer
    const navigate = useNavigate()

    useEffect(() => {}, [buyer])

    function viewBuyer(id){
        navigate(`/buyers/${id}`)
    }
    return (
        <tr
            onClick={() => viewBuyer(buyer?.id)} 
            className='mb-2 hover:cursor-pointer'>
            <td>
                {buyer && (
                    <div className="flex items-center gap-3">
                        <div className='avatar'>
                            {utils.checkImage(buyer) ? (
                                <div className=" w-11 rounded-full">
                                    <img src={buyer?.image} alt={buyer?.email} />
                                </div>
                            ):(
                                <div>
                                    <div className="avatar placeholder">
                                        <div className="bg-neutral text-neutral-content rounded-full w-11">
                                            <span className="text-xl">{utils.makeProfileLetters(buyer)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div>
                                {buyer?.full_name ? (
                                    <p>{buyer?.full_name}</p>
                                ):(
                                    <p>{buyer?.email}</p>
                                )}
                            </div>
                            <div>
                                <p className='text-gray-500 text-xs'>Joined: {moment(buyer?.creation_time).format("MMMM Do YYYY")}</p>
                            </div>
                        </div>
                    </div>
                )}
            </td>
            <td>
                {buyer?.buyer_account_type}
            </td>
            <td>
                <span>{buyer?.city}, {buyer?.country ? (buyer?.country):('Kenya')}</span>
            </td>
            <td>
                {buyer?.gigs_requested}
            </td>
            <td>
                <div className='flex gap-3 items-center'>
                    <FaRegStar className='text-warning h-4 w-4'/>
                    {buyer?.ratings}
                </div>
            </td>
            <td>
                {buyer?.subscription}
            </td>
            <td>
                {moment(buyer?.last_updated_time).from()}
            </td>
        </tr>
    )
}

export default BuyersTableRow