import React, { useEffect } from 'react'
import moment from 'moment'
import { IoSettingsOutline } from "react-icons/io5"
import { useNavigate } from 'react-router-dom'

function ServiceChargesTableRow(props) {
    const charge = props.charge
    const navigate = useNavigate()

    useEffect(() => {}, [charge])
    return (
        <tr>
            <td>
                {charge?.service_type ? (
                    <span>{charge?.service_type?.name}</span>
                ):(
                    <span>-</span>
                )}
            </td>
            <td>
                {moment(charge?.creation_time).format("MMMM Do YYYY")}   
            </td>
            <td>
                {charge?.times_used}
            </td>
            <td>
            <button 
                onClick={() => props.openModal(charge)}
                className='btn btn-ghost' 
                style={{color: "#42B883"}}>View Charges</button>
            </td>
            <td>
                <button 
                    onClick={() => navigate(`/services/manage/charges/${charge?.id}`)}
                    className='btn btn-ghost' 
                    style={{color: "#42B883"}}><IoSettingsOutline/> Manage</button>
            </td>
        </tr>
    )
}

export default ServiceChargesTableRow