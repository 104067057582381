import React, { useEffect } from 'react'
import { IoTicketOutline } from "react-icons/io5";
import { LiaUserTagSolid } from "react-icons/lia";
import { RiUserForbidLine } from "react-icons/ri";
import { RiUserSettingsLine } from "react-icons/ri";
import utils from '../../utils';

function SubscriptionSummary(props) {
    const statistics = props.statistics
    useEffect(() => {}, [statistics])
    return (
        <div className='p-3'>
            {statistics && (
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3'>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.subscrition_plans)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Subscription Plans</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <IoTicketOutline className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.user_subscriptions)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Total Subscribers</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <LiaUserTagSolid className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.active_subscriptions)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Active Subscriptions</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <RiUserSettingsLine className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.inactive_subscriptions)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Inactive Subscriptions</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <RiUserForbidLine className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                </div>
            )}

        </div>
    )
}

export default SubscriptionSummary