import React from 'react'
import {PiGearBold} from 'react-icons/pi'

function UnderConstruction() {
    return (
        <div className='alert rounded-lg alert-info mb-3'>
            <PiGearBold className='h-7 w-7 animate-spin '/>
            <p>This Component is under constuction, it will be available soon.</p>
        </div>
    )
}

export default UnderConstruction