import React, { useEffect, useState } from 'react'
import Empty from '../feedback/Empty'
import ServiceChargesTableRow from './ServiceChargesTableRow'
import ChargesViewModal from './ChargesViewModal'
import { IoAddCircleOutline } from "react-icons/io5";
import ServiceChargesCreateModal from './ServiceChargesCreateModal';
import CommissionModal from '../serviceCharges/CommissionModal';

function ServiceTypeTable(props) {
    const [activeCharge, setActiveCharge] = useState()
    const [commission, setCommission] = useState()
    const charges = props.charges
    const vehicleCategories = props.vehicleCategories

    useEffect(() => {}, [charges])
    useEffect(() => {}, [activeCharge])
    useEffect(() => {}, [vehicleCategories])
    useEffect(() => {}, [commission])

    function openModal(charge){
        setActiveCharge(charge)
        const openBtn = document.getElementById("charge-view-open-btn")
        openBtn.click()
    }
    function openCommissionModal(){
        const openBtn = document.getElementById("commission-view-modal-open-btn")
        openBtn.click()
    }
    function openCreateModal(){
        const openBtn = document.getElementById("service-charge-create-open-btn")
        openBtn.click()
    }
    return (
        <div className='p-3'>
            <div className='border p-4 rounded-md'>
                <CommissionModal
                    action={0}
                    setCommission={setCommission}
                    openCreateModal={openCreateModal}
                />
                <ChargesViewModal
                    activeCharge={activeCharge}
                />
                <ServiceChargesCreateModal
                    vehicleCategories={vehicleCategories}
                    commission={commission}
                    updateUI={props.updateUI}
                />
                <div className="flex justify-between items-center mb-5">
                    <p className='font-bold md:text-lg text-gray-600'>Manage Services Charges</p>
                    <button 
                        onClick={() => openCommissionModal()}
                        className='btn btn-accent text-white'><IoAddCircleOutline className='w-5 h-5'/> Add Service</button>
                </div>
                <div>
                    <div className="overflow-x-auto mb-9 lg:mb-14">
                        <table className="table table-zebra table-xs">
                            <thead>
                                <tr className='text-sm'>
                                    <td>Service Name</td>
                                    <td>Date Added</td>
                                    <td>Times Requested</td>
                                    <td>Cost</td>
                                    <td>Manage</td>
                                </tr>
                            </thead>
                            <tbody>
                                {charges?.results?.map((charge, idx) => (
                                    <ServiceChargesTableRow
                                        key={idx}
                                        charge={charge}
                                        openModal={openModal}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {charges?.count < 1 && (
                            <Empty objectName={"Service"}/>
                        )}
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default ServiceTypeTable