import React, { useEffect, useState } from 'react'
import servicesService from '../../services/ServicesService'
import FetchError from '../feedback/FetchError'
import Empty from '../feedback/Empty'
import Loading from '../feedback/Loading'
import { FaDownload } from 'react-icons/fa'
import utils from '../../utils'
import toast from 'react-hot-toast'

function ServiceProof(props) {
    const [proof, setProof] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const isCompleted = props.isCompleted
    const service = props.service

    useEffect(() => {}, [proof])
    useEffect(() => {}, [isCompleted])
    useEffect(() => {}, [error])
    useEffect(() => {
        if (service && isCompleted) {
            getDeliveryProof(service?.id)
        }
    }, [service, isCompleted])

    const getDeliveryProof = (id) => {
        setLoading(true)
        setError(null)
        servicesService.getDeliveryProof(id).then(
            res => {
                setProof(res.data)
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }
    function downloadMedia(id){
        toast.error("Feature not yet available")
    }
    return (
        <div className="rounded-md border pb-7">
            <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                <p className="font-bold text-lg">Proof of Work</p>    
            </div>
            <div>
                {isCompleted ? (
                    <div>
                        {loading ? (
                            <div className='flex items-center justify-center h-44'>
                                <Loading/>
                            </div>
                        ):(
                            <div>
                                {error && (<FetchError error={error}/>)}
                                {proof && (
                                    <div className='p-2'>
                                        {proof?.map((p, idx) => (
                                            <div 
                                                key={idx}
                                                className='flex mb-3 border rounded-md items-center p-2'>
                                                <div className="flex-grow">
                                                    <div className='flex gap-3 items-center'>
                                                        <img
                                                            className='h-20 w-20 rounded-md'
                                                            src={utils.baseUrl + p?.media}
                                                            alt='Delivery Proof'/>
                                                        <div>
                                                            <p className='text-black'>{p?.file_name}</p>
                                                            <p>{p?.file_size} Mb</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='flex-grow-0'>
                                                    <button
                                                        onClick={() => downloadMedia(p?.id)}
                                                        className='btn btn-ghost'
                                                        style={{color: "#42B883"}}
                                                    ><FaDownload/> Download</button>
                                                </div>
                                            </div>
                                        ))}
                                        {proof?.length < 1 && (
                                            <Empty objectName={"Proof of Work"}/>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                ):(
                    <div className='p-2'>
                        <div>This gig is not Completed!</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ServiceProof