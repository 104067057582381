import React, { useEffect, useState } from 'react'
import Loading from '../feedback/Loading'
import toast from 'react-hot-toast'
import servicesService from '../../services/ServicesService'
import utils from '../../utils'
import { FaAngleDown } from "react-icons/fa6";
import VehicleStartingCostModal from './VehicleStartingCostModal'
import VehicleRangeRow from './VehicleRangeRow'

function EditServiceChargeModal(props) {
    const activeCharge = props.activeCharge
    const vehicleRanges = props.vehicleRanges
    const startingCosts = props.startingCosts
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [baseRate, setBaseRate] = useState(0)
    const [pricePerKm, setPricePerKm] = useState(0)
    const [stopCost, setStopCost] = useState(0)
    const [tractactionFee, setTransactionFee] = useState(0)
    const [vehicleCategories, setVehicleCategories] = useState()
    const [activeCategory, setActiceCategory] = useState()
    const [changeValue, setChangevalue] = useState([])

    useEffect(() => {
        getCategories()
    }, [])
    useEffect(() => {
        if (activeCharge) {
            setName(activeCharge?.service_type?.name)
            setBaseRate(activeCharge?.base_rate)
            setPricePerKm(activeCharge?.price_per_km)
            setStopCost(activeCharge?.stop_cost)
            setTransactionFee(activeCharge?.transaction_fee)
        }
    }, [activeCharge])
    useEffect(() => {}, [name])
    useEffect(() => {}, [baseRate])
    useEffect(() => {}, [pricePerKm])
    useEffect(() => {}, [stopCost])
    useEffect(() => {}, [tractactionFee])
    useEffect(() => {}, [vehicleCategories])
    useEffect(() => {}, [activeCategory])
    useEffect(() => {}, [vehicleRanges])
    useEffect(() => {}, [changeValue])
    useEffect(() => {}, [startingCosts])
    

    const getCategories = () => {
        servicesService.getVehicleCategories().then(
            res => {
                setVehicleCategories(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }

    function closeModal(){
        const closeBtn = document.getElementById("service-charge-edit-modal-close-btn")
        closeBtn.click()
    }
    function submitHandler(e){
        setLoading(true)
        e.preventDefault()
        const submitToast = toast.loading("Updating service")
        const data = {
            'base_rate': baseRate,
            'price_per_km': pricePerKm,
            'stop_cost': stopCost,
            'transaction_fee': tractactionFee
        }
        servicesService.modifyServiceCharge(activeCharge?.id, data).then(
            res => {
                toast.success("Update Successfull", {
                    id:submitToast
                })
                setLoading(false)
                props.setCharge(res.data)
            },
            err => {
                console.log(err);
                toast.error(utils.toasterError, {
                    id:submitToast
                })
                setLoading(false)
            }
        )
    }
    function submitForms(){
        const formOneChanged = checkChange()
        if (formOneChanged) {
            const formOneBtn = document.getElementById("service-charge-submit-btn")
            formOneBtn.click()
        }
        if (changeValue?.length) {
            updateVehicleRangesPercentage(changeValue)
        }
        if(! changeValue?.length && ! formOneChanged){
            toast.error("Nothing Changed!")
        }
    }
    function openModal(id){
        const active = vehicleCategories.filter((item) => item?.id === id)
        setActiceCategory(active[0])
        const openBtn = document.getElementById("vehicle-starting-cost-open-btn")
        openBtn.click()
    }
    function performChange(data){
        // check if data in change values
        const existingObject = changeValue.find(
            (object) => object.id === data.id 
        );

        // if existing update else add obj to change arr
        if (existingObject) {
            setChangevalue([
                ...changeValue.filter((object) => object !== existingObject),
                { ...existingObject, ...data },
            ])
        } else {
            setChangevalue([...changeValue, data])
        }
    }
    function checkChange(){
        if (baseRate !== activeCharge?.base_rate) {
            return true
        }
        if (pricePerKm !== activeCharge?.price_per_km) {
            return true
        }
        if (stopCost !== activeCharge?.stop_cost) {
            return true
        }
        if (tractactionFee !== activeCharge?.transaction_fee) {
            return true
        }
        return false
    }
    function updateVehicleRangesPercentage(data){
        const updateToast = toast.loading("Updating Vehicle Range Percentage")
        setLoading(true)
        servicesService.updateVehicleRangesPercentage(data).then(
            res => {
                toast.success("Updated Percentage(s) Successfully", {
                    id:updateToast
                })
                setChangevalue([])
                setLoading(false)
            },
            err => {
                console.log(err);
                toast.error(utils.toasterError, {
                    id:updateToast
                })
                setLoading(false)
            }
        )
    }
    return (
        <div>
            <label htmlFor="service-charge-edit-modal" id='service-charge-create-open-btn' className="hidden">Edit Service</label>
            <input type="checkbox" id="service-charge-edit-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <VehicleStartingCostModal
                        updateStartingCosts={props.updateStartingCosts}
                        startingCosts={startingCosts}
                        activeCategory={activeCategory}/>
                    <label htmlFor="service-charge-edit-modal" id='service-charge-edit-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-4'>Edit Service Details</h2>
                    <div className="p-7 mt-6">
                        <form onSubmit={submitHandler}>
                            <div className='flex w-full justify-between gap-5 mb-4'>
                                <label className="form-control w-full">
                                    <div className="label">
                                        <span className="label-text mb-1">Service Name <span className='text-red-600 ml-1'>*</span></span>
                                    </div>
                                    <input 
                                        value={name}
                                        readOnly
                                        type="text" 
                                        placeholder="Enter Service Name" 
                                        className="input input-bordered w-full input-disabled" />
                                </label>
                                <label className="form-control w-full">
                                    <div className="label">
                                        <span className="label-text mb-1">Service Base Rate <span className='text-red-600 ml-1'>*</span></span>
                                    </div>
                                    <input 
                                        value={baseRate}
                                        onChange={(e) => setBaseRate(e.target.value)}
                                        type="number" 
                                        placeholder="Enter Amount" 
                                        className="input input-bordered w-full" />
                                </label>
                            </div>
                            <div className='flex w-full justify-between gap-5 mb-4'>
                                <label className="form-control w-full">
                                    <div className="label">
                                        <span className="label-text mb-1">Price per Kilometer <span className='text-red-600 ml-1'>*</span></span>
                                    </div>
                                    <input 
                                        value={pricePerKm}
                                        onChange={(e) => setPricePerKm(e.target.value)}
                                        type="number" 
                                        placeholder="Enter Service Name" 
                                        className="input input-bordered w-full" />
                                </label>
                                <label className="form-control w-full">
                                    <div className="label">
                                        <span className="label-text mb-1">Stop Cost <span className='text-red-600 ml-1'>*</span></span>
                                    </div>
                                    <input
                                        value={stopCost}
                                        onChange={(e) => setStopCost(e.target.value)} 
                                        type="number" 
                                        placeholder="Enter Amount" 
                                        className="input input-bordered w-full" />
                                </label>
                                <label className="form-control w-full">
                                    <div className="label">
                                        <span className="label-text mb-1">Transaction Fee <span className='text-red-600 ml-1'>*</span></span>
                                    </div>
                                    <input 
                                        value={tractactionFee}
                                        onChange={(e) => setTransactionFee(e.target.value)} 
                                        type="number" 
                                        placeholder="Enter Percentage" 
                                        className="input input-bordered w-full" />
                                </label>
                            </div>
                            <button type='submit' id='service-charge-submit-btn' className='hidden'>Service Charge Submit Button</button>
                        </form>
                        <div className='py-2'></div>
                        <div className='mb-4'>
                            <div className="label">
                                <span className="label-text mb-1">Starting Cost <span className='text-red-600 ml-1'>*</span></span>
                            </div>
                            <div className="flex justify-center bg-base-200" >
                                <div className="border rounded-l flex-grow">
                                    <div className="label">
                                        <span className="label-text">For Personal Vehicles</span>
                                    </div>
                                    <div className=''>
                                        <button
                                            onClick={() => openModal(1)} 
                                            className='btn w-full justify-between'>Enter Amount <FaAngleDown/> </button>
                                    </div>
                                </div>
                                <div className="border rounded-r flex-grow">
                                    <div className="label">
                                        <span className="label-text">For Commercial Vehicles</span>
                                    </div>
                                    <div className='w-full'>
                                        <button
                                            onClick={() => openModal(2)} 
                                            className='btn w-full justify-between'>Enter Amount <FaAngleDown/></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-2'></div>
                        <div>
                            <p className=' mb-2'>No of Vehicle Brackets</p>
                            <div className="overflow-x-auto mb-4">
                                <table className="table table-md rounded-t">
                                    <thead className='bg-base-200 '>
                                        <tr className='text-md'>
                                            <td>Number Range</td>
                                            <td>Percentage Price of Price per Kilometer</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vehicleRanges ? (
                                            <>
                                                {vehicleRanges?.map((vehicleRange, idx) => (
                                                    <VehicleRangeRow
                                                        key={idx}
                                                        vehicleRange={vehicleRange}
                                                        performChange={performChange}
                                                    />
                                                ))} 
                                            </>
                                        ):null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='modal-action flex justify-end gap-3'>
                            {loading ? (<Loading/>):(
                                <>
                                    <div
                                        onClick={() => closeModal()}
                                        className='btn btn-ghost text-accent'>Cancel</div>
                                    <button
                                        onClick={() => submitForms()}
                                        className='btn text-white btn-accent'>Save</button>
                                </>
                            )}                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditServiceChargeModal