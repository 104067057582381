import React, { useEffect, useState } from 'react'
import Empty from '../feedback/Empty'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import subscriptionServices from '../../services/SubscriptionService'
import SuscriberTableRow from './SuscriberTableRow'
import { useSearchParams } from 'react-router-dom'
import Pagination from '../nav/Pagination'

function SubscriberDetails(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const [subscribers, setSubscribers] = useState()
    const [error, setError] = useState()
    const idParam = props.idParam
    const currentTab = props.currentTab

    useEffect(() => {
        if (currentTab === 1 && idParam?.id) {
            setError()
            setLoading(true)
            getSubscribers(idParam?.id, '')
        }
    }, [idParam, currentTab])
    useEffect(() => {}, [subscribers])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getSubscribers(idParam?.id, params)
        }
    }, [searchParams, idParam])

    const getSubscribers = (id, params) => {
        setError()
        setLoading(true)
        subscriptionServices.getSubscribers(id, params ? `?${params}` : '').then(
            res => {
                setLoading(false)
                setSubscribers(res.data)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    return (
        <div className='mb-5'>
            <div className="rounded-md border">
                <div className="py-5 border-b mb-3 p-4">
                    <p className="font-bold text-lg">Plan Subscribers</p>
                </div>
                <div className="p-4">
                    {loading ? (
                        <div className='flex justify-center'>
                            <Loading/>
                        </div>
                    ):(
                        <div>
                            {error ? (<FetchError error={error}/>):(
                                <div>
                                    <div className="overflow-x-auto mb-6">
                                        <table className="table table-zebra table-sm"> 
                                            <thead>
                                                <tr className='text-sm'>
                                                    <td>Buyer</td>
                                                    <td>Buyer Type</td>
                                                    <td>Billing</td>
                                                    <td>Status</td>
                                                    <td>Due Date</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subscribers?.results?.map((subscriber, idx) => (
                                                    <SuscriberTableRow
                                                        key={idx}
                                                        subscriber={subscriber}
                                                    />
                                                ))}
                                            </tbody>
                                        </table>
                                        {subscribers?.results?.length < 1 && (
                                            <Empty objectName="Suscribed Users"/>
                                        )}
                                    </div>                                    
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {subscribers?.results?.length > 1 && (
                    <>
                        {subscribers && (
                            <Pagination
                                count={subscribers?.count}
                                next={subscribers?.next}
                                previous={subscribers?.previous}
                                changeLimitParam={changeLimitParam}
                                changePageParam={changePageParam}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default SubscriberDetails