import React, { useEffect, useState } from 'react'
import RegistrationProgress from '../feedback/RegistrationProgress'
import UnderConstruction from '../feedback/UnderConstruction'

function RegisterExpertModal(props) {
    const [progress, setProgress] = useState(30)
    useEffect(() => {}, [progress])
    return (
        <div>
            <label htmlFor="expert-create-modal" id='expert-create-open-btn' className="hidden">Create Expert</label>
            <input type="checkbox" id="expert-create-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <label htmlFor="expert-create-modal" id='expert-create-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-4'>Create Expert Account</h2>
                    <RegistrationProgress progress={progress} setProgress={setProgress}/>
                    <div className="p-7 mt-9">
                        <UnderConstruction/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterExpertModal