import React, { useEffect, useState } from 'react'
import { IoIosSearch } from "react-icons/io"
import { LuFilter } from "react-icons/lu";
import { useSearchParams } from 'react-router-dom';
import financeServices from '../../services/FInanceServices';
import Loading from '../feedback/Loading';
import FetchError from '../feedback/FetchError';
import Pagination from '../nav/Pagination';
import Empty from '../feedback/Empty';
import ReceivedCommisionRow from './ReceivedCommisionRow';

function ReceivedCommission(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [payments, setPayments] = useState()
    const currentTab = props.currentTab

    useEffect(() => {
        if (currentTab === 4) {
            setSearchParams((params) => {
                params.set("has_disbursed", true)
                return params
            })
        }
    }, [currentTab, setSearchParams])
    useEffect(() => {}, [loading])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getPendingPayments(params)
        }
    }, [searchParams])
    useEffect(() => {}, [error])
    useEffect(() => {}, [payments])

    const getPendingPayments = (params) => {
        setLoading(true)
        setError()
        financeServices.getCashPayments(params ? `?${params}` : '').then(
            res => {
                setPayments(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    return (
        <div>
            <div className='border rounded-md pb-6'>
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Received Commissions</p>
                </div>
                <div className='mb-3 flex gap-6 items-center px-3'>
                    <div className='btn btn-outline'>
                        <IoIosSearch className='w-5 h-5'/>
                    </div>
                    <div className='flex items-center gap-3'>
                        <LuFilter className='w-8 h-8' color='#42B883'/>
                        <select className="select select-ghost" defaultValue={0}>
                            <option value={0} disabled>Filter By</option>
                            <option value={1}>Active</option>
                            <option value={2}>Inactive</option>
                        </select>
                    </div>
                </div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div className="p-3">
                                <div className="overflow-x-auto">
                                    <table className="table table-zebra">
                                        <thead>
                                            <tr>
                                                <td>Expert</td>
                                                <td>Date of Service</td>
                                                <td>Amount</td>
                                                <td>Confirmed On</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments?.results?.map((payment, idx) => (
                                                <ReceivedCommisionRow
                                                    key={idx}
                                                    payment={payment}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                    {payments?.count < 1 ? (
                                        <div className="mb-7">
                                            <Empty objectName={"Received Commission"}/>
                                        </div>                            
                                    ):(
                                        <div className=" p-3 mt-3">
                                            {payments && (
                                                <Pagination
                                                    count={payments?.count}
                                                    next={payments?.next}
                                                    previous={payments?.previous}
                                                    changeLimitParam={props.changeLimitParam}
                                                    changePageParam={props.changePageParam}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>  
                        )}
                    </div>
                )}              
            </div>
        </div>
    )
}

export default ReceivedCommission