import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import utils from '../../utils'
import moment from 'moment'
import { IoSettingsOutline } from "react-icons/io5"

function ServiceTableRow(props) {
    const service = props.service
    const location = useLocation()
    const navigate = useNavigate()
    const maxLength = 27

    useEffect(() => {}, [location])
    useEffect(() => {}, [service])
    return (
        <tr>
            <td>
                {service?.responder ? (
                    <div className="flex items-center gap-3">
                        <div className='avatar'>
                            {utils.checkImage(service?.responder) ? (
                                <div className=" w-11 rounded-full">
                                    <img src={service?.responder?.image} alt={service?.responder?.email} />
                                </div>
                            ):(
                                <div>
                                    <div className="avatar placeholder">
                                        <div className="bg-neutral text-neutral-content rounded-full w-11">
                                            <span className="text-xl">{utils.makeProfileLetters(service?.responder)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className=''>
                            <div>
                                {service?.responder?.full_name ? (
                                    <p>{service?.responder?.full_name}</p>
                                ):(
                                    <p>{service?.responder?.email}</p>
                                )}
                            </div>
                        </div>
                    </div>
                ):(
                    <span>-</span>
                )}
            </td>
            <td>
                {service?.owner && (
                    <div className="flex items-center gap-3">
                        <div className='avatar'>
                            {utils.checkImage(service?.owner) ? (
                                <div className=" w-11 rounded-full">
                                    <img src={service?.owner?.image} alt={service?.owner?.email} />
                                </div>
                            ):(
                                <div>
                                    <div className="avatar placeholder">
                                        <div className="bg-neutral text-neutral-content rounded-full w-11">
                                            <span className="text-xl">{utils.makeProfileLetters(service?.owner)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className=''>
                            <div>
                                {service?.owner?.full_name ? (
                                    <p>{service?.owner?.full_name}</p>
                                ):(
                                    <p>{service?.owner?.email}</p>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </td>
            <td>
                {service?.service_type?.name}
            </td>
            {location.pathname === '/services/ongoing' ? (
                <>
                    <td>{moment(service?.creation_time).fromNow()}</td>
                    <td>{service?.from_formated_address}</td>
                    <td>{service?.status}</td>
                </>
            ) : null}
            {location.pathname === '/services/scheduled' ? (
                <>
                    <td>{moment(service?.creation_time).format("MMMM Do YYYY HH:mm")}</td>
                    <td>{service?.from_formated_address}</td>
                    <td>
                        {((service?.schedule_date) && (service?.schedule_time)) ? (
                            <>
                                {(moment(`${service?.schedule_date}T${service?.schedule_time}`).fromNow())}
                            </>
                        ):(
                            <>-</>
                        )}
                        
                    </td>
                </>
            ) : null}
            {location.pathname === '/services/completed' ? (
                <>
                    <td>{moment(service?.creation_time).format("MMMM Do YYYY HH:mm")}</td>
                    <td>{service?.from_formated_address}</td>
                    <td>
                        {service?.finish_time ? (
                            <>
                                {moment(service?.finish_time).format("MMMM Do YYYY HH:mm")}
                            </>
                        ):(
                            <>-</>
                        )}
                    </td>
                </>
            ) : null}
            {location.pathname === '/services/cancelled' ? (
                <>
                    <td>{moment(service?.creation_time).format("MMMM Do YYYY HH:mm")}</td>
                    <td>{service?.from_formated_address}</td>
                    <td>
                        {service?.cancel_reason?.length > maxLength ? (
                            <>
                                {service?.cancel_reason?.substring(0, maxLength)}...
                            </>
                        ):(
                            <>
                                {service?.cancel_reason}
                            </>
                        )}
                    </td>
                </>
            ) : null}
            <td>
                {service?.payment_amount}
            </td>
            <td>
                <button 
                    onClick={() => navigate(`/service/${service?.id}`)}
                    className='btn btn-ghost' 
                    style={{color: "#42B883"}}><IoSettingsOutline/> Manage</button>
            </td>
        </tr>
    )
}

export default ServiceTableRow