import React, { useEffect } from 'react'
import utils from '../../utils'
import moment from 'moment'
import { Link } from 'react-router-dom'

function ExpertProfile(props) {
    const user = props.user
    useEffect(() => {}, [user])
    return (
        <div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Personal Details</p>
                </div>
                <div className='p-2 space-y-3'>
                    {user && (
                        <div className="flex items-center gap-3">
                            <div className='avatar'>
                                {utils.checkImage(user) ? (
                                    <div className=" w-16 rounded-full">
                                        <img src={utils.baseUrl + user?.image} alt={user?.email} />
                                    </div>
                                ):(
                                    <div>
                                        <div className="avatar placeholder">
                                            <div className="bg-neutral text-neutral-content rounded-full w-16">
                                                <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className=''>
                                <p>Profile Photo</p>
                            </div>
                        </div>
                    )}
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Full Name</p>
                        <p>
                            {user?.full_name ? (
                                <span>{user?.full_name}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Email</p>
                        <p>{user?.email}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Phonenumber</p>
                        <p>
                            {user?.phonenumber ? (
                                <span>{user?.phonenumber}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>County</p>
                        <p>{user?.country}</p>
                    </div>
                    {user?.is_expert && (
                        <>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>City</p>
                                <p>
                                    {user?.city ? (
                                        <span>{user?.city}</span>
                                    ):(
                                        <span>-</span>
                                    )}
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Street Address</p>
                                <p>
                                    {user?.street_address ? (
                                        <span>{user?.street_address}</span>
                                    ):(
                                        <span>-</span>
                                    )}
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Building / Landmark</p>
                                <p>
                                    {user?.building_landmark ? (
                                        <span>{user?.building_landmark}</span>
                                    ):(
                                        <span>-</span>
                                    )}
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Account Details</p>
                </div>
                <div className='p-2 space-y-3'>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>User Type</p>
                        <p className='flex gap-1'>
                            <span className='comma'>{user?.is_expert ? 'Expert':null}</span>
                            <span>{user?.is_buyer ? 'Buyer':null}</span>
                            <span className='comma'>{user?.is_employee ? 'Employee':null}</span>
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Account Type</p>
                        <p className='flex gap-2'>
                            {user?.is_buyer && (
                                <span>
                                    {user?.buyer_account_type}
                                </span>
                            )}
                            {user?.is_expert && (
                                <span>
                                    {user?.expert_service_type ? (
                                        <span>
                                            {user?.expert_service_type?.name}
                                        </span>
                                    ):(
                                        <span>
                                            -
                                        </span>
                                    )}
                                </span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Created On</p>
                        <p className='flex gap-1'>
                            <span>{moment(user?.creation_time).format("Do MMMM YYYY")}</span>
                            <span>.</span>
                            <span>{moment(user?.creation_time).format("HH:MM:SS")}</span>
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Joined from</p>
                        <p className='flex gap-1'>
                            {user?.referred_code ? (
                                <span>Referral</span>
                            ):(
                                <span>Joined Directly</span>
                            )}
                        </p>
                    </div>
                    {user?.referred_code && (
                        <div className="flex justify-between">
                            <p className='text-gray-500'>Refered by</p>
                            <p className='flex gap-1'>
                                <Link to={
                                    user?.referred_code?.owner?.is_buyer 
                                    ? 
                                    `/buyers/${user?.referred_code?.owner?.id}`
                                    :
                                    `/experts/${user?.referred_code?.owner?.id}`}>
                                        {user?.referred_code?.owner?.full_name ? (
                                            <span className='text-accent'>{user?.referred_code?.owner?.full_name}</span>
                                        ):(
                                            <span>{user?.referred_code?.owner?.email}</span>
                                        )}
                                </Link>
                            </p>
                        </div>
                    )}
                    <div className="flex justify-between">
                        <p className='text-gray-500'>DOT Number</p>
                        <p className='flex gap-1'>
                            {user?.user_documents?.dot_number ? (
                                <span>{user?.user_documents?.dot_number}</span>
                            ):(
                                <span>Not yet Saved</span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Payment Details</p>
                </div>
                <div className='p-2 space-y-3'>
                    {user?.is_expert && (
                        <>
                            {user?.payment_details ? (
                                <>
                                    <div className="flex justify-between">
                                        <p className='text-gray-500'>Bank</p>
                                        <p>{user?.payment_details?.bank}</p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p className='text-gray-500'>Branch</p>
                                        <p>{user?.payment_details?.branch}</p>
                                    </div>
                                    <div className="flex justify-between">
                                        <p className='text-gray-500'>Account Holder Name</p>
                                        <p>{user?.payment_details?.account_holder_name}</p>
                                    </div>
                                </>
                            ):(
                                <>
                                    <p>User has not Saved Payment Details yet!</p>
                                </>
                            )}
                        </>
                    )}
                    {user?.is_buyer && (
                        <>
                            {user?.default_payment_method ? (
                                <>
                                    {user?.default_payment_method?.title === "cash" && (
                                        <div className="flex justify-between">
                                            <p className='text-gray-500'>Account Number</p>
                                            <p>{user?.default_payment_method?.title}</p>
                                        </div>
                                    )}
                                    {user?.default_payment_method?.setup_intent_id && (
                                        <>
                                            <div className="flex justify-between">
                                                <p className='text-gray-500'>Default Payment</p>
                                                <p>{user?.default_payment_method?.title}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p className='text-gray-500'>Brand</p>
                                                <p>{user?.default_payment_method?.brand}</p>
                                            </div>
                                        </>
                                    )}
                                </>
                            ):(
                                <>
                                    <p>User has not Saved Payment Methods yet!</p>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Uploads</p>
                </div>
                <div className='p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3'>
                    {user?.is_expert && (
                        <>
                            {user?.user_documents ? (
                                <>
                                    <div className=''>
                                        <div 
                                            className='card border rounded-md'>
                                            {user?.image ? (
                                                <figure>
                                                    <img
                                                        className='w-full rounded-md h-56' 
                                                        src={utils.baseUrl + user?.image} 
                                                        alt="profile" />
                                                </figure>
                                            ):(
                                                <div className="avatar placeholder">
                                                    <div className="bg-neutral text-neutral-content rounded-md w-full h-56">
                                                        <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="p-3">
                                                <p>Profile Picture</p>
                                                <p className='text-gray-500'>
                                                    {user?.image ? (
                                                        <span>
                                                            {user?.image}
                                                        </span>
                                                    ):(
                                                        <span>None Yet</span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div 
                                            className='card border rounded-md'>
                                            {user?.user_documents?.licence ? (
                                                <figure>
                                                    <img
                                                        className='w-full rounded-md h-56' 
                                                        src={utils.baseUrl + user?.user_documents?.licence} 
                                                        alt="profile" />
                                                </figure>
                                            ):(
                                                <div className="avatar placeholder">
                                                    <div className="bg-neutral text-neutral-content rounded-md w-full h-56">
                                                        <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="p-3">
                                                <p>Professional Licence</p>
                                                <p className='text-gray-500'>
                                                    {user?.user_documents?.licence ? (
                                                        <span>
                                                            {user?.user_documents?.licence}
                                                        </span>
                                                    ):(
                                                        <span>None Yet</span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div 
                                            className='card border rounded-md'>
                                            {user?.user_documents?.insurance ? (
                                                <figure>
                                                    <img
                                                        className='w-full rounded-md h-56' 
                                                        src={utils.baseUrl + user?.user_documents?.insurance} 
                                                        alt="profile" />
                                                </figure>
                                            ):(
                                                <div className="avatar placeholder">
                                                    <div className="bg-neutral text-neutral-content rounded-md w-full h-56">
                                                        <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="p-3">
                                                <p>Insurance </p>
                                                <p className='text-gray-500'>
                                                    {user?.user_documents?.insurance ? (
                                                        <span>
                                                            {user?.user_documents?.insurance}
                                                        </span>
                                                    ):(
                                                        <span>None Yet</span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ):(
                                <>
                                    <p>User has not uploaded Any Documents yet!</p>
                                </>
                            )}
                        </>
                    )}
                    {user?.is_buyer && (
                        <>
                            <div className=''>
                                <div 
                                    className='card border rounded-md'>
                                    {user?.image ? (
                                        <figure>
                                            <img
                                                className='w-full rounded-md h-56' 
                                                src={utils.baseUrl + user?.image} 
                                                alt="profile" />
                                        </figure>
                                    ):(
                                        <div className="avatar placeholder">
                                            <div className="bg-neutral text-neutral-content rounded-md w-full h-56">
                                                <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="p-3">
                                        <p>Profile Picture</p>
                                        <p className='text-gray-500'>
                                            {user?.image ? (
                                                <span>
                                                    {user?.image}
                                                </span>
                                            ):(
                                                <span>None Yet</span>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ExpertProfile