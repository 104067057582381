import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { IoCopyOutline } from "react-icons/io5";
import Empty from '../feedback/Empty';
import utils from '../../utils';
import moment from 'moment'
import promotionsServices from '../../services/PromotionsServices';
import Loading from '../feedback/Loading';
import FetchError from '../feedback/FetchError';

function ExpertReferral(props) {
    const [payments, setPayments] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const user = props.user
    
    useEffect(() => {
        if (user?.own_referral_code?.id) {
            getCLaims(user?.own_referral_code?.id)
        }
    }, [user])
    useEffect(() => {}, [error])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [payments])

    const getCLaims = (id) => {
        setError(null)
        setLoading(false)
        promotionsServices.getReferalClaims(id).then(
            res => {
                setPayments(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }

    function copyToClipboard(){
        if (user?.own_referral_code?.code) {
            navigator.clipboard.writeText(user?.own_referral_code?.code)
            toast.success(`${user?.own_referral_code?.code} Copied to clipboard`)
        } else{
            toast.error("User has no Referral Code")
            return
        }
    }
    return (
        <div>
            <div className="rounded-md border p-4 mb-6">
                <div className='flex justify-between items-center'>
                    <p className='text-gray-600'>Referral Code</p>
                    <div className='flex items-center gap-4'>
                        <p className='p-1 px-2 border rounded-md text-accent'>
                            {user?.own_referral_code?.code ? (
                                <span>{user?.own_referral_code?.code}</span>
                            ):(
                                <span>None Yet</span>
                            )}
                        </p>
                        <button
                            onClick={() => copyToClipboard()}
                            className='btn btn-ghost' 
                            style={{color: "#42B883"}}>
                            <IoCopyOutline />
                        </button>
                    </div>
                </div>
            </div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Own Referals</p>
                </div>
                <div className='p-2'>
                    <div className="overflow-x-auto mb-6">
                        <table className="table table-zebra">
                            <thead>
                                <tr>
                                    <td>User</td>
                                    <td>Date</td>
                                    <td>Bonus</td>
                                </tr>
                            </thead>
                            <tbody>
                                {user?.own_referral_code?.users?.map((user, idx) => (
                                    <tr
                                        key={idx}>
                                        <td>
                                            {user && (
                                                <div className="flex items-center gap-3">
                                                    <div className='avatar'>
                                                        {utils.checkImage(user) ? (
                                                            <div className=" w-11 rounded-full">
                                                                <img src={utils.baseUrl + user?.image} alt={user?.email} />
                                                            </div>
                                                        ):(
                                                            <div>
                                                                <div className="avatar placeholder">
                                                                    <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                        <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='flex flex-col gap-2'>
                                                        <div>
                                                            {user?.full_name ? (
                                                                <p>{user?.full_name}</p>
                                                            ):(
                                                                <p>{user?.email}</p>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <p className='text-gray-500 text-xs'>
                                                                {user?.is_expert && 'Expert'}
                                                                {user?.is_buyer && 'Buyer'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )} 
                                        </td>
                                        <td>
                                            {moment(user?.creation_time).format("MMMM Do YYYY")}
                                        </td>
                                        <td>
                                            Set Cost
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {user?.own_referral_code?.users?.length < 1 && (
                            <Empty objectName={"Referals"}/>
                        )}
                    </div>
                </div>
            </div>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Bonus Claims</p>
                </div>
                <div className='p-2'>
                    {user?.own_referral_code ? (
                        <div>
                            {loading ? (
                               <div className='flex items-center justify-center h-44'>
                                    <Loading/>
                                </div> 
                            ):(
                                <div>
                                    {error && (<FetchError error={error}/>)}
                                    {payments && (
                                        <div className="overflow-x-auto mb-6">
                                            <table className="table table-zebra">
                                                <thead>
                                                    <tr>
                                                        <td>Service Charge</td>
                                                        <td>Referral Discount</td>
                                                        <td>Subscription Discount</td>
                                                        <td>Date</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {payments?.map((payment, idx) => (
                                                        <tr key={idx}>
                                                            <td>{payment?.service_charge}</td>
                                                            <td>{payment?.referral_discount}</td>
                                                            <td>{payment?.subscription_discount}</td>
                                                            <td>{moment(payment?.last_updated_time).format("Do MMMM YYYY")}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {payments?.length < 1 && (
                                                <Empty objectName={"Bonus Claims"}/>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            
                        </div>
                    ):(
                        <div>
                            <p>None yet</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ExpertReferral