import React, { useEffect } from 'react'
import utils from '../../utils'

function PersonalDetails(props) {
    const user = props.expert
    useEffect(() => {}, [user])
    return (
        <div id="personal-details" className='p-3'>
            <div className="rounded-md border pb-5 mb-6">
                <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Personal Details</p>
                </div> 

                <div className='p-2 space-y-3'>
                    {user && (
                        <div className="flex items-center gap-3">
                            <div className='avatar'>
                                {utils.checkImage(user) ? (
                                    <div className=" w-16 rounded-full">
                                        <img src={utils.baseUrl + user?.image} alt={user?.email} />
                                    </div>
                                ):(
                                    <div>
                                        <div className="avatar placeholder">
                                            <div className="bg-neutral text-neutral-content rounded-full w-16">
                                                <span className="text-xl">{utils.makeProfileLetters(user)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className=''>
                                <p>Profile Photo</p>
                            </div>
                        </div>
                    )}
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Full Name</p>
                        <p>
                            {user?.full_name ? (
                                <span>{user?.full_name}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Email</p>
                        <p>{user?.email}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Phonenumber</p>
                        <p>
                            {user?.phonenumber ? (
                                <span>{user?.phonenumber}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>County</p>
                        <p>{user?.country}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>City</p>
                        <p>
                            {user?.city ? (
                                <span>{user?.city}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Street Address</p>
                        <p>
                            {user?.street_address ? (
                                <span>{user?.street_address}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Building / Landmark</p>
                        <p>
                            {user?.building_landmark ? (
                                <span>{user?.building_landmark}</span>
                            ):(
                                <span>-</span>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalDetails