import React, { useEffect, useState } from 'react'

function CreationVehicleRangeRow(props) {
    const [rangeOne, setRangeOne] = useState(0)
    const [rangeTwo, setRangeTwo] = useState(0)
    const [rangeThree, setRangeThree] = useState(0)
    const [rangeFour, setRangeFour] = useState(0)
    const vehilceRangePerc = props.vehilceRangePerc
    
    useEffect(() => {
        if (vehilceRangePerc?.length) {
            // TODO: fill percentages
        }
    }, [vehilceRangePerc])
    useEffect(() => {}, [rangeOne])
    useEffect(() => {}, [rangeTwo])
    useEffect(() => {}, [rangeThree])
    useEffect(() => {}, [rangeFour])

    function changePercentange(setRange, val, min, max){
        setRange(val)
        const data = {
            "percentage": parseInt(val),
            "min_value": min,
            "max_value": max
        }
        const existingObject = vehilceRangePerc.find(
            (object) => object.min_value === min
        );
        if (existingObject) {
            props.setVehilceRangePerc([
                ...vehilceRangePerc.filter((object) => object !== existingObject),
                { ...existingObject, ...data },
            ])
        } else{
            props.setVehilceRangePerc([...vehilceRangePerc, data])
        }
    }
    return (
        <>
        <tr>
            <td>
                <span>1</span>
                <span className='ml-1'>Vehicle</span>
            </td>
            <td>
                <label className="form-control w-full">
                    <input 
                        value={rangeOne}
                        type="number" 
                        max={100}
                        min={1}
                        onChange={(e) => changePercentange(
                            setRangeOne, 
                            e.target.value,
                            1, null
                        )}
                        placeholder="Enter Percentage" 
                        className="input input-bordered w-full input-sm" />
                </label>
            </td>
        </tr>
        <tr>
            <td>
                <span>2 - 4</span>
                <span className='ml-1'>Vehicles</span>
            </td>
            <td>
                <label className="form-control w-full">
                    <input 
                        value={rangeTwo}
                        onChange={(e) => changePercentange(
                            setRangeTwo, 
                            e.target.value,
                            2, 4
                        )}
                        type="number" 
                        max={100}
                        min={1}
                        placeholder="Enter Percentage" 
                        className="input input-bordered w-full input-sm" />
                </label>
            </td>
        </tr>
        <tr>
            <td>
                <span>5 - 7</span>
                <span className='ml-1'>Vehicles</span>
            </td>
            <td>
                <label className="form-control w-full">
                    <input 
                        value={rangeThree}
                        onChange={(e) => changePercentange(
                            setRangeThree, 
                            e.target.value,
                            5, 7
                        )}
                        type="number" 
                        max={100}
                        min={1}
                        placeholder="Enter Percentage" 
                        className="input input-bordered w-full input-sm" />
                </label>
            </td>
        </tr>
        <tr>
            <td>
                <span>8 - 10</span>
                <span className='ml-1'>Vehicles</span>
            </td>
            <td>
                <label className="form-control w-full">
                    <input 
                        value={rangeFour}
                        onChange={(e) => changePercentange(
                            setRangeFour, 
                            e.target.value,
                            8, 10
                        )}
                        type="number" 
                        max={100}
                        min={1}
                        placeholder="Enter Percentage" 
                        className="input input-bordered w-full input-sm" />
                </label>
            </td>
        </tr>
        </>
    )
}

export default CreationVehicleRangeRow