import React, { useEffect } from 'react'
import Empty from '../feedback/Empty'
import utils from '../../utils'
import { IoSettingsOutline } from "react-icons/io5"
import { useNavigate } from 'react-router-dom'
import Pagination from '../nav/Pagination'

function ExpertServicesTab(props) {
    const activeTab = props.activeTab
    const services = props.services
    const navigate = useNavigate()

    useEffect(() => {}, [activeTab])
    useEffect(() => {}, [services])
    return (
        <div>
            <div className="overflow-x-auto">
                <table className="table table-zebra mb-6">
                    <thead>
                        <tr>
                            <td>Buyer</td>
                            <td>Pickup</td>
                            <td>Destination</td>
                            <td>Progress Status</td>
                            <td>Cost</td>
                            <td>Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        {services?.results?.map((service, idx) => (
                            <tr key={idx}>
                                <td>
                                    {service?.owner && (
                                        <div className="flex items-center gap-3">
                                            <div className='avatar'>
                                                {utils.checkImage(service?.owner) ? (
                                                    <div className=" w-11 rounded-full">
                                                        <img src={service?.owner?.image} alt={service?.owner?.email} />
                                                    </div>
                                                ):(
                                                    <div>
                                                        <div className="avatar placeholder">
                                                            <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                <span className="text-xl">{utils.makeProfileLetters(service?.owner)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className=''>
                                                <div>
                                                    {service?.owner?.full_name ? (
                                                        <p>{service?.owner?.full_name}</p>
                                                    ):(
                                                        <p>{service?.owner?.email}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {service?.from_formated_address}
                                </td>
                                <td>
                                    {service?.destination_formated_address}
                                </td>
                                <td>
                                    {service?.status}
                                </td>
                                <td>
                                    {service?.payment_amount}
                                </td>
                                <td>
                                    <button 
                                        onClick={() => navigate(`/service/${service?.id}`)}
                                        className='btn btn-ghost' 
                                        style={{color: "#42B883"}}><IoSettingsOutline/> Manage</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {services?.count < 1 ? (
                    <Empty objectName={"Service"}/>
                ):(
                    <div>
                        {services && (
                            <Pagination
                                count={services?.count}
                                next={services?.next}
                                previous={services?.previous}
                                changeLimitParam={props.changeLimitParam}
                                changePageParam={props.changePageParam}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default ExpertServicesTab