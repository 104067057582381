import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import ApplicationsSummary from '../../components/applications/ApplicationsSummary'
import ApplicationsTable from '../../components/applications/ApplicationsTable'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import expertsServices from '../../services/ExpertsServices'
import { useSearchParams } from 'react-router-dom'

function ApplicationsPage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [statistics, setStatistics] = useState()
    const [experts, setExperts] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    useEffect(() => {}, [statistics])
    useEffect(() => {}, [experts])
    useEffect(() => {}, [error])
    useEffect(() => {
        getStatistics()
        getApplications()
    }, [])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getApplications(params)
        }
    }, [searchParams])

    const getStatistics = () => {
        expertsServices.getApplicationStatistics().then(
            res => {
                setStatistics(res.data)
            }, 
            err => {
                console.log(err);
            }
        )
    }
    const getApplications = (params) => {
        setError()
        setLoading(true)
        expertsServices.getApplications(params ? `?${params}` : '').then(
            res => {
                setExperts(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
            getApplications()
        }
    }
    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Account Applications - {utils.siteName}</title>
            </Helmet>
            {loading ? (
                <div className='flex items-center justify-center h-44'>
                    <Loading/>
                </div>
            ):(
                <div>
                    {error ? (<FetchError error={error}/>):(
                        <div>
                            <div className='mb-3'>
                                <ApplicationsSummary
                                    statistics={statistics}/>
                            </div>
                            <div className='mb-3'>
                                <ApplicationsTable
                                    experts={experts}
                                    changePageParam={changePageParam}
                                    changeLimitParam={changeLimitParam}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </HelmetProvider>
    )
}

export default ApplicationsPage