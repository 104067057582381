import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BiSort } from "react-icons/bi";
import Select from 'react-select';
import FetchError from '../feedback/FetchError';
import Loading from '../feedback/Loading';
import expertsServices from '../../services/ExpertsServices';
import Empty from '../feedback/Empty';
import ServiceTypeExpertsTableRow from './ServiceTypeExpertsTableRow';

const options = [
    { value: 'none', label: 'Order By' },
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
]

function ServiceTypeExperts(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const orderingParam = searchParams.get("ordering")
    const [selectedValue, setSelectedValue] = useState(null);
    const charge = props.charge
    const [hasFiltered, setHasFiltered] = useState(false)
    const [experts, setExperts] = useState()

    useEffect(() => {
        const allParams = [...searchParams.entries()]
        const remove = allParams.filter((param) => param[0] !== 'expert_service_type')
        if (remove.length) {
            remove.forEach(element => {
                setSearchParams((params) => {
                    params.delete(element[0])
                    return params
                })
            });
        }
        setSearchParams((params) => {
            params.set("expert_service_type", charge?.id)
            return params
        })
    }, [charge?.id, searchParams, setSearchParams])
    useEffect(() => {
        if (orderingParam && (!hasFiltered)) {
            if (orderingParam === 'creation_time') {
                const defaultOption = options.find(option => option.value === 'asc')
                setSelectedValue(defaultOption)
            } else if (orderingParam === '-creation_time') {
                const defaultOption = options.find(option => option.value === 'desc')
                setSelectedValue(defaultOption)
            }
        }
    }, [orderingParam, hasFiltered])
    useEffect(() => {
        if (!orderingParam) {
            const defaultOption = options.find(option => option.value === 'none')
            setSelectedValue(defaultOption);
        }
    }, [orderingParam])
    useEffect(() => {
        if (selectedValue) {
            if (selectedValue?.value === 'asc') {
                setSearchParams((params) => {
                    params.set("ordering", 'creation_time')
                    return params
                })
            }else if (selectedValue?.value === 'desc') {
                setSearchParams((params) => {
                    params.set("ordering", '-creation_time')
                    return params
                })
            } else if (selectedValue?.value === 'none') {
                setSearchParams((params) => {
                    params.delete("ordering")
                    return params
                })
            }
        }
    }, [selectedValue, setSearchParams])
    useEffect(() => {
        const params = searchParams.toString()
        if (params) {
            getExperts(params ? `?${params}` : '')
        }
    }, [searchParams])
    useEffect(() => {}, [experts])
    
    const handleChange = (selectedOption) => {
        setHasFiltered(true)
        setSelectedValue(selectedOption);
    };

    const getExperts = (params) => {
        setLoading(true)
        setError()
        expertsServices.getExperts(params ? `${params}` : '').then(
            res => {
                console.log(res.data);
                setExperts(res.data)
                setLoading(false)
            }, 
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }

    return (
        <div className="rounded-md border pb-7">
            <div className="py-5 border-b mb-3 p-4">
                <p className="font-bold text-lg">Experts</p>
            </div>
            <div className="flex gap-6 items-center mb-6">
                <div className='flex items-center gap-3'>
                    <BiSort className='w-8 h-8' color='#42B883'/>
                    <Select
                        className='select select-ghost'
                        value={selectedValue}
                        onChange={handleChange}
                        options={options}
                    />
                </div>
            </div>
            {loading ? (
                <div className='flex items-center justify-center h-44'>
                    <Loading/>
                </div>
            ):(
                <div>
                    {error ? (<FetchError error={error}/>) : (
                        <div>
                            <div className="overflow-x-auto mb-6">
                                <table className="table table-zebra table-xs">
                                    <thead>
                                        <tr className='text-sm'>
                                            <td>User</td>
                                            <td>Location</td>
                                            <td>Service Frequency</td>
                                            <td>Joined</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {experts?.results.map((expert, idx) => (
                                            <ServiceTypeExpertsTableRow
                                                key={idx}
                                                expert={expert}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                                {experts?.count < 1 && (
                                    <Empty objectName={"Service Gigs"}/>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default ServiceTypeExperts