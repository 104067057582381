import React from 'react'
import UnderConstruction from '../feedback/UnderConstruction'

function EditServiceRequest(props) {
    return (
        <div>
            <label htmlFor="edit-service-request-modal" id='edit-service-request-open-btn' className="hidden">Edit Service request</label>
            <input type="checkbox" id="edit-service-request-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <label htmlFor="edit-service-request-modal" id='edit-service-request-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-4'>Edit Service request</h2>
                    <div className="p-7 mt-9">
                        <UnderConstruction/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditServiceRequest