import React from 'react'
import utils from '../../utils'
import { ReactComponent as Gift } from '../../assets/ph_gift.svg'
import { ReactComponent as Vip } from '../../assets/tdesign_user-vip.svg'
import { ReactComponent as GiftOpen } from '../../assets/fluent_gift-open-16-regular.svg'
import { ReactComponent as GiftCard } from '../../assets/fluent_gift-card-24-regular.svg'

function PromotionsSummary({statistics}) {
    return (
        <div className='p-3'>
            {statistics && (
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3'>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.created_promotions)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Created Promotions</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <Gift className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.participants)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Total Participants</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <Vip className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.active_promotions)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Active Promotions</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <GiftOpen className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.inactive_promotions)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Inactive Promotions</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <GiftCard className='w-10 h-10'/>
                            </div>
                        </figure>
                    </div>
                </div>
            )}            
        </div>
    )
}

export default PromotionsSummary