import React, { useEffect } from 'react'
import Empty from '../feedback/Empty'
import { FaCar } from 'react-icons/fa'

function ServiceVehicles(props) {
    const service = props.service
    useEffect(() => {}, [service])
    return (
        <div className="rounded-md border pb-7">
            <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                <p className="font-bold text-lg">Vehicles</p>    
            </div>
            <div className="p-2">
                {service?.owner?.buyer_account_type !==  "Car Dealer" ? (
                    <div>
                        {service?.owner_vehicles?.map((vehicle, idx) => (
                            <div
                                key={idx} 
                                className='mb-3 border rounded-md p-2'>
                                <div className='flex items-center'>
                                    <div className="flex-grow">
                                        <div className="flex gap-3 items-center">
                                            <div>
                                                <FaCar className='text-accent w-14 h-16'/>
                                            </div>
                                            <div>
                                                <p className='mb-1'>{vehicle?.vehicle_brand}</p>
                                                <div className="flex gap-3">
                                                    <p>{vehicle?.vehicle_category?.name}</p>
                                                    <p>.</p>
                                                    <p>{vehicle?.reg_number}</p>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow-0">
                                        <p>{vehicle?.vehicle_color?.name}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {service?.owner_vehicles?.length < 1 ? (
                            <Empty objectName="Owner Vehicles"/>
                        ):null}
                    </div>
                ):(
                    <div>
                        {service?.car_dealer_num_vehicles ? (
                            <div>
                                <p>Number of Car Dealer Vehicles: {service?.car_dealer_num_vehicles}</p>
                            </div>
                        ):(
                            <div>
                                <p>Not Recorded</p>
                            </div>
                        )}
                    </div>
                )}
            </div>            
        </div>
    )
}

export default ServiceVehicles