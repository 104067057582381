import React, { useEffect } from 'react'
import { AiOutlineUser } from "react-icons/ai"
import utils from '../../utils'
import { ReactComponent as BusySvg } from '../../assets/Vector.svg'
import { ReactComponent as ActiveSvg } from '../../assets/mdi_user-online-outline (2).svg'
import { ReactComponent as InactiveSvg } from '../../assets/mdi_user-cancel-outline.svg'

function BuyersSummary(props) {
    const statistics = props.statistics
    useEffect(() => {}, [statistics])
    return (
        <div className='p-3'>
            {statistics && (
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3'>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.registered_buyers)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Registered Buyers</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <AiOutlineUser className='w-10 h-10' style={{color: "#42B883"}}/>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.busy_buyers)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Busy Buyers</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <BusySvg className='w-9 h-9'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.active_buyers)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Active Buyers</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <ActiveSvg className='w-10 h-10'/>
                            </div>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.inactive_buyers)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Inactive Buyers</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <div style={{color: "#42B883"}}>
                                <InactiveSvg className='w-10 h-10'/>
                            </div>
                        </figure>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BuyersSummary