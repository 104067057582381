// import utils from '../utils'
import apiClient from './AxiosHttp'

const api = '/api/v1'

const getBuyers = (params) => {
    return apiClient.get(`${api}/dashboard/buyers/${params}`)
}

const getBuyerStatistics = () => {
    return apiClient.get(`${api}/dashboard/buyers/statistics/`)
}

const getSpecificBuyerStatistics = (id) => {
    return apiClient.get(`${api}/dashboard/buyer/statistics/${id}/`)
}

const buyersServices = {
    getBuyers,
    getBuyerStatistics,
    getSpecificBuyerStatistics,
}

export default buyersServices