import React, { useEffect, useState } from 'react'
import { IoTicketOutline } from "react-icons/io5";
import Loading from '../feedback/Loading';
import toast from 'react-hot-toast';
import subscriptionServices from '../../services/SubscriptionService';
import utils from '../../utils';

function SubscriptionPricingModal(props) {
    const [priceId, setPriceId] = useState('')
    const [amount, setAmount] = useState(0)
    const subscriptionplan = props.subscriptionplan
    const activePeriod = props.activePeriod
    const [loading, setLoading] = useState(false)

    useEffect(() => {}, [subscriptionplan])
    useEffect(() => {
        if (activePeriod) {
            setAmount(activePeriod?.amount)
            setPriceId(activePeriod?.stripe_price_id)
        }
    }, [activePeriod])

    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        const updateToast = toast.loading("Updating Period Pricing")
        const data = {
            "amount": amount,
            "stripe_price_id": priceId
        }
        subscriptionServices.updateBillingPeriod(activePeriod?.id, data).then(
            res=> {
                console.log(res.data);
                toast.success("PeriodPricing Updated successfully", {
                    id:updateToast
                })
                setLoading(false)
                closeModal()
                props.updateBillingPeriods()
            },
            err => {
                console.log(err);
                setLoading(false)
                toast.error(utils?.toasterError, {
                    id:updateToast
                })
            }
        )
    }
    function closeModal(){
        const closeBtn = document.getElementById("subscription-pricing-modal-close-btn")
        closeBtn.click()
    }
    return (
        <div>
            <label htmlFor="subscription-pricing-modal" id='subscription-pricing-open-btn' className="hidden">Edit Subscription Pricing</label>
            <input type="checkbox" id="subscription-pricing-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-7/12 max-w-5xl">
                    <label htmlFor="subscription-pricing-modal" id='subscription-pricing-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-6'>Edit Subscription Pricing</h2>
                    <div className="flex justify-center mb-3">
                        <div className="p-3 rounded-full border-2">
                            <IoTicketOutline className='h-9 w-9 text-accent'/>
                        </div>
                    </div>
                    <p className="text-lg font-bold text-center mb-4">{subscriptionplan?.name}</p>
                    <form onSubmit={submitHandler}>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Billing Period *</span>
                            </div>
                            <div className="badge badge-accent badge-outline p-3 py-4">{activePeriod?.period_name?.name}</div>
                        </div>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Currency *</span>
                            </div>
                            <div className="badge badge-accent badge-outline p-3 py-4">{activePeriod?.allowed_currency?.name}</div>
                        </div>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Stripe Price ID *</span>
                            </div>
                            <input 
                                value={priceId}
                                onChange={(e) => setPriceId(e.target.value)}
                                type="text" 
                                required 
                                className='input input-bordered' 
                                placeholder='Enter Stripe Price ID' />
                        </div>
                        <div className="form-control mb-3">
                            <div className="label">
                                <span className='label-text'>Amount *</span>
                            </div>
                            <input 
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                type="number" 
                                required 
                                min={1}
                                className='input input-bordered' 
                                placeholder='Enter number' />
                        </div>
                        <div className='modal-action flex justify-end gap-3'>
                            {loading ? (<Loading/>):(
                                <>
                                    <div 
                                        onClick={() => closeModal()}
                                        className='btn btn-ghost text-accent'>Cancel</div>
                                    <button type='submit' className='btn text-white btn-accent'>Save</button>
                                </>
                            )}                                
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPricingModal