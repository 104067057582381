import React, {useContext, useEffect, useState } from 'react'
import utils from '../../utils'
import SessionContext from '../../context/SessionContext'

function Pagination(props) {
    const sessionCtx = useContext(SessionContext) 
    const limit = sessionCtx.limit
    const next = props.next
    const previous = props.previous
    const count = props.count

    useState(() => {}, [limit])

    useEffect(() => {}, [count, sessionCtx, next, previous])

    function changePage(page){
        props.changePageParam(page)
    }

    return (
        <div className='md:flex md:justify-end gap-3 mb-7'>
            <div className="join border">
                {previous && (
                    <button 
                        onClick={() => changePage(utils.getPageValue(previous))}
                        className="join-item btn">«</button>
                )}
                <button className="join-item btn">
                    Page {utils.getCurrentPage(previous, next)}
                </button>
                {next && (
                    <button 
                        onClick={() => changePage(utils.getPageValue(next))}
                        className="join-item btn">»</button>
                )}
            </div>
        </div>
    )
}

export default Pagination