import React, { useEffect, useState } from 'react'
import { GrUserWorker } from "react-icons/gr"
import { RiCircleFill } from "react-icons/ri";
import moment from 'moment'
import utils from '../../utils';
import { IoMdInformationCircleOutline } from "react-icons/io";
import ReassignExpertModal from './ReassignExpertModal';


function ServiceTimeLine(props) {
    const [isScheduled, setIsScheduled] = useState(false)
    const isCompleted = props.isCompleted
    const service = props.service

    useEffect(() => {}, [isCompleted])
    useEffect(() => {
        if (service) {
            if (
                (service?.schedule_date !== null)
                &&
                (service?.schedule_time !== null)
            ) {
                setIsScheduled(true)
            }
        }
    }, [service])
    useEffect(() => {}, [isScheduled])

    function openModal(){
        const openBtn = document.getElementById("reassign-expert-open-btn")
        openBtn.click()
    }
    return (
        <div className="rounded-md border pb-7">
            <ReassignExpertModal
                service={service}
            />
            <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                <p className="font-bold text-lg">Gig Timeline</p>    
            </div>
            <div className='p-2'>
                <div>
                    <ul className="timeline  timeline-snap-icon max-md:timeline-compact timeline-vertical">
                        <li>
                            <div className="timeline-middle">
                                <RiCircleFill className='h-6 w-6 text-accent'/>
                            </div>
                            <div className="timeline-start md:text-end mb-10 ">
                                <div className="flex flex-row-reverse gap-3">
                                    <div>
                                        {service?.owner && (
                                            <div className='avatar'>
                                                {utils.checkImage(service?.owner) ? (
                                                    <div className="w-11 rounded-full">
                                                        <img 
                                                            src={utils.baseUrl + service?.owner?.image} 
                                                            alt={service?.owner?.email} />
                                                    </div>
                                                ):(
                                                    <div>
                                                        <div className="avatar placeholder">
                                                            <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                <span className="text-xl">{utils.makeProfileLetters(service?.owner)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className="">{isScheduled ? 'Scheduled':'Started'} Service Request</div>
                                        <time className="text-gray-500">{moment(service?.creation_time).format("HH:MM:SS")}</time>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                        </li>
                        {service?.driving_to_pickup_time ? (
                            <li>
                                <hr />
                                <div className="timeline-middle">
                                    <RiCircleFill className='h-6 w-6 text-accent'/>
                                </div>
                                <div className="timeline-end mb-10 flex gap-3">
                                    <div>
                                        {service?.responder && (
                                            <div className='avatar'>
                                                {utils.checkImage(service?.responder) ? (
                                                    <div className="w-11 rounded-full">
                                                        <img 
                                                            src={utils.baseUrl + service?.responder?.image} 
                                                            alt={service?.responder?.email} />
                                                    </div>
                                                ):(
                                                    <div>
                                                        <div className="avatar placeholder">
                                                            <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                <span className="text-xl">{utils.makeProfileLetters(service?.responder)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className="">Driving to Pick Up</div>
                                        <time className="text-gray-500">{moment(service?.driving_to_pickup_time).format("HH:MM:SS")}</time>
                                    </div>
                                </div>
                                <hr />
                            </li>
                        ):null}
                        {service?.arrival_at_pickup_time ? (
                            <li>
                                <hr />
                                <div className="timeline-middle">
                                    <RiCircleFill className='h-6 w-6 text-accent'/>
                                </div>
                                <div className="timeline-end mb-10 flex gap-3">
                                    <div>
                                        {service?.responder && (
                                            <div className='avatar'>
                                                {utils.checkImage(service?.responder) ? (
                                                    <div className="w-11 rounded-full">
                                                        <img 
                                                            src={utils.baseUrl + service?.responder?.image} 
                                                            alt={service?.responder?.email} />
                                                    </div>
                                                ):(
                                                    <div>
                                                        <div className="avatar placeholder">
                                                            <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                <span className="text-xl">{utils.makeProfileLetters(service?.responder)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className="">Arrival at Pick Up</div>
                                        <time className="text-gray-500">{moment(service?.arrival_at_pickup_time).format("HH:MM:SS")}</time>
                                    </div>
                                </div>
                                <hr />
                            </li>
                        ):null}
                        {service?.loading_time ? (
                            <li>
                                <hr />
                                <div className="timeline-middle">
                                    <RiCircleFill className='h-6 w-6 text-accent'/>
                                </div>
                                <div className="timeline-end mb-10 flex gap-3">
                                    <div>
                                        {service?.responder && (
                                            <div className='avatar'>
                                                {utils.checkImage(service?.responder) ? (
                                                    <div className="w-11 rounded-full">
                                                        <img 
                                                            src={utils.baseUrl + service?.responder?.image} 
                                                            alt={service?.responder?.email} />
                                                    </div>
                                                ):(
                                                    <div>
                                                        <div className="avatar placeholder">
                                                            <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                <span className="text-xl">{utils.makeProfileLetters(service?.responder)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className="">Loading Time</div>
                                        <time className="text-gray-500">{moment(service?.loading_time).format("HH:MM:SS")}</time>
                                    </div>
                                </div>
                                <hr />
                            </li>
                        ):null}
                        {service?.driving_to_destination_time ? (
                            <li>
                                <hr />
                                <div className="timeline-middle">
                                    <RiCircleFill className='h-6 w-6 text-accent'/>
                                </div>
                                <div className="timeline-end mb-10 flex gap-3">
                                    <div>
                                        {service?.responder && (
                                            <div className='avatar'>
                                                {utils.checkImage(service?.responder) ? (
                                                    <div className="w-11 rounded-full">
                                                        <img 
                                                            src={utils.baseUrl + service?.responder?.image} 
                                                            alt={service?.responder?.email} />
                                                    </div>
                                                ):(
                                                    <div>
                                                        <div className="avatar placeholder">
                                                            <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                <span className="text-xl">{utils.makeProfileLetters(service?.responder)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className="">Driving to Destination</div>
                                        <time className="text-gray-500">{moment(service?.driving_to_destination_time).format("HH:MM:SS")}</time>
                                    </div>
                                </div>
                                <hr />
                            </li>
                        ):null}
                        {service?.cancel_time ? (
                            <li>
                                <hr />
                                <div className="timeline-middle">
                                    <RiCircleFill className='h-6 w-6 text-accent'/>
                                </div>
                                <div className="timeline-start md:text-end mb-10 ">
                                    <div className="flex flex-row-reverse gap-3">
                                        <div>
                                            {service?.owner && (
                                                <div className='avatar'>
                                                    {utils.checkImage(service?.owner) ? (
                                                        <div className="w-11 rounded-full">
                                                            <img 
                                                                src={utils.baseUrl + service?.owner?.image} 
                                                                alt={service?.owner?.email} />
                                                        </div>
                                                    ):(
                                                        <div>
                                                            <div className="avatar placeholder">
                                                                <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                    <span className="text-xl">{utils.makeProfileLetters(service?.owner)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <div className="">Cancel Time</div>
                                            <time className="text-gray-500">{moment(service?.cancel_time).format("HH:MM:SS")}</time>
                                        </div>
                                    </div>
                                    {service?.cancel_reason ? (
                                        <div className='p-2 rounded-md text-red-600 border border-red-600'>
                                            <div className="flex gap-3 items-center">
                                                <div><IoMdInformationCircleOutline className="h-5 w-5"/></div>
                                                <div>Reason</div>
                                            </div>
                                            <p className=''>{service?.cancel_reason}</p>
                                        </div>
                                    ):null}
                                </div>
                                <hr/>
                            </li>
                        ): null}
                        {service?.finish_time ? (
                            <li>
                                <hr />
                                <div className="timeline-middle">
                                    <RiCircleFill className='h-6 w-6 text-accent'/>
                                </div>
                                <div className="timeline-end mb-10 flex gap-3">
                                    <div>
                                        {service?.responder && (
                                            <div className='avatar'>
                                                {utils.checkImage(service?.responder) ? (
                                                    <div className="w-11 rounded-full">
                                                        <img 
                                                            src={utils.baseUrl + service?.responder?.image} 
                                                            alt={service?.responder?.email} />
                                                    </div>
                                                ):(
                                                    <div>
                                                        <div className="avatar placeholder">
                                                            <div className="bg-neutral text-neutral-content rounded-full w-11">
                                                                <span className="text-xl">{utils.makeProfileLetters(service?.responder)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <div className="">Finish Time</div>
                                        <time className="text-gray-500">{moment(service?.finish_time).format("HH:MM:SS")}</time>
                                    </div>
                                </div>
                                <hr />
                            </li>
                        ):null}
                    </ul>
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={() => openModal()}
                        className={isCompleted ? 'btn btn-ghost btn-disabled': 'btn btn-ghost'}
                        style={{color: "#42B883"}}>
                            <GrUserWorker className='mr-0.5'/>
                        <span className='mr-0.5'>{service?.responder ? 'Reassign':'Assign'}</span>
                        Expert
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ServiceTimeLine