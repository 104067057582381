import React, { useEffect, useState } from 'react'
import { BiSolidEditAlt } from "react-icons/bi";
import moment from 'moment'
import EditServiceRequest from './EditServiceRequest';

function ServiceDetails(props) {
    const [isScheduled, setIsScheduled] = useState(false)
    const service = props.service

    useEffect(() => {
        if (service) {
            if (
                (service?.schedule_date !== null)
                &&
                (service?.schedule_time !== null)
            ) {
                setIsScheduled(true)
            }
        }
    }, [service])
    useEffect(() => {}, [isScheduled])

    function openModal(){
        const openBtn = document.getElementById("edit-service-request-open-btn")
        openBtn.click()
    }
    return (
        <div className="rounded-md border pb-7">
            <EditServiceRequest

            />
            <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                <p className="font-bold text-lg">Service Details</p>    
                <div>
                    <button 
                        onClick={() => openModal()}
                        className='btn btn-ghost'
                        style={{color: "#42B883"}}><BiSolidEditAlt className=''/> Edit</button>
                </div>
            </div>
            <div className='p-2 space-y-3'>
                <div className="flex justify-between">
                    <p className='text-gray-500'>Service Name</p>
                    <p>{service?.service_type?.name}</p>
                </div>
                <div className="flex justify-between">
                    <p className='text-gray-500'>Cost</p>
                    <p>
                        {service?.payment_object ? (
                            <span>
                                {service?.payment_object ? (
                                    <span>{service?.payment_currency} .</span>
                                ):null} {service?.payment_amount}
                            </span>
                        ):(
                            <span>-</span>
                        )}
                    </p>
                </div>
                <div className="flex justify-between">
                    <p className='text-gray-500'>Number of Vehicles</p>
                    <p>
                        {service?.owner?.buyer_account_type === "Motorist" ? (
                            <span>{service?.owner_vehicles?.length}</span>
                        ):(
                            <span>{service?.car_dealer_num_vehicles}</span>
                        )}
                    </p>
                </div>
                <div className="flex justify-between">
                    <p className='text-gray-500'>Pick Up Location</p>
                    <p>{service?.from_formated_address}</p>
                </div>
                <div className="flex justify-between">
                    <p className='text-gray-500'>Destination</p>
                    <p>{service?.destination_formated_address}</p>
                </div>
                <div className="flex justify-between">
                    <p className='text-gray-500'>Added Stops</p>
                    <p>
                        {service?.request_stop?.length ? (
                            <span>
                                {service?.request_stop?.map((stop, idx) => (
                                    <span 
                                        key={idx}
                                        className='comma'>
                                            {stop?.formated_address}
                                    </span>
                                ))}
                            </span>
                        ):(
                            <span>None</span>
                        )}
                    </p>
                </div>
                <div className="flex justify-between">
                    <p className='text-gray-500'>Service Request Type</p>
                    <p>
                        {isScheduled ? (
                            <span>Scheduled for Later</span>
                        ):(
                            <span>Immediate Service</span>
                        )}
                    </p>
                </div>

                {isScheduled ? (
                    <div className="flex justify-between">
                        <p className='text-gray-500'>Scheduled Start</p>
                        <p className='flex gap-1'>
                            <span>{service?.schedule_time}</span>
                            <span className='font-bold'>.</span>
                            <span>{moment(service?.schedule_date).format("Do MMMM YYYY")}</span>
                        </p>
                    </div>
                ): null}
                
                <div className="flex justify-between">
                    <p className='text-gray-500'>Request At</p>
                    <p className='flex gap-1'>
                        <span>{moment(service?.creation_time).format("HH:MM:SS")}</span>
                        <span className='font-bold'>.</span>
                        <span>{moment(service?.creation_time).format("Do MMMM YYYY")}</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ServiceDetails