import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import { useParams } from 'react-router-dom'
import subscriptionServices from '../../services/SubscriptionService'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import { IoTicketOutline } from "react-icons/io5";
import { TfiLayoutGrid3 } from "react-icons/tfi";
import PlanDetails from '../../components/subscriptions/PlanDetails'
import SubscriberDetails from '../../components/subscriptions/SubscriberDetails'

function SubscriptionViewPage() {
    const [loading, setLoading] = useState(true)
    const [subscriptionplan, setSubscriptionPlan] = useState()
    const [error, setError] = useState()
    const idParam = useParams()
    const [currentTab, setCurrentTab] = useState(0)
    const [billingPeriods, setBillingPeriods] = useState()

    useEffect(() => {
        if (idParam?.id) {
            getBillingPeriods()
            getSubscriptionPlan(idParam?.id)
        }
    }, [idParam])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [subscriptionplan])
    useEffect(() => {}, [currentTab])
    useEffect(() => {}, [billingPeriods])
    
    const getSubscriptionPlan = (id) => {
        subscriptionServices.getSubscriptionPlan(id).then(
            res => {
                setSubscriptionPlan(res.data)
                setLoading(false)
            }, 
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }
    const getBillingPeriods = () => {
        subscriptionServices.getBillingPeriods().then(
            res => {
                setBillingPeriods(res.data)
            },
            err => {
                console.log(err);
            }
        )
    }
    function updateSubscriptionPlan(){
        setError()
        setLoading(true)
        getSubscriptionPlan(idParam?.id)
    }
    function updateBillingPeriods(){
        setLoading(true)
        getBillingPeriods()
        setLoading(false)
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Subscription - {utils.siteName}</title>
            </Helmet>
            <div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div className='p-3'>
                                <Helmet>
                                    <title>{subscriptionplan?.name} - {utils.siteName}</title>
                                </Helmet>
                                <div className='rounded-md border p-5 py-7 mb-3'>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div className='flex gap-4 items-center'>
                                                <div className="p-3 rounded-full border-2">
                                                    <IoTicketOutline className='h-9 w-9 text-accent'/>
                                                </div>
                                                <div>
                                                    <p className='font-bold'>{subscriptionplan?.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="dropdown">
                                                <label tabIndex="0" className="flex items-center gap-3 btn btn-ghost text-accent">
                                                    <TfiLayoutGrid3 className='h-6 w-6'/>
                                                    <p className='font-bold'>Actions</p>
                                                </label>
                                                <ul 
                                                    tabIndex="0"
                                                    className="menu menu-vertical dropdown-content mt-3 shadow bg-base-200 rounded-box text-start">
                                                    <li className='p-2 hover:cursor-pointer'>Delete Plan</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:flex gap-5'>
                                    <div className="md:w-4/12 lg:w-3/12 rounded-md">
                                        <div className="join join-vertical w-full">
                                            <div 
                                                onClick={() => setCurrentTab(0)}
                                                className={
                                                    currentTab === 0 
                                                    ? 
                                                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                                                    : 
                                                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                                                }>
                                                Plan Overview
                                            </div>
                                            <div
                                                 onClick={() => setCurrentTab(1)}
                                                className={
                                                    currentTab === 1 
                                                    ? 
                                                    'join-item text-pretty text-center border py-4 hover:cursor-pointer text-accent'
                                                    : 
                                                    'join-item text-pretty text-center border py-4 hover:cursor-pointer'
                                                }>
                                                Subscribers
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:w-8/12 lg:w-9/12">
                                        {currentTab === 0 && (
                                            <PlanDetails
                                                subscriptionplan={subscriptionplan}
                                                currentTab={currentTab}
                                                billingPeriods={billingPeriods}
                                                updateBillingPeriods={updateBillingPeriods}
                                                updateSubscriptionPlan={updateSubscriptionPlan}
                                            />
                                        )}
                                        {currentTab === 1 && (
                                            <SubscriberDetails
                                                subscriptionplan={subscriptionplan}
                                                currentTab={currentTab}
                                                idParam={idParam}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default SubscriptionViewPage