import React, { useEffect } from 'react'
import utils from '../../utils'
import { IoBriefcaseOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import { MdOutlineCalendarMonth } from "react-icons/md";

function ServiceSummary(props) {
    const statistics = props.statistics
    useEffect(() => {}, [statistics])
    return (
        <div className='p-3'>
            {statistics && (
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3'>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.completed_gigs)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Completed Gigs</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <IoBriefcaseOutline className='w-10 h-10' style={{color: "#42B883"}}/>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.ongoing_gigs)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Ongoing Gigs</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <IoSettingsOutline className='w-10 h-10' style={{color: "#42B883"}}/>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.cancelled_gigs)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Cancelled Gigs</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <MdOutlineCancel className='w-10 h-10' style={{color: "#42B883"}}/>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.scheduled_gigs)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Scheduled Gigs</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <MdOutlineCalendarMonth className='w-10 h-10' style={{color: "#42B883"}}/>
                        </figure>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ServiceSummary