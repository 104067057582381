import React, { useEffect, useState } from 'react'
import { HiOutlineUserAdd } from "react-icons/hi";
import RegisterExpertModal from './RegisterExpertModal';
import { useSearchParams } from 'react-router-dom'
import { IoIosSearch } from "react-icons/io"
import { BiSort } from "react-icons/bi";
import ExpertTableRow from './ExpertTableRow';
import Pagination from '../nav/Pagination';
import Select from 'react-select';
import { IoTrashSharp } from "react-icons/io5";

const options = [
    { value: 'none', label: 'Order By' },
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
]

function ExpertsTable(props) {
    const [hasSearched, setHasSearched] = useState(false)
    const [hasFiltered, setHasFiltered] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchText, setSearchText] = useState('')
    const [debouncedInputValue, setDebouncedInputValue] = React.useState("");
    const experts = props.experts
    const searchInputParam = searchParams.get("search")
    const orderingParam = searchParams.get("ordering")
    const [selectedValue, setSelectedValue] = useState(null);
    const [showSearch, setShowSearch] = useState(false)

    useEffect(() => {}, [experts])
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
        setDebouncedInputValue(searchText);
        }, 1000);
        return () => clearTimeout(delayInputTimeoutId);
    }, [searchText])
    useEffect(() => {
        if (debouncedInputValue.length) {
            setHasSearched(true)
            setSearchParams((params) => {
                params.set("search", debouncedInputValue)
                return params
            })
        }
    }, [debouncedInputValue, setSearchParams])
    useEffect(() => {
        if (searchInputParam && (! hasSearched)) {
            setSearchText(searchInputParam)
        }
    }, [searchInputParam, hasSearched])
    useEffect(() => {}, [hasSearched])
    useEffect(() => {
        if (orderingParam && (!hasFiltered)) {
            if (orderingParam === 'creation_time') {
                const defaultOption = options.find(option => option.value === 'asc')
                setSelectedValue(defaultOption)
            } else if (orderingParam === '-creation_time') {
                const defaultOption = options.find(option => option.value === 'desc')
                setSelectedValue(defaultOption)
            }
        }
    }, [orderingParam, hasFiltered])
    useEffect(() => {
        if (!orderingParam) {
            const defaultOption = options.find(option => option.value === 'none')
            setSelectedValue(defaultOption);
        }
    }, [orderingParam])

    const handleChange = (selectedOption) => {
        setHasFiltered(true)
        setSelectedValue(selectedOption);
    };
    useEffect(() => {
        if (selectedValue) {
            if (selectedValue?.value === 'asc') {
                setSearchParams((params) => {
                    params.set("ordering", 'creation_time')
                    return params
                })
            }else if (selectedValue?.value === 'desc') {
                setSearchParams((params) => {
                    params.set("ordering", '-creation_time')
                    return params
                })
            } else if (selectedValue?.value === 'none') {
                setSearchParams((params) => {
                    params.delete("ordering")
                    return params
                })
            }
        }
    }, [selectedValue, setSearchParams])

    function openModal(){
        const openBtn = document.getElementById("expert-create-open-btn")
        openBtn.click()
    }
    function resetSearch(){
        setSearchText('')
        setDebouncedInputValue('')
        setSearchParams((params) => {
            params.delete("search")
            return params
        })
        setSearchParams((params) => {
            params.delete("ordering")
            return params
        })
        props.getExperts('')
    }
    return (
        <div className='p-3'>
            <RegisterExpertModal/>
            <div className='border p-4 rounded-md'>
                <div className="flex justify-between items-center mb-5">
                    <p className='font-bold md:text-lg text-gray-600'>Registered Experts</p>
                    <button 
                        onClick={() => openModal()}
                        className='btn btn-accent text-white'><HiOutlineUserAdd className='w-5 h-5'/> Add Expert</button>
                </div>
                <div className='mb-3 flex justify-between items-center gap-6'>
                    <div className='flex gap-4 items-center'>
                        {showSearch ? (
                            <div>
                                <div className="flex space-x-3 md:space-x-4 py-2 px-3 border rounded-lg mb-4 items-center">
                                    <IoIosSearch className='w-6 h-6' style={{color: "#42B883"}}/>
                                    <input 
                                        value={searchText}
                                        type='search' 
                                        onChange={(e) => setSearchText(e.target.value)}
                                        placeholder='Search by name, email, full_name' 
                                        className='input'/>
                                </div>
                            </div>
                        ):(
                            <div>
                                <div 
                                    onClick={() => setShowSearch(!showSearch)}
                                    className='btn btn-outline hover:cursor-pointer'>
                                    <IoIosSearch className='w-5 h-5'/>
                                </div>
                            </div>
                        )}
                        <div className='flex items-center'>
                            <BiSort className='w-8 h-8' color='#42B883'/>
                            <Select
                                className='select select-ghost'
                                value={selectedValue}
                                onChange={handleChange}
                                options={options}
                            />
                        </div>
                    </div>
                    <div>
                        {searchInputParam && (
                            <button 
                                onClick={() => resetSearch()}
                                className='btn btn-primary btn-outline'>
                                <IoTrashSharp/> Clear Search</button>
                        )}
                    </div>
                </div>
                <div>
                    <div className="overflow-x-auto mb-9">
                        <table className="table table-zebra table-xs">
                            <thead>
                                <tr className='te text-sm'>
                                    <td>Name</td>
                                    <td>Location</td>
                                    <td>Business Name</td>
                                    <td>Employees</td>
                                    {/* <td>Status</td> */}
                                    <td>Gigs Completed</td>
                                    <td>Ratings</td>
                                </tr>
                            </thead>
                            <tbody>
                                {experts?.results?.map((expert, idx) => (
                                    <ExpertTableRow
                                        key={idx}
                                        expert={expert}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>  
                </div>
                {experts && (
                    <Pagination
                        count={experts?.count}
                        next={experts?.next}
                        previous={experts?.previous}
                        changePageParam={props.changePageParam}
                        changeLimitParam={props.changeLimitParam}
                    />
                )}
            </div>
        </div>
    )
}

export default ExpertsTable