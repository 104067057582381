import React, { useEffect, useState } from 'react'
import { IoTicketOutline } from "react-icons/io5";
import Loading from '../feedback/Loading';
import toast from 'react-hot-toast';
import subscriptionServices from '../../services/SubscriptionService';

function SubscriptionBenefitsModal(props) {
    const subscriptionplan = props.subscriptionplan
    const [discountPercent, setDiscountPercent] = useState(0)
    const [sharable, setSharable] = useState(0)
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        if (subscriptionplan) {
            setSharable(subscriptionplan?.usage_count)
            setDiscountPercent(subscriptionplan?.discount)
        }
    }, [subscriptionplan])

    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        const updateToast = toast.loading("Updating Subscription plan")
        const data = {
            "discount": discountPercent,
            "usage_count": sharable
        }
        subscriptionServices.updateSubscriptionPlan(subscriptionplan?.id, data).then(
            res => {
                console.log(res.data);
                toast.success("Subscription Plan Updated Successfully", {
                    id:updateToast
                })
                setLoading(false)
                closeModal()
                props.updateSubscriptionPlan()
            },
            err => {
                console.log(err);
                toast.error("Something went wrong", {
                    id: updateToast
                })
                setLoading(false)
            }
        )
    }
    function closeModal(){
        const closeBtn = document.getElementById("benefit-edit-modal-close-btn")
        closeBtn.click()
    }
    return (
        <div>
            <label htmlFor="benefit-edit-modal" id='benefit-edit-open-btn' className="hidden">Edit Subscription Benefits</label>
            <input type="checkbox" id="benefit-edit-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-7/12 max-w-5xl">
                    <label htmlFor="benefit-edit-modal" id='benefit-edit-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-6'>Edit Subscription Benefits</h2>
                    <div className="flex justify-center mb-3">
                        <div className="p-3 rounded-full border-2">
                            <IoTicketOutline className='h-9 w-9 text-accent'/>
                        </div>
                    </div>
                    <p className="text-lg font-bold text-center mb-4">{subscriptionplan?.name}</p>
                    <div>
                        <form onSubmit={submitHandler}>
                            <div className="form-control mb-3">
                                <div className="label">
                                    <span className='label-text'>Disount Percent *</span>
                                </div>
                                <input 
                                    value={discountPercent}
                                    onChange={(e) => setDiscountPercent(e.target.value)}
                                    type="number" 
                                    required 
                                    min={1}
                                    className='input input-bordered' 
                                    placeholder='Enter percent discount' />
                            </div>
                            <div className="form-control mb-3">
                                <div className="label">
                                    <span className='label-text'>Sharable Among *</span>
                                </div>
                                <input 
                                    value={sharable}
                                    onChange={(e) => setSharable(e.target.value)}
                                    type="number" 
                                    required 
                                    min={1}
                                    className='input input-bordered' 
                                    placeholder='Enter number' />
                            </div>
                            <div className='modal-action flex justify-end gap-3'>
                                {loading ? (<Loading/>):(
                                    <>
                                        <div 
                                            onClick={() => closeModal()}
                                            className='btn btn-ghost text-accent'>Cancel</div>
                                        <button type='submit' className='btn text-white btn-accent'>Save</button>
                                    </>
                                )}                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionBenefitsModal