import React, { useEffect } from 'react'
import { CiEdit } from "react-icons/ci";
import SubscriptionBenefitsModal from './SubscriptionBenefitsModal';

function Benefits(props) {
    const subscriptionplan =props.subscriptionplan
    
    useEffect(() => {}, [subscriptionplan])

    function openModal(){
        const openBtn = document.getElementById("benefit-edit-open-btn")
        openBtn.click()
    }
    return (
        <div className='mb-5'>
            <div className="rounded-md border pb-7">
                <SubscriptionBenefitsModal
                    updateSubscriptionPlan={props.updateSubscriptionPlan}
                    subscriptionplan={subscriptionplan}
                />
                <div className="py-5 border-b mb-3 p-4">
                    <p className="font-bold text-lg">Benefits</p>
                </div>
                <div className="p-4">
                    <div className="">
                        <div className="overflow-x-auto">
                            <table className="table table-sm">
                                <thead>
                                    <tr className='text-sm'>
                                        <td>#</td>
                                        <td>Benefit</td>
                                        <td>Description</td>
                                        <td>Actions</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>{subscriptionplan?.discount}%</td>
                                        <td>Percentage discount off</td>
                                        <td>
                                            <button
                                                onClick={() => openModal()}
                                                className='btn btn-ghost text-accent'
                                            ><CiEdit/> Edit</button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>{subscriptionplan?.usage_count}</td>
                                        <td>Maximum Number of People sharable with</td>
                                        <td>
                                            <button
                                                onClick={() => openModal()}
                                                className='btn btn-ghost text-accent'
                                            ><CiEdit/> Edit</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefits