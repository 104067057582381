import React, { useEffect, useState } from 'react'
import { BiSort } from "react-icons/bi";
import { IoIosSearch } from "react-icons/io";
import Loading from '../feedback/Loading';
import FetchError from '../feedback/FetchError';
import { useSearchParams } from 'react-router-dom';
import Pagination from '../nav/Pagination';
import financeServices from '../../services/FInanceServices';
import Empty from '../feedback/Empty';
import ServiceTxTableRow from './ServiceTxTableRow';
import Select from 'react-select';
import { IoTrashSharp } from "react-icons/io5";

const options = [
    { value: 'none', label: 'Order By' },
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
]

function ServiceTransactions(props) {
    const [hasSearched, setHasSearched] = useState(false)
    const [hasFiltered, setHasFiltered] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchText, setSearchText] = useState('')
    const [debouncedInputValue, setDebouncedInputValue] = React.useState("");
    const searchInputParam = searchParams.get("search")
    const orderingParam = searchParams.get("ordering")
    const [selectedValue, setSelectedValue] = useState(null);
    const currentTab = props.currentTab
    const [payments, setPayments] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [showSearch, setShowSearch] = useState(false)

    useEffect(() => {
        if (currentTab === 0) {
            const params = searchParams.toString()
            getPayments(params ? `?${params}` : '')
        }
    }, [currentTab, searchParams])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [payments])

    const getPayments = (params) => {
        setLoading(true)
        setError()
        financeServices.getRecentTransactions(params ? `${params}` : '').then(
            res => {
                setPayments(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }

    function changeLimitParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("limit", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("limit")
                return params
            })
        }
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
        setDebouncedInputValue(searchText);
        }, 1000);
        return () => clearTimeout(delayInputTimeoutId);
    }, [searchText])
    useEffect(() => {
        if (debouncedInputValue.length) {
            setHasSearched(true)
            setSearchParams((params) => {
                params.set("search", debouncedInputValue)
                return params
            })
        }
    }, [debouncedInputValue, setSearchParams])
    useEffect(() => {
        if (searchInputParam && (!hasSearched)) {
            setSearchText(searchInputParam)
        }
    }, [searchInputParam, hasSearched])
    useEffect(() => {}, [hasSearched])
    useEffect(() => {
        if (orderingParam && (!hasFiltered)) {
            if (orderingParam === 'creation_time') {
                const defaultOption = options.find(option => option.value === 'asc')
                setSelectedValue(defaultOption)
            } else if (orderingParam === '-creation_time') {
                const defaultOption = options.find(option => option.value === 'desc')
                setSelectedValue(defaultOption)
            }
        }
    }, [orderingParam, hasFiltered])
    useEffect(() => {
        if (!orderingParam) {
            const defaultOption = options.find(option => option.value === 'none')
            setSelectedValue(defaultOption);
        }
    }, [orderingParam])

    const handleChange = (selectedOption) => {
        setHasFiltered(true)
        setSelectedValue(selectedOption);
    };

    useEffect(() => {
        if (selectedValue) {
            if (selectedValue?.value === 'asc') {
                setSearchParams((params) => {
                    params.set("ordering", 'creation_time')
                    return params
                })
            }else if (selectedValue?.value === 'desc') {
                setSearchParams((params) => {
                    params.set("ordering", '-creation_time')
                    return params
                })
            } else if (selectedValue?.value === 'none') {
                setSearchParams((params) => {
                    params.delete("ordering")
                    return params
                })
            }
        }
    }, [selectedValue, setSearchParams])

    function resetSearch(){
        setShowSearch(false)
        setSearchText('')
        setDebouncedInputValue('')
        setSearchParams((params) => {
            params.delete("search")
            return params
        })
        setSearchParams((params) => {
            params.delete("ordering")
            return params
        })
    }
    return (
        <div>
            <div className='border rounded-md pb-3'>
                <div className="py-5 border-b mb-1 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Recent Transactions</p>
                </div>
                <div className='flex justify-between items-center gap-6 p-3'>
                    <div className='flex gap-4 items-center'>
                        {showSearch ? (
                            <div>
                                <div className="flex space-x-3 md:space-x-4 py-2 px-3 border rounded-lg mb-4 items-center">
                                    <IoIosSearch className='w-6 h-6' style={{color: "#42B883"}}/>
                                    <input 
                                        value={searchText}
                                        type='search' 
                                        onChange={(e) => setSearchText(e.target.value)}
                                        placeholder='Search by buyer, expert' 
                                        className='input'/>
                                </div>
                            </div>
                        ):(
                            <div>
                                <div 
                                    onClick={() => setShowSearch(!showSearch)}
                                    className='btn btn-outline hover:cursor-pointer'>
                                    <IoIosSearch className='w-5 h-5'/>
                                </div>
                            </div>
                        )}
                        <div className='flex items-center'>
                            <BiSort className='w-8 h-8' color='#42B883'/>
                            <Select
                                className='select select-ghost'
                                value={selectedValue}
                                onChange={handleChange}
                                options={options}
                            />
                        </div>
                    </div>
                    <div>
                        {searchInputParam && (
                            <button 
                                onClick={() => resetSearch()}
                                className='btn btn-primary btn-outline'>
                                <IoTrashSharp/> Clear Search</button>
                        )}
                    </div>
                </div>
                {loading ? (
                    <div className='flex items-center justify-center h-44'>
                        <Loading/>
                    </div>
                ):(
                    <div className='p-3'>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <div className="overflow-x-auto mb-6">
                                    <table className="table table-zebra">
                                        <thead>
                                            <tr>
                                                <td>Buyer</td>
                                                <td>Service</td>
                                                <td>Date</td>
                                                <td>Amount</td>
                                                <td>Disbursed</td>
                                                <td>Manage</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments?.results?.map((payment, idx) => (
                                                <ServiceTxTableRow
                                                    key={idx}
                                                    payment={payment}/>  
                                            ))}
                                        </tbody>
                                    </table>
                                    {payments?.count < 1 && (
                                        <Empty objectName={"Recent Transaction"}/>
                                    )}
                                </div>
                                {payments && (
                                    <Pagination
                                        count={payments?.count}
                                        next={payments?.next}
                                        previous={payments?.previous}
                                        changeLimitParam={changeLimitParam}
                                        changePageParam={changePageParam}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )} 
            </div>
        </div>
    )
}

export default ServiceTransactions