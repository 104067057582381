import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { BiSort } from "react-icons/bi";
import { IoIosSearch } from "react-icons/io";
import Empty from '../feedback/Empty';
import Pagination from '../nav/Pagination';
import ServiceTableRow from './ServiceTableRow';
import Select from 'react-select';
import { IoTrashSharp } from "react-icons/io5";

const options = [
    { value: 'none', label: 'Order By' },
    { value: 'asc', label: 'Ascending' },
    { value: 'desc', label: 'Descending' },
]

function ServicesTable(props) {
    const [hasSearched, setHasSearched] = useState(false)
    const [hasFiltered, setHasFiltered] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchText, setSearchText] = useState('')
    const [debouncedInputValue, setDebouncedInputValue] = React.useState("");
    const searchInputParam = searchParams.get("search")
    const orderingParam = searchParams.get("ordering")
    const [selectedValue, setSelectedValue] = useState(null);
    const services = props.services
    const location = useLocation()
    const [showSearch, setShowSearch] = useState(false)

    useEffect(() => {}, [location])
    useEffect(() => {}, [services])

    useEffect(() => {
        const delayInputTimeoutId = setTimeout(() => {
        setDebouncedInputValue(searchText);
        }, 1000);
        return () => clearTimeout(delayInputTimeoutId);
    }, [searchText])
    useEffect(() => {
        if (debouncedInputValue.length) {
            setHasSearched(true)
            setSearchParams((params) => {
                params.set("search", debouncedInputValue)
                return params
            })
        }
    }, [debouncedInputValue, setSearchParams])
    useEffect(() => {
        if (searchInputParam && (!hasSearched)) {
            setSearchText(searchInputParam)
        }
    }, [searchInputParam, hasSearched])
    useEffect(() => {}, [hasSearched])
    useEffect(() => {
        if (orderingParam && (!hasFiltered)) {
            if (orderingParam === 'creation_time') {
                const defaultOption = options.find(option => option.value === 'asc')
                setSelectedValue(defaultOption)
            } else if (orderingParam === '-creation_time') {
                const defaultOption = options.find(option => option.value === 'desc')
                setSelectedValue(defaultOption)
            }
        }
    }, [orderingParam, hasFiltered])
    useEffect(() => {
        if (!orderingParam) {
            const defaultOption = options.find(option => option.value === 'none')
            setSelectedValue(defaultOption);
        }
    }, [orderingParam])

    const handleChange = (selectedOption) => {
        setHasFiltered(true)
        setSelectedValue(selectedOption);
    };

    useEffect(() => {
        if (selectedValue) {
            if (selectedValue?.value === 'asc') {
                setSearchParams((params) => {
                    params.set("ordering", 'creation_time')
                    return params
                })
            }else if (selectedValue?.value === 'desc') {
                setSearchParams((params) => {
                    params.set("ordering", '-creation_time')
                    return params
                })
            } else if (selectedValue?.value === 'none') {
                setSearchParams((params) => {
                    params.delete("ordering")
                    return params
                })
            }
        }
    }, [selectedValue, setSearchParams])

    function resetSearch(){
        setSearchText('')
        setDebouncedInputValue('')
        setSearchParams((params) => {
            params.delete("search")
            return params
        })
        setSearchParams((params) => {
            params.delete("ordering")
            return params
        })
    }
    return (
        <div className='p-3'>
            <div className='border p-4 rounded-md'>
                <p className='font-bold md:text-lg text-gray-600 mb-4'>
                    {location.pathname === '/services/ongoing' ? 'Ongoing' : null}
                    {location.pathname === '/services/scheduled' ? 'Scheduled' : null}
                    {location.pathname === '/services/completed' ? 'Completed' : null}
                    {location.pathname === '/services/cancelled' ? 'Cancelled' : null}
                    <span className='ml-1'>Services</span>
                </p>
                <div className='mb-3 flex justify-between items-center gap-6'>
                    <div className='flex gap-4 items-center p-3'>
                        {showSearch ? (
                            <div>
                                <div className="flex space-x-3 md:space-x-4 py-2 px-3 border rounded-lg mb-4 items-center">
                                    <IoIosSearch className='w-6 h-6' style={{color: "#42B883"}}/>
                                    <input 
                                        value={searchText}
                                        type='search' 
                                        onChange={(e) => setSearchText(e.target.value)}
                                        placeholder='Search by buyer, expert, status' 
                                        className='input'/>
                                </div>
                            </div>
                        ):(
                            <div>
                                <div 
                                    onClick={() => setShowSearch(!showSearch)}
                                    className='btn btn-outline hover:cursor-pointer'>
                                    <IoIosSearch className='w-5 h-5'/>
                                </div>
                            </div>
                        )}
                        <div className='flex items-center'>
                            <BiSort className='w-8 h-8' color='#42B883'/>
                            <Select
                                className='select select-ghost'
                                value={selectedValue}
                                onChange={handleChange}
                                options={options}
                            />
                        </div>
                    </div>
                    <div>
                        {searchInputParam && (
                            <button 
                                onClick={() => resetSearch()}
                                className='btn btn-primary btn-outline'>
                                <IoTrashSharp/> Clear Search</button>
                        )}
                    </div>
                </div>
                <div>
                    <div className="overflow-x-auto">
                        <table className="table table-zebra table-xs mb-4">
                            <thead>
                                <tr className='text-sm'>
                                    <td>Expert</td>
                                    <td>Buyer</td>
                                    <td>Service</td>
                                    {location.pathname === '/services/completed' ? (
                                        <>
                                            <td>Requested On</td>
                                            <td>Pick Up</td>
                                            <td>Completed On</td>
                                        </>
                                    ) : null}
                                    {location.pathname === '/services/ongoing' ? (
                                        <>
                                            <td>Booking Time</td>
                                            <td>Pick Up</td>
                                            <td>Progress</td>
                                        </>
                                    ) : null}
                                    {location.pathname === '/services/scheduled' ? (
                                        <>
                                            <td>Booking Time</td>
                                            <td>Pick Up</td>
                                            <td>Scheduled Start</td>
                                        </>
                                    ) : null}
                                    {location.pathname === '/services/cancelled' ? (
                                        <>
                                            <td>Requested On</td>
                                            <td>Pick Up</td>
                                            <td>Cancellation Reason</td>
                                        </>
                                    ) : null}
                                    <td>Cost</td>
                                    <td>Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {services?.results?.map((service, idx) => (
                                    <ServiceTableRow
                                        key={idx}
                                        service={service}
                                    />
                                ))}
                            </tbody>
                        </table>
                        {services?.count < 1 ? (
                            <div className="mb-7">
                                <Empty objectName={"Service Gigs"}/>
                            </div>                            
                        ):(
                            <div className=" p-3">
                                {services && (
                                    <Pagination
                                        count={services?.count}
                                        next={services?.next}
                                        previous={services?.previous}
                                        changeLimitParam={props.changeLimitParam}
                                        changePageParam={props.changePageParam}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>                
            </div>
        </div>
    )
}

export default ServicesTable