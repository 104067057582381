// import utils from '../utils'
import apiClient from './AxiosHttp'

const api = '/api/v1'

const getDashboardStatistics = () => {
    return apiClient.get(`${api}/dashboard/statistics/`)
}

const getExperts = (params) => {
    return apiClient.get(`${api}/dashboard/experts/${params}`)
}

const getExpertStatistics = () => {
    return apiClient.get(`${api}/dashboard/experts/statistics/`)
}

const getServiceEMployees = (id) => {
    return apiClient.get(`${api}/dashboard/service/employees/${id}/`)
}

const getExpertReviews = (id, params) => {
    return apiClient.get(`${api}/dashboard/expert/reviews/${id}/${params}`)
}

const getSpecificExpertStatistics = (id) => {
    return apiClient.get(`${api}/dashboard/expert/statistics/${id}/`)
}

const getExpertServices =  (params) => {

}

const getApplicationStatistics = () => {
    return apiClient.get(`${api}/dashboard/applications/statistics/`)
}

const getApplications = (params) => {
    return apiClient.get(`${api}/dashboard/applications/${params}`)
}

const updateExpert = (id, details) => {
    return apiClient.patch(`${api}/dashboard/user/get/${id}/`, details)
}

const deleteExpert = (id) => {
    return apiClient.delete(`${api}/dashboard/user/get/${id}/`)
}

const expertsServices = {
    getDashboardStatistics,
    getExperts,
    getExpertStatistics,
    getServiceEMployees,
    getExpertReviews,
    getExpertServices,
    getSpecificExpertStatistics,
    getApplicationStatistics,
    getApplications,
    updateExpert,
    deleteExpert,
}

export default expertsServices