import apiClient from './AxiosHttp'

const api = '/api/v1'

const getPeriodNames = () => {
    return apiClient.get(`${api}/payments/billing/period/names/`)
}

const getSubscriptionPlans = () => {
    return apiClient.get(`${api}/payments/subscription/plans/`)
}

const getBillingPeriods = () => {
    return apiClient.get(`${api}/payments/billing/periods/`)
}

const getSubscriptionStatistics = () => {
    return apiClient.get(`${api}/dashboard/subscriptions/statistics/`)
}

const getSubscriptionPlan = (id) => {
    return apiClient.get(`${api}/payments/subscription/plans/get/${id}/`)
}

const updateSubscriptionPlan = (id, details) => {
    return apiClient.patch(`${api}/payments/subscription/plans/modify/${id}/`, details)
}

const updateBillingPeriod = (id, details) => {
    return apiClient.patch(`${api}/payments/billing/periods/modify/${id}/`, details)
}

const addBillingPeriod = (details) => {
    return apiClient.post(`${api}/payments/billing/periods/`, details)
}

const addSubscriptionPlan = (details) => {
    return apiClient.post(`${api}/payments/subscription/plans/`, details)
}

const getSubscribers = (id, params) => {
    return apiClient.get(`${api}/dashboard/subscribed/users/${id}/${params}`)
}

const addPeriodName = (details) => {
    return apiClient.post(`${api}/payments/billing/period/names/`, details)
}

const getAllowedCurrencies = () => {
    return apiClient.get(`${api}/payments/allowed/currencies/`)
}

const addAllowedCurrency = (details) => {
    return apiClient.post(`${api}/payments/allowed/currencies/`)
}


const subscriptionServices = {
    getPeriodNames,
    getSubscriptionPlans,
    getBillingPeriods,
    getSubscriptionStatistics,
    getSubscriptionPlan,
    updateSubscriptionPlan,
    updateBillingPeriod,
    addBillingPeriod,
    addSubscriptionPlan,
    getSubscribers,
    addPeriodName,
    getAllowedCurrencies,
    addAllowedCurrency
}

export default subscriptionServices