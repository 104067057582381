import React, { useEffect } from 'react'
import utils from '../../utils'
import moment from 'moment'

function SuscriberTableRow(props) {
    const subscriber = props.subscriber

    useEffect(() => {}, [subscriber])
    return (
        <tr>
            <td>
                {subscriber && (
                    <div className="flex items-center gap-3">
                        <div className='avatar'>
                            {utils.checkImage(subscriber?.owner) ? (
                                <div className=" w-11 rounded-full">
                                    <img src={subscriber?.owner?.image} alt={subscriber?.owner?.email} />
                                </div>
                            ):(
                                <div>
                                    <div className="avatar placeholder">
                                        <div className="bg-neutral text-neutral-content rounded-full w-11">
                                            <span className="text-xl">{utils.makeProfileLetters(subscriber?.owner)}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col gap-2'>
                            <div>
                                {subscriber?.owner?.full_name ? (
                                    <p>{subscriber?.owner?.full_name}</p>
                                ):(
                                    <p>{subscriber?.owner?.email}</p>
                                )}
                            </div>
                            <div>
                                <p className='text-gray-500 text-xs'>
                                    Subscribed: 
                                    {subscriber?.subscription_date ? (
                                        <span>{moment(subscriber?.subscription_date).format("Do MMM YYYY")}</span>
                                    ):(
                                        <span>-</span>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                )}  
            </td>
            <td>
                {subscriber?.owner?.buyer_account_type}
            </td>
            <td>
                {subscriber?.billing_period?.period_name?.name}
            </td>
            <td>
                {subscriber?.status}
            </td>
            <td>
                {subscriber?.due_date ? (
                    <span>{moment(subscriber?.due_date).format("Do MMM YYYY")}</span>
                ):(
                    <span>-</span>
                )}
            </td>
        </tr>
    )
}

export default SuscriberTableRow