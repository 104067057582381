import React, { useEffect } from 'react'
import { ReactComponent as Script } from '../../assets/script.svg'
import { ReactComponent as Driver } from '../../assets/driver.svg'
import utils from '../../utils'

function ApplicationsSummary(props) {
    const statistics = props.statistics
    useEffect(() => {}, [statistics])
    return (
        <div className='p-3'>
            {statistics && (
                <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3'>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.new_applications)}</p>
                            <p className='uppercase text-gray-500 font-bold'>New Applications</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <Script className='w-10 h-10'/>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.registered_motorists)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Joined as Motorists</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <Driver className='w-10 h-10'/>
                        </figure>
                    </div>
                    <div className="flex items-center border p-3 gap-2 rounded-md justify-between">
                        <div className="grow">
                            <p className='font-bold text-lg'>{utils.toCommas(statistics?.registered_experts)}</p>
                            <p className='uppercase text-gray-500 font-bold'>Joined as experts</p>
                        </div>
                        <figure className='border p-2 rounded-md grow-0'>
                            <Script className='w-10 h-10'/>
                        </figure>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ApplicationsSummary