import React, { useEffect } from 'react'

function PaymentDetails(props) {
    const user = props.expert
    useEffect(() => {}, [user])
    return (
        <div id="payment-details" className='p-3'>
            <div className="rounded-md border pb-5 mb-6">
            <div className="py-5 border-b mb-3 p-4 flex justify-between items-center">
                    <p className="font-bold text-lg">Payment Details</p>
                </div> 
                <div className='p-2 space-y-3'>
                    {user?.payment_details ? (
                        <>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Bank</p>
                                <p>{user?.payment_details?.bank}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Branch</p>
                                <p>{user?.payment_details?.branch}</p>
                            </div>
                            <div className="flex justify-between">
                                <p className='text-gray-500'>Account Holder Name</p>
                                <p>{user?.payment_details?.account_holder_name}</p>
                            </div>
                        </>
                    ):(
                        <>
                            <p>User has not Saved Payment Details yet!</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PaymentDetails